import { IconProps, Icon } from '../Icon';

export const SeverityHighIcon = (props: IconProps) => (
  <Icon
    width="8"
    height="10"
    viewBox="0 0 8 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.06 5.5L4 2.44667L0.94 5.5L0 4.56L4 0.56L8 4.56L7.06 5.5Z"
      fill="#723E01"
    />
    <path
      d="M7.06 9.43994L4 6.38661L0.94 9.43994L0 8.49994L4 4.49994L8 8.49994L7.06 9.43994Z"
      fill="#723E01"
    />
  </Icon>
);
