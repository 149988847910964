import { FC } from 'react';
import { BiMinus } from 'react-icons/bi';
import { BsPlusLg, BsChevronDown } from 'react-icons/bs';
import { FiInfo } from 'react-icons/fi';
import { useUpdateTeamSettingsHandler } from '../../handlers';
import { useCurrentTeam, useTeamType } from '../../queries';
import { TeamFeatureSetModal } from '../TeamFeatureSetModal';
import { TeamBusinessType } from '@spoke/graphql';
import {
  useToast,
  useDisclosure,
  TOAST_ERROR_GENERIC,
  UpDown,
  HStack,
  Center,
  Icon,
  Spacer,
  Switch,
  Button,
  Menu,
  MenuButton,
  Flex,
  teamBusinessTypeNames,
  MenuList,
  MenuItem,
  log,
  Tooltip,
  isNullish,
  Text,
} from '@spoke/common';

export const TeamSettingsOptions: FC = () => {
  const [toast] = useToast();
  const [currentTeam] = useCurrentTeam();
  const { lowercaseTeamType } = useTeamType();

  const currentDefaultVoteCount = currentTeam?.settings?.defaultVoteCount;

  const [handleUpdateTeamSettings] = useUpdateTeamSettingsHandler();

  const featureSetModal = useDisclosure();

  const teamId = currentTeam?.id;
  const currentGuidedRetros =
    currentTeam?.settings?.defaultGuidedRetrospectives;
  const currentPublicRetros =
    currentTeam?.settings?.defaultPublicRetrospectives;
  const currentWeeklyDigest = true;

  const onTogglePublicRetros = async () => {
    if (!teamId || isNullish(currentPublicRetros)) {
      log.error(
        'Missing critical data to toggle team default public retros setting',
        {
          teamId,
          currentPublicRetros,
        }
      );
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    await handleUpdateTeamSettings({
      teamId,
      newSettings: {
        defaultPublicRetrospectives: !currentPublicRetros,
      },
    });
  };

  const onToggleGuidedRetros = async () => {
    if (!teamId || isNullish(currentGuidedRetros)) {
      log.error('Missing critical data to toggle team guided retros setting', {
        teamId,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    await handleUpdateTeamSettings({
      teamId,
      newSettings: {
        defaultGuidedRetrospectives: !currentGuidedRetros,
      },
    });
  };

  const onChangeTeamBusiness = async (newType: TeamBusinessType) => {
    if (!teamId) {
      log.error('Cannot save team name without loaded currentTeamId', {
        teamId,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    await handleUpdateTeamSettings({
      teamId,
      newSettings: {
        businessType: newType,
      },
    });
  };

  const onDefaultVoteCountChange = async (upDown: UpDown) => {
    if (!teamId || isNullish(currentDefaultVoteCount)) {
      log.error('Missing critical data to update team default vote count', {
        teamId,
        currentDefaultVoteCount,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    const toAdd = upDown === UpDown.Up ? 1 : -1;

    await handleUpdateTeamSettings({
      teamId,
      newSettings: {
        defaultVoteCount: (currentDefaultVoteCount as number) + toAdd,
      },
    });
  };

  return (
    <>
      <HStack>
        <Text fontSize={14} fontWeight={400} color="gray.600">
          Default to invite {lowercaseTeamType} members with retrospective URL
        </Text>
        <Tooltip
          variant="white"
          placement="top"
          label="For all future retros, anyone with the link will automatically be added to this team. You can change this setting by clicking the Share button in the retrospective."
        >
          <Center pb="3px">
            <Icon color="gray.500" as={FiInfo} />
          </Center>
        </Tooltip>
        <Spacer />
        <Switch
          onChange={onTogglePublicRetros}
          isChecked={currentPublicRetros}
        />
      </HStack>
      <HStack>
        <Text fontSize={14} fontWeight={400} color="gray.600">
          Default all retros to guided
        </Text>
        <Tooltip
          variant="white"
          placement="top"
          maxW={240}
          label="New retrospectives will automatically opt in guided mode"
        >
          <Center pb="3px">
            <Icon color="gray.500" as={FiInfo} />
          </Center>
        </Tooltip>
        <Spacer />
        <Switch
          onChange={onToggleGuidedRetros}
          isChecked={currentGuidedRetros}
        />
      </HStack>
      <HStack>
        <Text fontSize={14} fontWeight={400} color="gray.600">
          Default vote count
        </Text>
        <Spacer />
        <HStack>
          <Button
            isDisabled={Boolean(
              !isNullish(currentDefaultVoteCount) &&
                currentDefaultVoteCount! <= 0
            )}
            onClick={() => onDefaultVoteCountChange(UpDown.Down)}
            w="20px"
            minW={0}
            h="20px"
            p={3}
            borderRadius="4px"
          >
            <Icon as={BiMinus} w={3} h={3} />
          </Button>
          <Center
            minH="20px"
            minW="40px"
            bg="gray.75"
            color="gray.600"
            fontWeight={500}
            borderRadius="4px"
          >
            {currentDefaultVoteCount}
          </Center>
          <Button
            w="20px"
            minW={0}
            h="20px"
            onClick={() => onDefaultVoteCountChange(UpDown.Up)}
            p={3}
            borderRadius="4px"
          >
            <Icon as={BsPlusLg} w={2} h={2} />
          </Button>
        </HStack>
      </HStack>
      <HStack>
        <Text fontSize={14} fontWeight={400} color="gray.600">
          Team Focus
        </Text>
        <Tooltip
          variant="white"
          placement="top"
          maxW={240}
          label="What does your team focus on? This helps us tailor the best experience for you"
        >
          <Center pb="3px">
            <Icon color="gray.500" as={FiInfo} />
          </Center>
        </Tooltip>
        <Spacer />
        <Menu placement="bottom" variant="unstyled">
          <MenuButton>
            <Flex alignItems="center">
              <Text
                ml={1}
                color="gray.500"
                fontSize={14}
                fontWeight={400}
                cursor="pointer"
              >
                {currentTeam?.settings?.businessType ===
                TeamBusinessType.NotSpecified
                  ? 'Please pick one'
                  : teamBusinessTypeNames[
                      currentTeam?.settings?.businessType ??
                        TeamBusinessType.NotSpecified
                    ]}
              </Text>
              <Icon ml={1} as={BsChevronDown} />
            </Flex>
          </MenuButton>
          <MenuList maxH="256px" overflowY="auto">
            {Object.values(TeamBusinessType)
              .filter(
                (option) =>
                  ![
                    TeamBusinessType.NotSpecified,
                    TeamBusinessType.Other,
                  ].includes(option)
              )
              .map((option) => (
                <MenuItem
                  key={option}
                  h={8}
                  fontSize={16}
                  onClick={() => onChangeTeamBusiness(option)}
                >
                  {teamBusinessTypeNames[option]}
                </MenuItem>
              ))}
            <MenuItem
              h={8}
              fontSize={16}
              onClick={() => onChangeTeamBusiness(TeamBusinessType.Other)}
            >
              {teamBusinessTypeNames[TeamBusinessType.Other]}
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
      <HStack>
        <Text fontSize={14} fontWeight={400} color="gray.600">
          Feature set
        </Text>
        <Spacer />
        <Button
          variant="link"
          textDecor="underline"
          fontWeight={400}
          fontSize={14}
          onClick={featureSetModal.open}
        >
          Choose features
        </Button>
      </HStack>
      <TeamFeatureSetModal
        isOpen={featureSetModal.isOpen}
        onClose={featureSetModal.close}
      />
    </>
  );
};
