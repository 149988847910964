import { FC } from 'react';
import {
  FlexProps,
  VStack,
} from '@chakra-ui/react';
import {
  Activity,
} from '@spoke/graphql';
import { ActivityFeedCardHeader } from './ActivityFeedCardHeader';
import { ActivityFeedCardBody } from './ActivityFeedCardBody';
import { ActivityFeedCardFooter } from './ActivityFeedCardFooter';


type ActivityFeedCardProps = FlexProps & {
  // currentUser: User;
  activity: Activity;
  // toggleReaction: (activity: Activity) => void;
};

export const ActivityFeedCard: FC<ActivityFeedCardProps> = ({ activity }) => {
  const test = 1;
  return (
    <VStack
      key={activity.id}
      direction="row"
      align="left"
      p={4}
      my={2}
      borderWidth={1}
      borderRadius="lg"
      borderColor="gray.200"
      backgroundColor="gray.50"
      boxShadow="sm"
    >
      <ActivityFeedCardHeader activity={activity} />
      <ActivityFeedCardBody activity={activity} />
      <ActivityFeedCardFooter activity={activity} />
    </VStack>
  );
};
