import { IconType } from 'react-icons';
import { MdOutlineBookmarkBorder, MdOutlineStickyNote2 } from 'react-icons/md';
import { TeamIcon, TeamPulseIcon, Text } from '@spoke/common';

export const BADGE_ICON: Record<
  string,
  { icon: IconType; w: number; h: number }
> = {
  team: { icon: TeamIcon as IconType, w: 5, h: 5 },
  bookmark: { icon: MdOutlineBookmarkBorder, w: 4, h: 4 },
  note: { icon: MdOutlineStickyNote2, w: 4, h: 4 },
};

export const ITEM_ICON: Record<
  string,
  { icon: IconType; w: number; h: number; color: string }
> = {
  metric: { icon: TeamPulseIcon as IconType, w: 5, h: 5, color: 'primary.500' },
  feedback: { icon: MdOutlineStickyNote2, w: 5, h: 5, color: 'blue.500' },
};

/**
 * Parses string for markdown **bold** and returns a set of spans
 * with corresponding font weights.
 * Not super elegant but simple enough for now.
 */
export const renderImpactReportSummary = (
  summary: string
): React.ReactNode[] => {
  const parts = summary.split(/\*\*(.*?)\*\*/);
  return parts.map((part, index) => {
    if (index % 2 === 0) {
      return (
        <Text as="span" color="gray.600" key={index}>
          {part}
        </Text>
      );
    } else {
      return (
        <Text
          as="span"
          color="primary.500"
          key={index}
          style={{ fontWeight: 500 }}
        >
          {part}
        </Text>
      );
    }
  });
};
