import { useCallback } from 'react';
import { useToast, TOAST_ERROR_GENERIC, log } from '@spoke/common';
import { useArchiveCommentForActivityFeedMutation } from '@spoke/graphql';

export const useArchiveCommentForActivityFeedHandler = () => {
  const [archiveComment] = useArchiveCommentForActivityFeedMutation();
  const [toast] = useToast();

  const handleArchiveComment = useCallback(
    async (
      activityId: string,
      activityFeedCommentId: string
    ): Promise<boolean> => {
      if (!activityFeedCommentId || !activityId) {
        log.error(
          'Missing critical data to archive comment for activity feed',
          {
            activityId,
            activityFeedCommentId,
          }
        );
        return false;
      }

      log.info('Archiving comment for activity feed', {
        activityId,
        activityFeedCommentId,
      });

      try {
        const response = await archiveComment({
          variables: { activityId, activityFeedCommentId },
        });

        if (response.errors) {
          log.error('Failed to archive comment for activity feed', {
            errors: response.errors,
          });
          toast(TOAST_ERROR_GENERIC);
          return false;
        }

        log.info('Successfully archived comment for activity feed', {
          activityId,
          activityFeedCommentId,
        });
        return true;
      } catch (error) {
        log.error('Failed to archive comment for activity feed', { error });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }
    },
    [archiveComment, toast]
  );

  return [handleArchiveComment];
};
