import { useToken } from '@chakra-ui/react';
import { FC, useCallback, useMemo } from 'react';
import { DayPicker } from 'react-day-picker';
import { Flex, Text } from '../';
import 'react-day-picker/dist/style.css';

export type DatePickerProps = {
  value?: Date | null;
  onChange?: (newDate: Date | null) => void;
};

export const DatePicker: FC<DatePickerProps> = ({ onChange, value }) => {
  const onSelect = useCallback(
    (newValue: Date | undefined) => {
      onChange?.(newValue ?? null);
    },
    [onChange]
  );

  const [primary400] = useToken('colors', ['primary.400']);

  const css = useMemo(
    () => `
    .rdp {
      --rdp-cell-size: 30px;
      --rdp-accent-color: ${primary400};
      --rdp-background-color: #e7edff;
      --rdp-accent-color-dark: ${primary400};
      --rdp-background-color-dark: ${primary400};
      --rdp-outline: 2px solid var(--rdp-accent-color);
      --rdp-outline-selected: 2px solid ${primary400};
    }
    .rdp-month {
      width: 100%;
    }
    .today {
      color: black;
      font-weight: 600;
    }
    .rdp-day_selected {
      background-color: ${primary400};
    }
`,
    [primary400]
  );

  return (
    <>
      <style>{css}</style>
      <Flex direction="column" width="100%">
        <DayPicker
          mode="single"
          selected={value ?? undefined}
          onSelect={onSelect}
          modifiersClassNames={{
            today: 'today',
            selected: 'rdp-day_selected',
          }}
          styles={{
            caption: { marginBottom: '0.5rem' },
            months: { width: '100%' },
          }}
        />
      </Flex>
    </>
  );
};
