import { useToken } from '@chakra-ui/react';
import { AxisLeft, TickRendererProps } from '@visx/axis';
import { scaleLinear, scaleTime } from '@visx/scale';
import { FC, useMemo, useState } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import useMeasure from 'react-use-measure';
import {
  ImpactReportIssueOccurrence,
  ImpactReportQueryHookResult,
} from '@spoke/graphql';
import {
  Circle,
  Divider,
  Flex,
  Icon,
  NO_OP,
  pickSpaced,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverContent,
  Spacer,
  SpkTime,
  Text,
  useLagState,
} from '@spoke/common';

type Props = {
  issues: Array<{
    id: string;
    name: string;
    color: string;
    occurrences: Array<{
      date: number;
      cardCount: number;
      teamCount: number;
    }>;
  }>;
  onIssueClick?: (issueId: string) => void;
  variant?: 'compact' | 'full';
};

const INNER_PADDING_HORIZONTAL = 40;
const INNER_PADDING_TOP = 15;
const INNER_PADDING_BOTTOM = 20;
const SVG_HEIGHT = 230;

const MONTH_LABEL_MAX_COUNT = 6;

export const IssuesOverTimeChart: FC<Props> = ({
  issues,
  onIssueClick = NO_OP,
  variant = 'full',
}) => {
  const [ref, bounds] = useMeasure();

  const isCompact = variant === 'compact';

  const width = bounds.width || 100;
  const height = bounds.height || 100;

  const [gray200, gray500] = useToken('colors', ['gray.200', 'gray.500']);

  const textDefaultProps = {
    fontSize: 14,
    fontFamily: 'Roboto, sans-serif',
    fill: gray500,
  };

  /**
   * This goes from the first day of the month of the earliest issue to the last day
   * of the month of the latest issue. In order to plot month names and keep a good margin.
   */
  const allDates = issues.flatMap((i) =>
    i.occurrences.map((o) => new Date(o.date))
  );
  const { minDate, maxDate } = useMemo(() => {
    const sortedDates = allDates.sort((a, b) => a.getTime() - b.getTime());
    const firstDate = new Date(sortedDates[0]);
    const lastDate = new Date(sortedDates[sortedDates.length - 1]);

    if (firstDate.toDateString() === lastDate.toDateString()) {
      firstDate.setDate(firstDate.getDate() - 1);
      lastDate.setDate(lastDate.getDate() + 1);
    } else {
      firstDate.setDate(firstDate.getDate() - 5);
      lastDate.setDate(lastDate.getDate() + 5);
    }

    firstDate.setHours(0, 0, 0, 0);
    lastDate.setHours(23, 59, 59, 999);

    return { minDate: firstDate, maxDate: lastDate };
  }, [allDates]);

  /**
   * All occurrences from all issues flattened in a single array.
   */
  const allOccurrences = issues.reduce(
    (acc, issue) => [
      ...acc,
      ...issue.occurrences.map((oc) => ({
        ...oc,
        color: issue.color,
        issueName: issue.name,
        issueId: issue.id,
      })),
    ],
    [] as (ImpactReportIssueOccurrence & {
      color: string;
      issueName: string;
      issueId: string;
    })[]
  );

  /**
   * State for hovered point
   */
  const [hoveredPointIdx, setFocusedPointIdx] = useState<null | number>(null);
  const _hoveredPoint =
    hoveredPointIdx !== null ? allOccurrences[hoveredPointIdx] ?? null : null;
  const [lagHoveredPoint] = useLagState(_hoveredPoint, 100); // Wait for close animation before nulling out
  const hoveredPoint = _hoveredPoint ?? lagHoveredPoint;

  /**
   * This goes from zero (bottom) to a bit above (20%) the maximum number of issues in a month
   * so we get a bit of space at the top.
   */
  const minValue = 0;
  const maxValue =
    Math.ceil(Math.max(...allOccurrences.map((issue) => issue.cardCount))) *
    1.2;

  /**
   * Array of dates containg the first day of each month in the dataset.
   */
  const monthStarts = Object.values(
    allOccurrences.reduce((acc, issue) => {
      const date = new Date(issue.date);
      const month = date.getMonth();
      const year = date.getFullYear();
      const key = `${month}-${year}`;
      if (!acc[key]) acc[key] = new Date(`${year}-${month + 1}-01`);
      return acc;
    }, {} as Record<string, Date>)
  ) as Date[];

  /**
   * Coordinates for the inner rectangle where we draw dots.
   */
  const canvasXLeft = INNER_PADDING_HORIZONTAL;
  const canvasXRight = width - INNER_PADDING_HORIZONTAL;
  const canvasYTop = INNER_PADDING_TOP;
  const canvasYBottom = height - INNER_PADDING_BOTTOM;

  /**
   * This is a helper component to visualize the margins of the chart.
   * Only useful for development purposes. Flip the boolean constant to enable.
   */
  const DevMargins = () => {
    // eslint-disable-next-line no-constant-condition
    if (true) return <></>;
    const lines = [
      [0, width, canvasYBottom, canvasYBottom],
      [0, width, canvasYTop, canvasYTop],
      [canvasXLeft, canvasXLeft, 0, height],
      [canvasXRight, canvasXRight, 0, height],
    ];
    return (
      <>
        {lines.map((line, i) => (
          <line
            key={i}
            x1={line[0]}
            x2={line[1]}
            y1={line[2]}
            y2={line[3]}
            stroke="red"
            strokeWidth={2}
            strokeDasharray="4 4"
          />
        ))}
      </>
    );
  };

  const xScale = useMemo(
    () =>
      scaleTime({
        range: [canvasXLeft, canvasXRight],
        domain: [minDate, maxDate],
      }),
    [canvasXLeft, canvasXRight, maxDate, minDate]
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [canvasYBottom, canvasYTop],
        domain: [minValue, maxValue],
      }),
    [canvasYBottom, canvasYTop, maxValue]
  );

  const AxisBottomComponent = (): JSX.Element => (
    <g>
      {pickSpaced(monthStarts, MONTH_LABEL_MAX_COUNT).map((tick, idx) => (
        <text
          key={`tick-${idx}`}
          x={xScale(tick)}
          y={height}
          fontSize={textDefaultProps.fontSize}
          fill={textDefaultProps.fill}
        >
          {tick.toLocaleString('en-US', { month: 'short' }).toUpperCase()}
        </text>
      ))}
    </g>
  );

  const AxisLeftTickComponent = ({
    y,
    formattedValue,
  }: TickRendererProps): JSX.Element => (
    <g>
      <line
        x1={canvasXLeft}
        x2={canvasXRight}
        y1={y}
        y2={y}
        stroke={gray200}
        strokeWidth={1}
      />
      <text
        x={15}
        y={y}
        fontSize={textDefaultProps.fontSize}
        fill={textDefaultProps.fill}
      >
        {formattedValue}
      </text>
    </g>
  );

  const PointHoverTooltipContent = () => (
    <Flex flexDir="column" p={3}>
      <Flex alignItems="center">
        <Circle mr="3px" bg={hoveredPoint?.color} size="10px" />
        <Text whiteSpace="nowrap" fontSize={14} fontWeight={500}>
          {hoveredPoint?.issueName}
        </Text>
      </Flex>
      <Text fontSize={14} color="gray.500">
        {SpkTime.format(hoveredPoint?.date ?? 0, 'MMM dd, yyyy')}
      </Text>
      <Divider my={2} />
      <Flex width={200} fontSize={14} mb={1}>
        <Text fontWeight={500}>Mentioned in</Text>
        <Spacer />
        <Text color="gray.500">{hoveredPoint?.cardCount} cards</Text>
      </Flex>
      <Flex width={200} fontSize={14}>
        <Text fontWeight={500}>Teams</Text>
        <Spacer />
        <Text color="gray.500">{hoveredPoint?.teamCount} teams</Text>
      </Flex>
    </Flex>
  );

  return (
    <Flex flexDir="column">
      {!isCompact && (
        <Flex overflowX="auto" w="full" gap={10} mb={4}>
          {issues.map((i) => (
            <Flex key={`${i.name}-${i.color}`} flexDir="column">
              <Flex alignItems="center">
                <Circle bg={i.color} size="10px" mr={1} />{' '}
                <Text
                  whiteSpace="nowrap"
                  fontSize={14}
                  fontWeight={500}
                  onClick={() => onIssueClick(i.id)}
                  cursor="pointer"
                >
                  {i.name}
                </Text>
              </Flex>
              <Text ml={4} color="gray.500">
                {i.occurrences.reduce((acc, occ) => acc + occ.cardCount, 0)}
              </Text>
            </Flex>
          ))}
        </Flex>
      )}
      <Flex ref={ref} w="full" h={SVG_HEIGHT}>
        <Popover isOpen={hoveredPointIdx !== null} placement="top">
          <PopoverContent w="fit-content">
            <PopoverArrow />
            <PointHoverTooltipContent />
          </PopoverContent>
          <svg width="100%" height="100%" viewBox={`0 0 ${width} ${height}`}>
            <DevMargins />
            <AxisLeft
              hideAxisLine
              hideTicks
              scale={yScale}
              tickComponent={AxisLeftTickComponent}
              numTicks={6}
            />

            {allOccurrences.map((occurrence, idx) => {
              const x = xScale(new Date(occurrence.date)) + idx * 3; // Add 2px to the x position for spacing since alot of have the same xscale when from same feedback session
              const y = yScale(occurrence.cardCount);
              const isFocused = hoveredPointIdx === idx;
              const isSomeFocused = hoveredPointIdx !== null;

              const IssuePoint = (
                <g
                  key={occurrence.color + idx}
                  onClick={() => onIssueClick(occurrence.issueId)}
                  onMouseMove={() => setFocusedPointIdx(idx)}
                  onMouseLeave={() => setFocusedPointIdx(null)}
                >
                  <circle
                    cx={x}
                    cy={y}
                    r={5}
                    opacity={isFocused ? 1 : isSomeFocused ? 0.5 : 1}
                    style={{ transition: 'opacity 0.1s ease-out' }}
                    fill={occurrence.color}
                    stroke="none"
                  />
                  <circle
                    cx={x}
                    cy={y}
                    r={isFocused ? 7 : 5}
                    opacity={isFocused ? 0.5 : 0}
                    style={{ transition: 'r 0.12s ease-out' }}
                    fill={occurrence.color}
                    stroke="none"
                  />
                </g>
              );

              return isFocused ? (
                <PopoverAnchor key={occurrence.color + idx}>
                  {IssuePoint}
                </PopoverAnchor>
              ) : (
                IssuePoint
              );
            })}

            <AxisBottomComponent />
          </svg>
        </Popover>
      </Flex>
      {!isCompact && (
        <Flex alignItems="center" mt={6}>
          <Icon as={BiInfoCircle} color="gray.500" w="16px" h="16px" mr={1} />
          <Text fontSize={14} color="gray.500">
            Click on an issue for insights
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
