import { StackProps } from '@chakra-ui/react';
import { VscFeedback } from 'react-icons/vsc';
import {
  Button,
  Flex,
  Icon,
  Text,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
  HStack,
  VStack,
  Image,
  useAssets,
  Link,
} from '@spoke/common';
import { AsyncFeedbackConfigList } from './AsyncFeedbackConfigList';
import { BoardAsyncSchedule } from '@spoke/graphql';
import { BsPlus } from 'react-icons/bs';
import { useCurrentOrgLockdown } from '@spoke/user';

type AsyncFeedbackListModalBodyProps = StackProps & {
  asyncFeedbackConfigs: BoardAsyncSchedule[];
  onCreateConfig: () => void;
  onConfigClick: (config: BoardAsyncSchedule) => void;
  onDeleteConfig: (configId: string) => void;
  onTriggerClick: (configId: string) => void;
};

export const AsyncFeedbackListModalBody = ({
  asyncFeedbackConfigs,
  onCreateConfig,
  onConfigClick,
  onDeleteConfig,
  onTriggerClick,
  ...rest
}: AsyncFeedbackListModalBodyProps) => {
  const { illustrationYellowPage } = useAssets();
  const [isOrgLocked, isTrialExpired, isOrgFree] = useCurrentOrgLockdown();
  return (
    <ModalContent p={8} maxW={700}>
      <ModalCloseButton />
      <ModalHeader p={0} mb={4}>
        {asyncFeedbackConfigs.length > 0 && (
          <HStack>
            <Icon as={VscFeedback} w={6} h={6} />
            <Heading mb={1} fontSize={24} color="gray.900">
              Team Feedback Schedule
            </Heading>
          </HStack>
        )}
      </ModalHeader>
      <ModalBody p={0}>
        {/* <Divider my={4} /> */}
        {asyncFeedbackConfigs.length > 0 ? (
          <VStack spacing={2} align="start">
            <Text fontSize="14" color="gray.500" mt={4}>
              Automate feedback collection from your team for things like team
              check-ins, standups, retrospectives, surveys, and more.{' '}
              <Link
                color="primary.500"
                href="https://www.scatterspoke.com/kb-cat/asynchronous-feedback"
                isExternal
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </Link>{' '}
              about the benefits of automating feedback.
            </Text>
            <AsyncFeedbackConfigList
              asyncFeedbackConfigs={asyncFeedbackConfigs}
              onConfigClick={onConfigClick}
              onDeleteConfig={onDeleteConfig}
              onTriggerClick={onTriggerClick}
              maxHeight="500px"
            />
            <Button
              fontSize="15px"
              iconSpacing={1}
              leftIcon={<Icon as={BsPlus} color="white" w={7} h={7} />}
              onClick={onCreateConfig}
              alignSelf="end"
              disabled={isOrgLocked || isTrialExpired || isOrgFree}
            >
              Create
            </Button>
          </VStack>
        ) : (
          <VStack w="full">
            <VStack textAlign="center" maxW={410} px={4} spacing={4}>
              <Flex direction="column" alignItems="center">
                <Image
                  src={illustrationYellowPage}
                  alt="No feedback created yet"
                  w={200}
                  mb={5}
                />
                <Heading size="md" color="gray.700" fontWeight={500} mb={2}>
                  No feedback scheduled
                </Heading>
                <Text color="gray.500" fontSize={14}>
                  Getting feedback regularly from your team can help you
                  identify issues and improve your processes.
                </Text>
              </Flex>
              <Button
                onClick={onCreateConfig}
                fontSize="15px"
                iconSpacing={1}
                leftIcon={<Icon as={BsPlus} color="white" w={7} h={7} />}
                disabled={isOrgLocked || isTrialExpired || isOrgFree}
              >
                Schedule Team Feedback
              </Button>
            </VStack>
          </VStack>
        )}
      </ModalBody>
    </ModalContent>
  );
};
