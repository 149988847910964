import React from 'react';
import {
  Box,
  Flex,
  HStack,
  Heading,
  Progress,
  Text,
  VStack,
  Icon,
} from '@chakra-ui/react';
import { ThumbsUp, ThumbsDown, Meh } from 'lucide-react';
import { InfoTooltip } from '@spoke/common';

interface FeedbackOverviewProps {
  engagementScorePercent: number;
  summary: string;
  positiveCount: number;
  neutralCount: number;
  negativeCount: number;
  actionItems?: {
    title: string;
    description: string;
    dueDate: Date;
    assignees: { id: string; name: string }[];
  }[];
}

const getSentimentIcon = (sentiment: string) => {
  switch (sentiment) {
    case 'positive':
      return <Icon w={5} h={5} as={ThumbsUp} color="green.500" />;
    case 'neutral':
      return <Icon w={5} h={5} as={Meh} color="yellow.500" />;
    case 'negative':
      return <Icon w={5} h={5} as={ThumbsDown} color="red.500" />;
    default:
      return null;
  }
};

const FeedbackOverview: React.FC<FeedbackOverviewProps> = ({
  engagementScorePercent,
  summary,
  positiveCount,
  neutralCount,
  negativeCount,
  actionItems,
}) => {
  const totalSentimentCount =
    (positiveCount ?? 0) + (neutralCount ?? 0) + (negativeCount ?? 0);

  const positivePercent =
    totalSentimentCount > 0
      ? Math.round(((positiveCount ?? 0) / totalSentimentCount) * 100)
      : 0;

  const neutralPercent =
    totalSentimentCount > 0
      ? Math.round(((neutralCount ?? 0) / totalSentimentCount) * 100)
      : 0;

  const negativePercent =
    totalSentimentCount > 0
      ? Math.round(((negativeCount ?? 0) / totalSentimentCount) * 100)
      : 0;

  const sentimentData = {
    positive: positivePercent,
    neutral: neutralPercent,
    negative: negativePercent,
  };

  return (
    <Box borderWidth={1} borderRadius="lg" borderColor="gray.200" my={4} p={4}>
      <Flex direction="column">
        <HStack mb={4}>
          <Heading as="h3" size="md" mb={1}>
            Overview
          </Heading>
          <InfoTooltip
            maxW={300}
            size={14}
            text="This section provides an overview of the feedback session, including the engagement score, a summary of the feedback, and the sentiment analysis."
          />
        </HStack>
        <VStack spacing={6} align="stretch">
          <Box>
            <Flex justify="space-between" align="center" mb={2}>
              <Text fontSize={14} color="black" fontWeight={500}>
                Engagement
              </Text>
              <Text fontSize="2xl" fontWeight="bold">
                {engagementScorePercent}%
              </Text>
            </Flex>
            <Progress
              value={engagementScorePercent}
              colorScheme="primary"
              size="md"
              borderRadius="full"
              transition="width 0.3s ease-in-out"
            />
          </Box>
          <Box>
            <Text fontSize={14} color="black" fontWeight={500} mt={1}>
              Summary
            </Text>
            <Text fontSize="sm" color="gray.600" my={2}>
              {summary}
            </Text>
          </Box>
          {actionItems && actionItems.length > 0 && (
            <Box>
              <Text fontSize={14} color="black" fontWeight={500} mt={1}>
                Action Plan
              </Text>
              <VStack spacing={4} align="stretch" width="100%">
                {actionItems.map((item, index) => (
                  <Box key={index} mt={index === 0 ? 4 : 0}>
                    <HStack justifyContent="space-between" mb={2}>
                      <Text fontSize={13} fontWeight={500}>
                        {item.title}
                      </Text>
                      <Text fontSize={12} color="gray.500">
                        {item.dueDate.toLocaleDateString()}
                      </Text>
                    </HStack>
                    <HStack justifyContent="space-between" mb={2}>
                      <Text fontSize={12} color="gray.600" mb={2}>
                        {item.description}
                      </Text>
                      <HStack>
                        <Text fontSize={12} fontWeight="medium">
                          Assignees:
                        </Text>
                        <Text fontSize={12} color="gray.600">
                          {item.assignees.map((a) => a.name).join(', ')}
                        </Text>
                      </HStack>
                    </HStack>
                  </Box>
                ))}
              </VStack>
            </Box>
          )}
          <Flex justify="space-around" align="center" pt={4} borderTopWidth={1}>
            {Object.entries(sentimentData).map(([sentiment, value]) => (
              <VStack key={sentiment} align="center">
                {getSentimentIcon(sentiment)}
                <Text
                  fontSize={14}
                  color="black"
                  fontWeight={500}
                  mt={2}
                  textTransform="capitalize"
                >
                  {sentiment}
                </Text>
                <Text fontSize={14} color="black" fontWeight={500}>
                  {value}%
                </Text>
              </VStack>
            ))}
          </Flex>
        </VStack>
      </Flex>
    </Box>
  );
};

export { FeedbackOverview };
