import { useToken } from '@chakra-ui/react';
import { Group } from '@visx/group';
import { scaleLinear, scaleTime } from '@visx/scale';
import { AreaClosed, LinePath } from '@visx/shape';
import { FC, useMemo } from 'react';
import useMeasure from 'react-use-measure';
import { Trend, ImprovementGoalUnit, TimeSeries } from '@spoke/graphql';
import {
  FlexProps,
  Flex,
  HStack,
  Spacer,
  formatWithGoalUnit,
  PercentChange,
  Text,
} from '@spoke/common';

const getXValue = (point: TimeSeries) => new Date(point.date).getTime() ?? 0;
const getYValue = (point: TimeSeries) => point.value ?? 0;

type Props = FlexProps & {
  title?: string;
  periodLabel?: string;
  lastValueSuffix?: string;
  ideal: Trend;
  unit: ImprovementGoalUnit;
  history: TimeSeries[];
};
export const TimeSeriesCard: FC<Props> = ({
  history,
  ideal,
  periodLabel,
  title,
  unit,
  lastValueSuffix,
  ...rest
}) => {
  const [chartContainerRef, chartDimensions] = useMeasure();

  const [primary25, primary200, gray100, gray300] = useToken('colors', [
    'primary.25',
    'primary.500',
    'gray.100',
    'gray.300',
  ]);

  const isEmpty = history.length === 0;

  const sortedHistory = useMemo(
    () =>
      isEmpty
        ? [
            { date: 0, value: 10 },
            { date: 10, value: 10 },
          ]
        : [...history].sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
          ),
    [history, isEmpty]
  );

  const latestPoint =
    sortedHistory[sortedHistory.length - 1] ?? sortedHistory[0];
  const firstPoint = sortedHistory[0];
  const latestValue = latestPoint?.value ?? 0;
  const secondLatestValue = sortedHistory[sortedHistory.length - 2]?.value ?? 0;

  const xScale = useMemo(
    () =>
      scaleTime({
        domain: [firstPoint?.date ?? 0, latestPoint?.date ?? 0],
        range: [0, chartDimensions.width],
      }),
    [chartDimensions.width, firstPoint?.date, latestPoint?.date]
  );

  const yScale = useMemo(() => {
    const minData = Math.min(...sortedHistory.map(getYValue));
    const maxData = Math.max(...sortedHistory.map(getYValue));
    const noChange = minData === maxData;
    const absRange = noChange ? maxData : Math.abs(maxData - minData);
    const PADDING_BOTTOM_FACTOR = 0.2;
    const PADDING_TOP_FACTOR = 1.1;
    const domain = [
      minData - absRange * PADDING_BOTTOM_FACTOR,
      maxData * PADDING_TOP_FACTOR,
    ];

    return scaleLinear({
      domain,
      range: [chartDimensions.height, 0],
    });
  }, [chartDimensions.height, sortedHistory]);

  return (
    <Flex flexDirection="column" gap={1} w="full" {...rest}>
      <HStack mb={2} flex={1}>
        <Flex flexDir="column" w="full">
          {title && (
            <Text
              fontSize={14}
              fontWeight={500}
              color="gray.700"
              w="fit-content"
              noOfLines={1}
            >
              {title}{' '}
              {isEmpty && (
                <Text
                  as="span"
                  d="inline"
                  fontSize={11}
                  color="gray.500"
                  fontWeight={400}
                >
                  (No data)
                </Text>
              )}
            </Text>
          )}
        </Flex>
        <Spacer />
        {periodLabel && (
          <Text mr={2} fontSize={10} color="gray.500" whiteSpace="nowrap">
            {periodLabel}
          </Text>
        )}
      </HStack>
      <HStack
        spacing="3px"
        w="full"
        alignItems="center"
        justifyContent="space-between"
        overflow="hidden"
      >
        <Flex alignItems="center">
          <Text fontSize={17} fontWeight={500}>
            {isEmpty
              ? 'N/A'
              : `${formatWithGoalUnit(latestValue, unit)}${
                  lastValueSuffix ? `${lastValueSuffix}` : ''
                }`}
          </Text>
          <PercentChange
            prevValue={secondLatestValue}
            value={latestValue}
            ideal={ideal === Trend.Up ? 'up' : 'down'}
            loading={false}
            fontSize={11}
            fontWeight={500}
            mb="2px"
            mr={2}
          />
        </Flex>
        <Flex ref={chartContainerRef} w="calc(100% - 120px)" h="45px">
          <svg
            width={chartDimensions.width ?? 100}
            height={chartDimensions.height ?? 100}
          >
            <Group>
              <AreaClosed
                data={sortedHistory}
                x={(d) => xScale(getXValue(d)) ?? 0}
                y={(d) => yScale(getYValue(d)) ?? 0}
                yScale={yScale}
                fill={isEmpty ? gray100 : primary25}
              />
              <LinePath
                data={sortedHistory}
                x={(d) => xScale(getXValue(d))}
                y={(d) => yScale(getYValue(d))}
                stroke={isEmpty ? gray300 : primary200}
                strokeWidth={2}
              />
            </Group>
          </svg>
        </Flex>
      </HStack>
    </Flex>
  );
};
