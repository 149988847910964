import { SpkRoutes } from '../../hooks';
import { SpokeAuthView } from '../../types';

export const WEB_ROUTES: SpkRoutes = {
  Root: '/',
  ActionItems: '/action-items',
  Auth: '/auth',
  Billing: '/billing',
  CreateBoard: '/board/create',
  Dashboard: '/dashboard',
  GetStarted: '/start',
  GetStartedQuestions: '/start/questions',
  GetStartedBookOnboarding: '/start/book-onboarding',
  GetStartedWelcome: '/start/welcome',
  GetStartedIntegrations: '/start/metrics',
  GetStartedRetro: '/start/retro',
  ImprovementGoals: '/team-goals',
  Integrations: '/integrations',
  Invitation: '/invitation',
  Logout: '/logout',
  Metrics: '/metrics',
  Onboarding: '/onboarding',
  OnboardingCreateTeam: '/onboarding/create-team',
  OnboardingSurvey: '/onboarding/survey',
  OrgSettings: '/organization/settings',
  OrgTeams: '/organization/teams',
  ParkingLot: '/parking-lot',
  ProgramTeams: '/teams',
  Retrospectives: '/retrospectives',
  SetupBitbucket: '/integrations/bitbucket/setup',
  SetupGithub: '/integrations/github/setup',
  TeamMembers: '/members',
  TeamSettings: '/settings',
  TeamPulse: '/team-pulse',
  Users: '/organization/users',
  UserSettings: '/user/settings',
  ImpactReport: '/dashboard',
  Reports: '/reports',

  TeamDigestReport: (reportId: string = '[reportId]') =>
    `/reports/team-digest/${reportId}`,

  Board: (boardId: string = '[boardId]') => `/board/${boardId}`,
  Whiteboard: (boardId: string = '[boardId]') => `/whiteboard/${boardId}`,
  InvitationBoard: (id?: string) => `/invitation/board/${id || '[boardId]'}`,

  AuthBridge: (to: string = '[to]', otp: string = '[otp]') =>
    `/bridge/${to}/${otp}`,
  AuthView: (authView: SpokeAuthView = '[authView]' as SpokeAuthView) =>
    `/auth/${authView}`,
  ImprovementGoalDrilldown: (goalId: string = '[teamGoalId]') =>
    `/team-goals/${goalId}`,
  InvitationTeam: (token?: string) =>
    `/invitation/team/${token || '[teamInviteToken]'}`,
  LegacyInvitationTeam: (token?: string) =>
    `/invitation/${token || '[teamInviteToken]'}`,

  InvitationBoardView: (
    boardId: string = '[boardId]',
    authView: SpokeAuthView = '[authView]' as SpokeAuthView
  ) => `/invitation/board/${boardId}/${authView}`,

  InvitationTeamView: (
    token: string = '[teamInviteToken]',
    authView: SpokeAuthView = '[authView]' as SpokeAuthView
  ) => `/invitation/team/${token}/${authView}`,
};
