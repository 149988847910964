import { FC, useRef, useState } from 'react';
import { DistributionBarItem, CodeActivityMetrics } from '@spoke/charts';
import {
  useNow,
  ONE_WEEK_MS,
  useDisclosure,
  useSourceControlRateLimitLock,
  useProjectManagementRateLimitLock,
  getFirstScrollableParent,
  MotionFlex,
  Flex,
  MetricsStateSwitcher,
  useRouter,
  useAssets,
  useRoutes,
} from '@spoke/common';
import { MetricsProviderCategory, TeamType } from '@spoke/graphql';
import {
  useTeamMetricsFeatureState,
  TeamMetricsState,
  useSourceControlMetrics,
  useTeamMetricsOverTime,
  useFlowMetrics,
  useTeamHasSourceControlMetricsInstalled,
  useTeamHasFlowMetricsInstalled,
  PendingMetricsPlaceholder,
  FullFlowMetrics,
  FullSourceControlMetrics,
  PendingMetricsPlaceholderProps,
  ProcessingMetricsAlert,
} from '@spoke/metrics';
import {
  useCurrentTeam,
  useBetaFeatures,
  TeamFeatureSetModal,
} from '@spoke/user';
import {
  useCurrentTeamInsights,
  useTeamInsightsSubscription,
  TeamInsightsAlert,
  TeamInsightsHistory,
} from '@spoke/insights';

export const FactsStatsMetrics: FC = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const router = useRouter();
  const routes = useRoutes();

  const now = useNow({ roundTo: 'ten-minutes' });
  const nowMs = now.getTime();
  const aWeekAgoMs = new Date(nowMs - ONE_WEEK_MS).getTime();
  const featureSetModal = useDisclosure();

  const [sourceControlRateLimited] = useSourceControlRateLimitLock();
  const [projectManagementRateLimited] = useProjectManagementRateLimitLock();

  const features = useTeamMetricsFeatureState();
  const isSourceControlEnabled = features.vcs.state === TeamMetricsState.Ready;
  const isFlowEnabled = features.flow.state === TeamMetricsState.Ready;

  const [team, { loading: currentTeamLoading }] = useCurrentTeam();
  // const [
  //   { unseenInsights, insights },
  //   { loading: currentTeamInsightsLoading },
  // ] = useCurrentTeamInsights();

  // const { teamInsights: teamInsightsEnabled } = useBetaFeatures();

  const {
    placeholderFullFlowMetrics,
    placeholderFullSourceControlMetrics,
    placeholderCodeActivity,
  } = useAssets();

  useTeamInsightsSubscription({ teamId: team?.id ?? '' });

  const [
    lastWeekSourceControlMetrics,
    {
      loading: lastWeekSourceControlMetricsLoading,
      refetch: refetchLastWeekSourceControlMetrics,
    },
  ] = useSourceControlMetrics({
    variables: { startDate: aWeekAgoMs, endDate: nowMs },
  });

  const [
    metricsOverTime,
    {
      loading: teamMetricsOverTimeLoading,
      refetch: refetchTeamMetricsOverTIme,
    },
  ] = useTeamMetricsOverTime();
  const [sourceControlMetrics, { loading: sourceControlMetricsLoading }] =
    useSourceControlMetrics();

  const [
    flowMetrics,
    { loading: flowMetricsLoading, refetch: refetchFlowMetrics },
  ] = useFlowMetrics();

  const [refetching, setRefetching] = useState(false);

  const {
    loading: sourceControlInstallationLoading,
    setUpOnOrg: sourceControlSetUpOnOrg,
    setUpOnTeam: sourceControlSetUpOnTeam,
  } = useTeamHasSourceControlMetricsInstalled();

  const {
    loading: flowMetricsInstallationLoading,
    setUpOnOrg: flowMetricsSetUpOnOrg,
    setUpOnTeam: flowMetricsSetUpOnTeam,
  } = useTeamHasFlowMetricsInstalled();

  const loading =
    refetching ||
    lastWeekSourceControlMetricsLoading ||
    currentTeamLoading ||
    flowMetricsLoading ||
    sourceControlMetricsLoading ||
    // currentTeamInsightsLoading ||
    sourceControlInstallationLoading ||
    flowMetricsInstallationLoading ||
    teamMetricsOverTimeLoading;

  const lastWeekReviewDistribution: DistributionBarItem[] =
    lastWeekSourceControlMetrics?.reviewLeaderboard.map((reviewer) => ({
      label: reviewer.userName,
      value: reviewer.count,
    })) ?? [];
  const lastWeekCommitDistribution: DistributionBarItem[] =
    lastWeekSourceControlMetrics?.workItemLeaderboard.map((reviewer) => ({
      label: reviewer.userName,
      value: reviewer.count,
    })) ?? [];

  const sourceControlIsProcessing = sourceControlMetrics?.isProcessing;

  const flowMetricsIsProcessing = flowMetrics?.isProcessing;
  const sourceControllInstalled =
    sourceControlSetUpOnOrg && sourceControlSetUpOnTeam;

  const flowMetricsInstalled = flowMetricsSetUpOnOrg && flowMetricsSetUpOnTeam;

  const sourceControlIsEmpty =
    !loading &&
    sourceControllInstalled &&
    !sourceControlIsProcessing &&
    !sourceControlMetrics?.prsPerDay &&
    !sourceControlMetrics?.cycleTimeMs;

  /**
   * Not including weekly data here because a week of empty data is too shortsighted to
   * determine that you have an empty dataset. We just show the chart empty states
   * themselves for weekly charts
   */
  const flowMetricsIsEmpty =
    !loading &&
    flowMetricsInstalled &&
    !flowMetricsIsProcessing &&
    !flowMetrics?.workItemsPerDay &&
    !flowMetrics?.cycleTimeMs;

  const showSourceControlData =
    (!sourceControlIsEmpty && !sourceControlIsProcessing) || loading;

  const showFlowMetricsData =
    (!flowMetricsIsEmpty && !flowMetricsIsProcessing) || loading;

  const onFlowMetricsPendingClick = () => {
    if (flowMetricsIsProcessing) scrollToTop();
    else router.push(routes.TeamSettings);
  };

  const onSourceControlPendingClick = () => {
    if (sourceControlIsProcessing) scrollToTop();
    else router.push(routes.TeamSettings);
  };

  const refetchData = async () => {
    setRefetching(true);
    await Promise.all([
      refetchFlowMetrics(),
      refetchTeamMetricsOverTIme(),
      refetchLastWeekSourceControlMetrics(),
    ]);
    setRefetching(false);
  };

  const scrollToTop = () => {
    if (!containerRef.current) return;
    const scrollableParent = getFirstScrollableParent(containerRef.current);
    if (!scrollableParent) return;
    scrollableParent.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const SourceControlPlaceholder: FC<
    Partial<PendingMetricsPlaceholderProps>
  > = (props) => (
    <PendingMetricsPlaceholder
      providerCategory={MetricsProviderCategory.SourceControl}
      isInstalledOnOrg={sourceControlSetUpOnOrg}
      isInstalledOnTeam={sourceControlSetUpOnTeam}
      isEmpty={sourceControlIsEmpty}
      isProcessing={sourceControlIsProcessing}
      onPendingActionClick={onSourceControlPendingClick}
      isRateLimited={Boolean(sourceControlRateLimited)}
      bgSrc=""
      {...props}
    />
  );

  const FlowMetricsPlaceholder: FC<Partial<PendingMetricsPlaceholderProps>> = (
    props
  ) => (
    <PendingMetricsPlaceholder
      providerCategory={MetricsProviderCategory.ProjectManagement}
      isInstalledOnOrg={flowMetricsSetUpOnOrg}
      isInstalledOnTeam={flowMetricsSetUpOnTeam}
      isEmpty={flowMetricsIsEmpty}
      isProcessing={flowMetricsIsProcessing}
      onPendingActionClick={onFlowMetricsPendingClick}
      isRateLimited={Boolean(projectManagementRateLimited)}
      bgSrc=""
      {...props}
    />
  );

  return (
    <MotionFlex
      ref={containerRef}
      initial={{ opacity: 0, transform: 'translateY(10px)' }}
      animate={{ opacity: 1, transform: 'translateY(0px)' }}
      exit={{ opacity: 0, transform: 'translateY(10px)' }}
      transition={{ bounce: false }}
      flexDir="column"
      pb={12}
    >
      {/* {teamInsightsEnabled && (
        <TeamInsightsAlert mb={2} insights={unseenInsights!} />
      )} */}
      {isFlowEnabled && flowMetricsIsProcessing && (
        <ProcessingMetricsAlert
          metricsName="Project Management"
          isRefreshing={refetching}
          onRefreshClick={refetchData}
          mb={2}
        />
      )}
      {isSourceControlEnabled && sourceControlIsProcessing && (
        <ProcessingMetricsAlert
          metricsName="Source Control"
          isRefreshing={refetching}
          onRefreshClick={refetchData}
          mb={2}
        />
      )}

      <Flex w="full" gap={6} minH={375} mt={2}>
        <MetricsStateSwitcher
          enabled={isFlowEnabled}
          showPlaceholder={!showFlowMetricsData}
          placeholder={() => (
            <FlowMetricsPlaceholder
              mt={1}
              flex={1}
              minW={0}
              bgSrc={placeholderFullFlowMetrics}
              bgMaxW={380}
            />
          )}
        >
          <FullFlowMetrics
            flex={1}
            minW={0}
            loading={loading}
            boxSizing="border-box"
            flowMetrics={flowMetrics}
            periodLabel="Last quarter"
          />
        </MetricsStateSwitcher>
        <MetricsStateSwitcher
          enabled={isSourceControlEnabled}
          showPlaceholder={!showSourceControlData}
          placeholder={() => (
            <SourceControlPlaceholder
              mt={1}
              flex={1}
              minW={0}
              bgSrc={placeholderFullSourceControlMetrics}
              bgMaxW={380}
            />
          )}
        >
          <FullSourceControlMetrics
            loading={loading}
            flex={1}
            minW={0}
            sourceControlMetrics={sourceControlMetrics}
            periodLabel="Last quarter"
          />
        </MetricsStateSwitcher>
      </Flex>

      <MetricsStateSwitcher
        enabled={isSourceControlEnabled}
        showPlaceholder={!showSourceControlData}
        placeholder={() => (
          <SourceControlPlaceholder
            bgSrc={placeholderCodeActivity}
            bgMaxW={800}
            mt={1}
          />
        )}
      >
        <CodeActivityMetrics
          loading={loading}
          teamType={team?.type ?? TeamType.Organization}
          pullRequestsOpen={metricsOverTime?.pullRequestsOpen ?? []}
          lastWeekReviewDistribution={lastWeekReviewDistribution}
          lastWeekCommitDistribution={lastWeekCommitDistribution}
          showReviewerDistribution={false}
          pullRequestsMergedWithoutReview={
            metricsOverTime?.pullRequestsMergedWithoutReview ?? []
          }
          mt={4}
        />
      </MetricsStateSwitcher>

      {/* {Boolean(teamInsightsEnabled && insights?.length) && (
        <>
          <Text mt={4} color="gray.700" fontSize={16} fontWeight={600} mb={3}>
            Team Insights
          </Text>
          <TeamInsightsHistory insights={insights ?? []} />
        </>
      )} */}
      <TeamFeatureSetModal
        isOpen={featureSetModal.isOpen}
        onClose={featureSetModal.close}
      />
    </MotionFlex>
  );
};
