import { useState, FC } from 'react';
import { Modal, ModalOverlay, ModalProps } from '@spoke/common';
import { AsyncFeedbackListModalBody } from './AsyncFeedbackListModal';
import { AsyncFeedbackConfigModalContent } from './AsyncFeedbackConfigModal';
import {
  BoardAsyncSchedule,
  useArchiveBoardAsyncScheduleMutation,
  useBoardAsyncSchedulesByTeamIdQuery,
  useTriggerBoardAsyncScheduleByIdMutation,
} from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';

type AsyncFeedbackModalProps = Omit<ModalProps, 'children'>;

export const AsyncFeedbackModalContent: FC<AsyncFeedbackModalProps> = ({
  onClose,
}) => {
  const [currentTeam] = useCurrentTeam();
  const { data, loading, error, refetch } = useBoardAsyncSchedulesByTeamIdQuery(
    {
      variables: { teamId: currentTeam?.id ?? '' },
    }
  );

  const [triggerBoardAsyncScheduleById] =
    useTriggerBoardAsyncScheduleByIdMutation();

  const [archiveBoardAsyncSchedule] = useArchiveBoardAsyncScheduleMutation();

  const asyncFeedbackConfigs = data?.boardAsyncSchedulesByTeamId;

  const [createConfigClicked, setCreateConfigClicked] = useState(false);
  const [selectedConfig, setSelectedConfig] =
    useState<BoardAsyncSchedule | null>(null);

  const handleGoBack = () => {
    setCreateConfigClicked(false);
    setSelectedConfig(null);
    refetch();
  };

  const handleConfigSelect = (config: BoardAsyncSchedule) => {
    setSelectedConfig(config);
  };

  const handleDeleteConfig = (configId: string) => {
    setSelectedConfig(null);
    archiveBoardAsyncSchedule({ variables: { id: configId } }).then(() => {
      refetch();
    });
  };

  const onTriggerClick = (configId: string) => {
    triggerBoardAsyncScheduleById({ variables: { id: configId } }).then(() => {
      refetch();
    });
  };

  if (!selectedConfig && !createConfigClicked) {
    return (
      <AsyncFeedbackListModalBody
        asyncFeedbackConfigs={asyncFeedbackConfigs ?? []}
        onCreateConfig={() => setCreateConfigClicked(true)}
        onConfigClick={handleConfigSelect}
        onDeleteConfig={handleDeleteConfig}
        onTriggerClick={onTriggerClick}
      />
    );
  }

  return (
    <AsyncFeedbackConfigModalContent
      config={selectedConfig ?? undefined}
      onGoBack={handleGoBack}
      isOpen={true} // or a relevant boolean value
      onClose={onClose}
    />
  );
};

export const AsyncFeedbackModal: FC<AsyncFeedbackModalProps> = (props) => {
  return (
    <Modal {...props}>
      <ModalOverlay>
        <AsyncFeedbackModalContent {...props} />
      </ModalOverlay>
    </Modal>
  );
};
