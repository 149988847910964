import { FC, useEffect, useState } from 'react';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { BsSlashCircle, BsStopwatch } from 'react-icons/bs';
import { InFlightWorkItemsQueryDataWorkItem } from '../../queries';
import {
  ONE_DAY_MS,
  Popover,
  PopoverAnchor,
  COLOR_BY_QUALITY_NAME,
  PopoverContent,
  HStack,
  Icon,
  ICON_BY_WORK_ITEM_ORIGIN,
  plural,
  PopoverArrow,
  Link,
  Text,
} from '@spoke/common';

type Props = { workItem: InFlightWorkItemsQueryDataWorkItem };
export const WorkItemHeatmapSquare: FC<Props> = ({ workItem }) => {
  const [hovering, setHovering] = useState(false);
  const [delayedHovering, setDelayedHovering] = useState(false);

  useEffect(() => {
    if (!hovering) {
      setDelayedHovering(false);
      return;
    }

    const timeoutId = setTimeout(() => {
      setDelayedHovering(hovering);
    }, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [hovering]);

  const daysStale = Math.ceil(workItem.staleForMs / ONE_DAY_MS);
  const daysOld = Math.ceil(
    (Date.now() - new Date(workItem.createdAt).getTime()) / ONE_DAY_MS
  );
  const daysDiffToCycleTime = Math.floor(
    Math.abs(workItem.timeLeftToExternalStatusCycleTimeMs) / ONE_DAY_MS
  );

  return (
    <Popover placement="top" isOpen={delayedHovering}>
      <PopoverAnchor>
        <Link
          bg={COLOR_BY_QUALITY_NAME[workItem.statusAgeQuality]}
          borderRadius="4px"
          cursor="pointer"
          minW={4}
          minH={4}
          href={workItem.externalUrl}
          target="_blank"
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
        />
      </PopoverAnchor>
      <PopoverContent
        w="full"
        p={2}
        boxShadow="1px 2px 3px 0 rgba(0, 0, 0, 0.05) !important"
        borderRadius="4px"
      >
        <HStack spacing={1} mb={1}>
          <Icon w={3} h={3} as={ICON_BY_WORK_ITEM_ORIGIN[workItem.origin]} />{' '}
          <Text fontSize={12} pt="3px" fontWeight={400} color="gray.600">
            {workItem.slug}
          </Text>
        </HStack>
        <Text fontSize={14} lineHeight="15px" color="gray.800" maxW={200}>
          {workItem.title}
        </Text>
        <HStack mt={1}>
          <HStack spacing={1}>
            <Icon as={BsStopwatch} w={3} h={3} />
            <Text fontSize={12} pt="2px" whiteSpace="nowrap">
              {daysOld} {plural('day', daysOld)} old
            </Text>
          </HStack>
          <HStack spacing={1}>
            <Icon as={BsSlashCircle} w={3} h={3} />
            <Text fontSize={12} pt="2px" whiteSpace="nowrap">
              Time in status: {daysStale} {plural('day', daysStale)}
            </Text>
          </HStack>
        </HStack>
        <HStack mt={1} spacing={1}>
          <Icon as={AiOutlineExclamationCircle} w={3} h={3} />
          <Text fontSize={12} lineHeight="12px" pt="2px" maxW={200}>
            {workItem.timeLeftToExternalStatusCycleTimeMs > 0
              ? `${daysDiffToCycleTime} ${plural(
                  'day',
                  daysDiffToCycleTime
                )} from reaching cycle time`
              : `Cycle time exceeded by ${daysDiffToCycleTime} ${plural(
                  'day',
                  daysDiffToCycleTime
                )}`}{' '}
            for{' '}
            <Text as="span" fontWeight={500}>
              {workItem.externalStatusName}
            </Text>
          </Text>
        </HStack>
        <PopoverArrow />
      </PopoverContent>
    </Popover>
  );
};
