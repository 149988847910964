import { useRouter } from '../../../hooks/etc';

/**
 * Performs better than useCurrentBoard for when we only need its id and viewSummary status
 */
export const useCurrentBoardId = (): [string | null, boolean] => {
  const router = useRouter();

  const { boardId, viewSummary } = router.query;

  // Extract board ID from the query string
  const id = typeof boardId === 'string' ? boardId.split('?')[0] : null;
  // Directly check for viewSummary in the query parameters
  const summary =
    typeof viewSummary === 'string' ? viewSummary === 'true' : false;

  return [id, summary];
};
