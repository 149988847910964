import { FiLifeBuoy } from 'react-icons/fi';
import { StackProps } from '@chakra-ui/react';
import { FC } from 'react';
import {
  VStack,
  HStack,
  Icon,
  SpkLink,
  HELP_DESK_URL,
  Text,
  Link,
} from '@spoke/common';

export const NeedHelpBox: FC<StackProps> = ({
  children: _children,
  ...props
}) => (
  <VStack
    bg="gray.50"
    p={4}
    borderRadius="lg"
    alignItems="flex-start"
    {...props}
  >
    <HStack spacing={1}>
      <Icon as={FiLifeBuoy} mb="3px" w={4} h={4} color="gray.700" />
      <Text fontWeight={600} fontSize={14} color="gray.700">
        Need help?
      </Text>
    </HStack>

    <Text fontSize={12} color="gray.600">
      Is there something that you need help with, miss or wish was different?
    </Text>
    <Text fontSize={12} color="gray.600">
      Feel free to{' '}
      <Link
        textDecor="underline"
        href="mailto:support@scatterspoke.com"
      >
        talk to us
      </Link>{' '}
      or check our{' '}
      <SpkLink href={HELP_DESK_URL} passHref>
        <Link target="_blank" textDecor="underline">
          Help Desk
        </Link>
      </SpkLink>
      .
    </Text>
  </VStack>
);
