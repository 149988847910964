import { useToken } from '@chakra-ui/react';
import { FC, useCallback, useMemo, useState } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';
import { Flex, HStack, Button, Text } from '../';
import 'react-day-picker/dist/style.css';

export type DateRangePickerProps = {
  value?: DateRange | null;
  onChange?: (newDateRange: DateRange | null) => void;
};

export const DateRangePicker: FC<DateRangePickerProps> = ({
  onChange,
  value: initialValue,
}) => {
  const [value, setValue] = useState<DateRange | null | undefined>(
    initialValue
  );

  const ranges = [
    {
      label: 'Last 30 Days',
      getDates: () => {
        const today = new Date();
        const thirtyDaysAgo = new Date(today);
        thirtyDaysAgo.setDate(today.getDate() - 30);
        return { from: thirtyDaysAgo, to: today };
      },
    },
    {
      label: 'Last Quarter',
      getDates: () => {
        const today = new Date();
        const threeMonthsAgo = new Date(today);
        threeMonthsAgo.setMonth(today.getMonth() - 3);
        return { from: threeMonthsAgo, to: today };
      },
    },
    {
      label: 'Last Year',
      getDates: () => {
        const today = new Date();
        const oneYearAgo = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 365
        );
        return { from: oneYearAgo, to: today };
      },
    },
  ];

  const onSelect = useCallback(
    (newValue: DateRange | undefined) => {
      if (!newValue) return;
      setValue(newValue);
      onChange?.(newValue);
    },
    [onChange]
  );

  const onRangeSelect = useCallback(
    (range: DateRange) => {
      setValue(range);
      onChange?.(range);
    },
    [onChange]
  );

  const [primary400] = useToken('colors', ['primary.400']);

  const css = useMemo(
    () => `
    .rdp {
      --rdp-cell-size: 30px;
      --rdp-accent-color: ${primary400};
      --rdp-background-color: #e7edff;
      --rdp-accent-color-dark: ${primary400};
      --rdp-background-color-dark: ${primary400};
      --rdp-outline: 2px solid var(--rdp-accent-color);
      --rdp-outline-selected: 2px solid ${primary400};
    }
    .rdp-month {
      width: 100%;
    }
    .today {
      color: black;
      font-weight: 600;
    }
    .rdp-day_selected {
      background-color: ${primary400};
    }
`,
    [primary400]
  );

  return (
    <>
      <style>{css}</style>
      <Flex direction="column" width="100%">
        <DayPicker
          mode="range"
          selected={value ?? undefined}
          onSelect={onSelect}
          modifiersClassNames={{
            today: 'today',
            selected: 'rdp-day_selected',
          }}
          styles={{
            caption: { marginBottom: '0.5rem' },
            months: { width: '100%' },
          }}
        />
        <Text fontSize={12} color="gray.500" textAlign="center">
          Double-click: new range
          <br />
          Single-click: add to current range
        </Text>
        <HStack spacing={2} p={2}>
          {ranges.map((range) => (
            <Button
              key={range.label}
              size="xs"
              variant="outline"
              onClick={() => onRangeSelect(range.getDates())}
            >
              {range.label}
            </Button>
          ))}
        </HStack>
      </Flex>
    </>
  );
};