import { PopoverAnchor } from '@chakra-ui/react';
import { FC, useRef } from 'react';
import { useDisclosure, useOutsideClick } from '../hooks';
import { Box, BoxProps } from './Box';
import { DatePicker, DatePickerProps } from './DatePicker';
import { Popover, PopoverProps } from './Popover/Popover';
import { PopoverContent } from './Popover/PopoverContent';

type DatePickerPopoverProps = DatePickerProps & {
  popoverProps?: PopoverProps;
  containerProps?: BoxProps;
  childrenContainerProps?: BoxProps;
  closeOnChange?: boolean;
  pastOnly?: boolean;
  futureOnly?: boolean;
};

export const DatePickerPopover: FC<DatePickerPopoverProps> = ({
  popoverProps,
  containerProps,
  childrenContainerProps,
  children,
  closeOnChange,
  onChange: _onChange = () => {},
  ...rest
}) => {
  const disclosure = useDisclosure();
  const contentRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  useOutsideClick(disclosure.close, contentRef, triggerRef);

  const onChange = closeOnChange
    ? (...args: Parameters<typeof _onChange>) => {
        disclosure.close();
        _onChange?.(...args);
      }
    : _onChange;

  return (
    <Popover
      closeOnBlur={false}
      isOpen={disclosure.isOpen}
      {...(popoverProps ?? {})}
    >
      <PopoverAnchor>
        <Box
          ref={triggerRef}
          onClick={disclosure.toggle}
          {...(childrenContainerProps ?? {})}
        >
          {children}
        </Box>
      </PopoverAnchor>
      <PopoverContent>
        <Box flex="1" ref={contentRef} {...containerProps}>
          <DatePicker onChange={onChange} {...rest} />
        </Box>
      </PopoverContent>
    </Popover>
  );
};
