import { FC } from 'react';
import {
  FlexProps,
  useDisclosure,
  Flex,
  Button,
  Divider,
  StepsTutorial,
  SpkLink,
  HELP_DESK_URL,
  Link,
  Image,
  Text,
  useAssets,
  SpkAssetKey,
  HELP_ACTIVITY_FEED_URL,
} from '@spoke/common';
import { useTeamType } from '@spoke/user';

const TUTORIAL_STEPS = [
  {
    image: SpkAssetKey.ImageGoalTutorial1,
    text: 'Choose from our library of goals, we cover a vast range of metrics from SPACE, DORA and Agile.',
  },
];

type ActivityFeedSummaryEmptyProps = FlexProps & {
  afterCreated?: () => void;
};
export const ActivityFeedEmptyState: FC<ActivityFeedSummaryEmptyProps> = ({
  afterCreated,
  ...rest
}) => {
  const { lowercaseTeamType } = useTeamType();
  const createGoalModal = useDisclosure();

  const { illustrationIncrease } = useAssets();

  return (
    <Flex flexDir="column" alignItems="center" mb={4} {...rest}>
      <Image
        src={illustrationIncrease}
        alt="No items in your team's feed"
        w={300}
      />
      <Text fontSize={18} fontWeight={700} color="gray.900">
        You don&apos;t have any items in your feed for this {lowercaseTeamType}{' '}
        yet.
      </Text>
      <Text fontSize={14} fontWeight={400} mt="2px" color="gray.500">
        You can hook up{' '}
        <SpkLink href="/metrics" passHref>
          <Link>metrics</Link>
        </SpkLink>
        , start a{' '}
        <SpkLink href="/retrospectives" passHref>
          <Link>retrospectives</Link>
        </SpkLink>
        , or create a {lowercaseTeamType}{' '}
        <SpkLink href="/team-goals" passHref>
          <Link>goal</Link>
        </SpkLink>{' '}
        to get started.
      </Text>
      <>
        <Divider my={7} />
        <Text color="gray.600" fontSize={14}>
          {`You can find more information about how our ${lowercaseTeamType} activity feed works in
                our`}{' '}
          <SpkLink href={HELP_ACTIVITY_FEED_URL} passHref>
            <Link target="_blank" textDecor="underline">
              Help Desk.
            </Link>
          </SpkLink>
        </Text>
      </>
    </Flex>
  );
};
