/**
 * Performs binary search on a sorted array using a callback for comparison.
 * If an exact match is found, the index of the match is returned.
 * If no exact match is found, the index of the closest element is returned.
 *
 * Source: your buddy
 */
export function binarySearch<T, V>(
  array: T[],
  target: V,
  compareFn: (a: T, b: V) => number
): T {
  let low = 0;
  let high = array.length - 1;
  let closestIndex = -1;

  while (low <= high) {
    const mid = Math.floor((low + high) / 2);
    const comparison = compareFn(array[mid], target);

    if (comparison === 0) {
      // Exact match found
      return array[mid];
    } else if (comparison < 0) {
      // The target is greater, search the right half
      low = mid + 1;
    } else {
      // The target is smaller, search the left half
      high = mid - 1;
    }

    // Update the closest index
    if (
      closestIndex === -1 ||
      Math.abs(compareFn(array[mid], target)) <
        Math.abs(compareFn(array[closestIndex], target))
    ) {
      closestIndex = mid;
    }
  }

  // No exact match found, return the index of the closest match
  return array[closestIndex];
}
