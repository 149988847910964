import { Flex, Icon, Heading, Box, Text } from '@chakra-ui/react';
import {
  MetricsIcon,
  OrganizationIcon,
  ProgramIcon,
  TeamIcon,
  Tooltip,
} from '@spoke/common';
import { Activity, ActivityType } from '@spoke/graphql';
import { formatDistanceToNow } from 'date-fns';
import { BiUser } from 'react-icons/bi';
import { FiInbox } from 'react-icons/fi';
import { GrAnnounce } from 'react-icons/gr';
import {
  MdOutlineStickyNote2,
  MdOutlineAutoAwesome,
  MdOutlinedFlag,
  MdQueryStats,
  MdOutlineAnnouncement,
  MdOutlineBookmarkBorder,
} from 'react-icons/md';
import { TbActivityHeartbeat, TbSpeakerphone } from 'react-icons/tb';

export const ActivityFeedCardHeader = ({
  activity,
}: {
  activity: Activity;
}) => {
  let IconType;
  let tooltipLabel;
  switch (activity.type) {
    case ActivityType.Account:
      IconType = OrganizationIcon;
      tooltipLabel = 'Account';
      break;
    case ActivityType.ActionItem:
      IconType = MdOutlineBookmarkBorder;
      tooltipLabel = 'Action Item';
      break;
    case ActivityType.Announcements:
      IconType = TbSpeakerphone;
      tooltipLabel = 'Announcement';
      break;
    case ActivityType.BasicMessage:
      IconType = TbActivityHeartbeat;
      tooltipLabel = 'Activity';
      break;
    case ActivityType.Goal:
      IconType = MdOutlinedFlag;
      tooltipLabel = 'Goal';
      break;
    case ActivityType.Insight:
      IconType = MdOutlineAutoAwesome;
      tooltipLabel = 'Insight';
      break;
    case ActivityType.Metrics:
      IconType = MetricsIcon;
      tooltipLabel = 'Metrics';
      break;
    case ActivityType.ParkingLot:
      IconType = FiInbox;
      tooltipLabel = 'Parking Lot';
      break;
    case ActivityType.Program:
      IconType = ProgramIcon;
      tooltipLabel = 'Program';
      break;
    case ActivityType.ProgramStats:
      IconType = MdQueryStats;
      tooltipLabel = 'Program Stats';
      break;
    case ActivityType.Retrospective:
      IconType = MdOutlineStickyNote2;
      tooltipLabel = 'Retrospective';
      break;
    case ActivityType.Team:
      IconType = TeamIcon;
      tooltipLabel = 'Team';
      break;
    case ActivityType.TeamStats:
      IconType = MdQueryStats;
      tooltipLabel = 'Team Stats';
      break;
    case ActivityType.User:
      IconType = BiUser;
      tooltipLabel = 'User';
      break;
    default:
      IconType = TbActivityHeartbeat;
      tooltipLabel = 'Activity';
  }

  return (
    <Flex justifyContent="flex-start">
      <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
        <Tooltip
          label={tooltipLabel}
          openDelay={350}
          placement="top"
          fontSize={12}
          maxW={250}
          variant="white"
          hasArrow
        >
          <Box tabIndex={-1}>
            <Icon as={IconType} color="primary.500" w={7} h={7} />
          </Box>
        </Tooltip>

        <Box>
          <Heading size="sm">{activity.headerTitle}</Heading>

          <Text color={'gray.500'} fontSize={13}>
            {formatDistanceToNow(new Date(activity.createdAt))} ago
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};
