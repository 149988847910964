import React, { FC } from 'react';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';

import {
  Box,
  Heading,
  VStack,
  Text,
  HStack,
  Icon,
  Button,
  useAssets,
  Image,
  useRouter,
  useRoutes,
} from '@spoke/common';
interface UpgradeCardProps {
  title: string;
  description: string;
  features: string[];
}

export const UpgradeCard: FC<UpgradeCardProps> = ({
  title,
  description,
  features,
}) => {
  const { illustrationTakeaways } = useAssets();
  const router = useRouter();
  const routes = useRoutes();
  return (
    <Box
      borderWidth={1}
      borderRadius="lg"
      borderColor="gray.200"
    //   shadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      bg="white"
      my={4}
      p={8}
    >
      <VStack spacing={4} textAlign="center">
        <Heading as="h2" size="lg" fontWeight="bold">
          {title}
        </Heading>
        <Text>{description}</Text>
        {/* <Box w="1/2" bg="purple.600" h={4} borderRadius="full" /> */}
        <Image src={illustrationTakeaways} alt="Upgrade to Spoke Pro" />
        <VStack spacing={2} align="start" mb={4}>
          {features.map((feature) => (
            <HStack key={feature} spacing={2}>
              <Icon as={IoMdCheckmarkCircleOutline} color="green.600" />
              <Text>{feature}</Text>
            </HStack>
          ))}
        </VStack>
        <Button
          bg="purple.600"
          color="white"
          _hover={{ bg: 'purple.700' }}
          fontWeight="bold"
          py={2}
          px={4}
          borderRadius="md"
          onClick={() => router.push(routes.Billing)}
        >
          Upgrade Now
        </Button>
      </VStack>
    </Box>
  );
};
