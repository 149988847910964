import { FC } from 'react';
import { DistributionBarItem, DistributionBars } from './DistributionBars';
import { TimeSeriesCard } from './TimeSeriesCard';
import {
  Trend,
  ImprovementGoalUnit,
  TimeSeries,
  TeamType,
} from '@spoke/graphql';
import { FlexProps, Flex, Skeleton, Grid } from '@spoke/common';

type Props = FlexProps & {
  teamType: TeamType;
  lastWeekReviewDistribution: DistributionBarItem[];
  lastWeekCommitDistribution: DistributionBarItem[];
  pullRequestsOpen: TimeSeries[];
  pullRequestsMergedWithoutReview: TimeSeries[];
  loading: boolean;
  showReviewerDistribution?: boolean;
};
export const CodeActivityMetrics: FC<Props> = ({
  pullRequestsMergedWithoutReview,
  pullRequestsOpen,
  lastWeekReviewDistribution,
  loading,
  showReviewerDistribution = true,
  lastWeekCommitDistribution,
  teamType,
  ...rest
}) => (
  <Flex mt={2} flexDir="column" {...rest}>
    {teamType === TeamType.Team && (
      <Grid templateColumns="repeat(2, minmax(0, 1fr))" w="full" gap={6} mb={4}>
        <Skeleton isLoaded={!loading} layerStyle="outlineGray">
          <TimeSeriesCard
            history={pullRequestsOpen ?? []}
            ideal={Trend.Up}
            unit={ImprovementGoalUnit.Abstract}
            title="Open Pull Requests"
            periodLabel="Last week"
          />
        </Skeleton>
        <Skeleton isLoaded={!loading} layerStyle="outlineGray">
          <TimeSeriesCard
            history={pullRequestsMergedWithoutReview ?? []}
            ideal={Trend.Down}
            unit={ImprovementGoalUnit.Abstract}
            title="Pull Requests Merged W/O Review"
            periodLabel="Last week"
          />
        </Skeleton>
      </Grid>
    )}
    {showReviewerDistribution && (
      <Flex flexDir="column">
        {teamType === TeamType.Team && (
          <Skeleton d="flex" isLoaded={!loading}>
            <DistributionBars
              data={lastWeekReviewDistribution ?? []}
              title="Reviewer distribution"
              description="The distribution of Pull Request reviewers in the team"
              periodLabel="Last week"
              unit={ImprovementGoalUnit.PullRequests}
              mb={3}
            />
          </Skeleton>
        )}
        <Skeleton d="flex" isLoaded={!loading}>
          <DistributionBars
            data={lastWeekCommitDistribution ?? []}
            title="Commit Distribution"
            description="The distribution of commits per work item"
            periodLabel="Last week"
            unit={ImprovementGoalUnit.PullRequests}
            mb={3}
          />
        </Skeleton>
      </Flex>
    )}
  </Flex>
);
