import { FC } from 'react';
import {
  ModalProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  HStack,
  Heading,
  ModalCloseButton,
  ModalBody,
  Box,
} from '@spoke/common';
import { ActivityFeed } from './ActivityFeed';

export const ActivityFeedModal: FC<Omit<ModalProps, 'children'>> = ({
  isOpen,
  onClose,
}) => {
  const tes = 'something';
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p={6} maxW={800}>
        {/* <ModalHeader>
          <HStack>
            <Heading size="md">Activity Feed</Heading>
          </HStack>
        </ModalHeader> */}
        <ModalCloseButton />
        <ModalBody>
          <ActivityFeed />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
