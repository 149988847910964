import { FC, useState, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AiTwotoneCalendar } from 'react-icons/ai';
import { BiUser, BiTrashAlt } from 'react-icons/bi';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import {
  MdOutlineBookmarkAdd,
  MdOutlinedFlag,
  MdOutlineStickyNote2,
  MdOutlineChat,
} from 'react-icons/md';
import {
  useDeleteActionItemHandler,
  useEditActionItemHandler,
  useAddActionItemCommentHandler,
  useDeleteActionItemCommentHandler,
} from '../handlers';
import { useActionItemById } from '../queries';
import { useFetchTeamMembers } from '@spoke/user';
import { useImprovementGoals } from '@spoke/improvement-goals';
import { ImprovementGoal, User } from '@spoke/graphql';
import {
  SpokeFormRules,
  truncateWithCount,
  SpkTime,
  TextArea,
  DatePickerPopover,
  MultiSelect,
  ImprovementGoalOption,
  ClickConfirmation,
  log,
  ModalProps,
  useDisclosure,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  HStack,
  Icon,
  Heading,
  ModalBody,
  Flex,
  Spinner,
  VStack,
  FormLabel,
  FormControl,
  Input,
  FormErrorMessage,
  Spacer,
  Divider,
  Modal,
  ModalOverlay,
  Button,
  Text,
  AddCommentBox,
  BoardInfoCard,
  useCurrentBoardId,
  Comment,
  useCurrentUser,
} from '@spoke/common';

export type EditActionItemFormSchema = {
  title: string;
  description: string;
};

const rules: SpokeFormRules<EditActionItemFormSchema> = {
  title: { required: 'Title is required' },
  description: {},
};

type EditActionItemModalProps = {
  actionItemId: string;
  onEdited?: () => void;
  onDeleted?: () => void;
} & Omit<ModalProps, 'children'>;

export const EditActionItemModalContent: FC<EditActionItemModalProps> = (
  props
) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<EditActionItemFormSchema>();

  const [actionItem] = useActionItemById(props.actionItemId);

  const [currentUser] = useCurrentUser();
  const [boardId] = useCurrentBoardId();
  const [{ activeGoals: teamGoals }] = useImprovementGoals();
  const [fetchTeamMembers] = useFetchTeamMembers();

  // Ideally these states would live in the form schema itself, but typescript gets mad about something
  // https://github.com/react-hook-form/react-hook-form/issues/6679
  const [selectedAssignees, setSelectedAssignees] = useState<User[]>([]);
  const [selectedGoals, setSelectedGoals] = useState<ImprovementGoal[]>([]);
  const [dueDate, setDueDate] = useState<Date | null>(null);

  const [handleDeleteActionItem] = useDeleteActionItemHandler();
  const [handleEditActionItem] = useEditActionItemHandler();
  const [onAddComment] = useAddActionItemCommentHandler();
  const [onDeleteComment] = useDeleteActionItemCommentHandler();

  const assigneesDropdown = useDisclosure();
  const goalsDropdown = useDisclosure();
  const originatedFromSection = useDisclosure();

  const onSubmit = useCallback(
    async (form: EditActionItemFormSchema) => {
      log.info('Handling EditActionItem submit', { form });

      const success = await handleEditActionItem(props.actionItemId, {
        title: form.title,
        description: form.description,
        dueDate: dueDate?.getTime() ?? null,
        boardId,
        assigneeIds: selectedAssignees.map((u) => u.id),
        improvementGoalIds: selectedGoals.map((g) => g.id),
      });

      if (success) props.onClose();
      if (success) props.onEdited?.();
    },
    [
      boardId,
      dueDate,
      handleEditActionItem,
      props,
      selectedAssignees,
      selectedGoals,
    ]
  );

  const onDelete = async () => {
    await handleDeleteActionItem(props.actionItemId).then(() => {
      props.onClose?.();
      props.onDeleted?.();
    });
  };

  const getAssigneeOptionsByTerm = async (term: string): Promise<User[]> => {
    const teamId = currentUser?.team?.id;

    if (!teamId) {
      log.error('Cannot fetch assignee options without loaded user teamId', {
        currentUser,
      });
      return [];
    }

    return fetchTeamMembers({
      term,
      teamId,
    }) as Promise<User[]>;
  };

  useEffect(() => {
    setValue('title', actionItem?.title || '');
  }, [actionItem?.title, setValue]);

  useEffect(() => {
    setValue('description', actionItem?.description || '');
  }, [actionItem?.description, setValue]);

  useEffect(() => {
    setDueDate(actionItem?.dueDate ? new Date(actionItem.dueDate) : null);
  }, [actionItem?.dueDate]);

  useEffect(() => {
    setSelectedGoals((actionItem?.improvementGoals as ImprovementGoal[]) ?? []);
  }, [actionItem?.improvementGoals]);

  useEffect(() => {
    setSelectedAssignees((actionItem?.assignees as User[]) ?? []);
  }, [actionItem?.assignees]);

  const loading: boolean = !actionItem;

  const improvementGoalsLabel =
    truncateWithCount(selectedGoals, (goal) => goal.type.name, 20) ||
    'Select a goal';
  const assigneesLabel =
    truncateWithCount(selectedAssignees, 'name', 20) || 'Unassigned';
  const dueDateLabel = dueDate
    ? SpkTime.format(dueDate, 'MMM dd, yyyy')
    : 'Select due date';

  const originBoard = actionItem?.board ?? null;

  return (
    <ModalContent p={8} maxW={700}>
      <ModalCloseButton />
      <ModalHeader p={0} mb={4}>
        <HStack>
          <Icon as={MdOutlineBookmarkAdd} w={6} h={6} />
          <Heading mb={1} fontSize={24} color="gray.900">
            Edit Action Item
          </Heading>
        </HStack>
      </ModalHeader>
      <ModalBody p={0}>
        {loading && (
          <Flex justifyContent="center">
            <Spinner
              color="primary.500"
              mt={40}
              mb={40}
              size="xl"
              speed="0.5s"
              thickness="5px"
            />
          </Flex>
        )}
        {!loading && (
          <Flex
            flexDir="column"
            alignItems="start"
            justifyContent="center"
            as="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <HStack mb={4} alignItems="start">
              {originBoard && (
                <VStack spacing={0} alignItems="start">
                  <FormLabel fontSize={15} htmlFor="title">
                    From
                  </FormLabel>
                  {originBoard && (
                    <BoardInfoCard
                      iconUrl={originBoard.format.iconImageUrl}
                      formatName={originBoard.format.displayName}
                      boardName={originBoard.name}
                      createdAt={originBoard.createdAt}
                      boardType={originBoard.type}
                    />
                  )}
                </VStack>
              )}
              <VStack spacing={0} alignItems="left">
                <FormLabel fontSize={15} htmlFor="title">
                  Created by
                </FormLabel>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  layerStyle="outlineGray"
                  fontWeight={500}
                  fontSize={14}
                  px={1}
                  pt={1}
                  pb="2px"
                >
                  {actionItem?.author?.name}
                </Flex>
              </VStack>
            </HStack>
            <FormControl mb={4}>
              <VStack spacing={4}>
                <FormControl isInvalid={Boolean(errors.title)}>
                  <FormLabel fontSize={15} htmlFor="title">
                    Title
                  </FormLabel>
                  <Input
                    id="title"
                    placeholder="What needs to be done?"
                    minH={10}
                    alignItems="center"
                    autoFocus
                    {...register('title', rules.title)}
                  />
                  <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
                </FormControl>
              </VStack>
            </FormControl>
            <FormControl mb={4}>
              <VStack spacing={4}>
                <FormControl isInvalid={Boolean(errors.description)}>
                  <FormLabel fontSize={15} htmlFor="description">
                    Description
                  </FormLabel>
                  <TextArea
                    id="description"
                    placeholder="Write a description for this action item"
                    h={120}
                    resize="none"
                    focusBorderColor="primary.500"
                    p={2}
                    {...register('description', rules.description)}
                  />
                  <FormErrorMessage>
                    {errors?.description?.message}
                  </FormErrorMessage>
                </FormControl>
              </VStack>
            </FormControl>
            <HStack mb={4} w="full" justifyContent="stretch">
              <FormControl flex="1">
                <FormLabel fontSize={15} htmlFor="dueDate">
                  Due date
                </FormLabel>
                <DatePickerPopover
                  futureOnly
                  value={dueDate}
                  onChange={(newDate) => {
                    setDueDate(newDate);
                  }}
                >
                  <Button
                    id="dueDate"
                    variant="outlineGray"
                    leftIcon={<Icon as={AiTwotoneCalendar} />}
                    justifyContent="start"
                    w="full"
                  >
                    {dueDateLabel}
                    <Spacer />
                    <Icon as={BsChevronDown} />
                  </Button>
                </DatePickerPopover>
              </FormControl>
              <FormControl flex="1">
                <FormLabel fontSize={15}>Assignees</FormLabel>
                <MultiSelect
                  searchable
                  values={selectedAssignees}
                  onChange={setSelectedAssignees}
                  onClose={assigneesDropdown.close}
                  isOpen={assigneesDropdown.isOpen}
                  getOptions={(term) => getAssigneeOptionsByTerm(term)}
                  idKey="id"
                  labelKeyOrFn="name"
                >
                  <Button
                    justifyContent="start"
                    onClick={assigneesDropdown.toggle}
                    variant="outlineGray"
                    leftIcon={<Icon as={BiUser} />}
                    w="full"
                  >
                    {assigneesLabel}
                    <Spacer />
                    <Icon as={BsChevronDown} />
                  </Button>
                </MultiSelect>
              </FormControl>
              <FormControl flex="1">
                <FormLabel fontSize={15}>Improvement Goals</FormLabel>
                <MultiSelect
                  values={selectedGoals}
                  onChange={setSelectedGoals}
                  onClose={goalsDropdown.close}
                  isOpen={goalsDropdown.isOpen}
                  getOptions={() => (teamGoals as ImprovementGoal[]) || []}
                  renderOption={ImprovementGoalOption}
                  idKey="id"
                  labelKeyOrFn={(goal) => goal.type?.name ?? ''}
                >
                  <Button
                    justifyContent="start"
                    onClick={goalsDropdown.toggle}
                    variant="outlineGray"
                    leftIcon={<Icon as={MdOutlinedFlag} />}
                    w="full"
                  >
                    {improvementGoalsLabel}
                    <Spacer />
                    <Icon as={BsChevronDown} />
                  </Button>
                </MultiSelect>
              </FormControl>
            </HStack>
            <Divider />
            {Boolean(actionItem?.children.length) && (
              <Flex
                overflow="hidden"
                flexDir="column"
                borderWidth={2}
                borderColor="gray.100"
                borderStyle="solid"
                borderRadius="lg"
                bg="gray.50"
                py={2}
                px={4}
                w="full"
                mt={2}
              >
                <Flex
                  alignItems="center"
                  cursor="pointer"
                  minH={10}
                  gap={1}
                  onClick={originatedFromSection.toggle}
                >
                  <Text color="gray.600" fontSize={16} fontWeight={500}>
                    Originated from
                  </Text>
                  <Icon
                    color="gray.600"
                    w={4}
                    h={4}
                    as={MdOutlineStickyNote2}
                  />
                  <Text color="gray.600" fontSize={16} fontWeight={500}>
                    {actionItem?.children.length}
                  </Text>
                  <Spacer />
                  <Icon
                    w={5}
                    h={5}
                    mr={2}
                    color="gray.500"
                    as={
                      originatedFromSection.isOpen ? BsChevronUp : BsChevronDown
                    }
                  />
                </Flex>
                {originatedFromSection.isOpen &&
                  actionItem?.children.map((child) => (
                    <Flex
                      key={child.id}
                      justifyContent="center"
                      alignItems="center"
                      borderWidth={2}
                      textAlign="center"
                      borderColor="gray.100"
                      borderStyle="solid"
                      borderRadius="md"
                      color="gray.500"
                      bg="white"
                      w="full"
                      my={1}
                      boxShadow="sm"
                      p={3}
                    >
                      {child.text}
                    </Flex>
                  ))}
              </Flex>
            )}
            <Flex
              borderWidth={2}
              borderColor="gray.100"
              borderStyle="solid"
              borderRadius="lg"
              flexDir="column"
              my={4}
              px={4}
              py={3}
              w="full"
            >
              <Flex alignItems="center">
                <Icon color="gray.600" w={5} h={5} mr={1} as={MdOutlineChat} />
                <Text color="gray.700" fontWeight={500} fontSize={17} mb="2px">
                  Comments
                </Text>
              </Flex>
              <AddCommentBox
                w="full"
                mb={actionItem?.comments.length ? 1 : 0}
                onAdd={(content) =>
                  onAddComment(actionItem?.id as string, content)
                }
              />
              {actionItem?.comments.map((comment) => (
                <Comment
                  key={comment.id}
                  id={comment.id}
                  authorName={comment.author?.name}
                  canDelete={comment.author?.id === currentUser?.id}
                  createdAt={comment.createdAt}
                  text={comment.text}
                  onDelete={() => onDeleteComment(comment.id)}
                  mt={3}
                  w="full"
                />
              ))}
            </Flex>
            <HStack w="full">
              <ClickConfirmation
                size="lg"
                isDisabled={isSubmitting}
                variant="outlineGray"
                type="button"
                w="fit-content"
                leftIcon={<Icon as={BiTrashAlt} />}
                onConfirm={onDelete}
                placement="top"
                confirmScale={1}
              >
                Delete
              </ClickConfirmation>
              <Spacer />
              <Button
                size="lg"
                isDisabled={isSubmitting}
                variant="outlineGray"
                type="button"
                w="fit-content"
                onClick={props.onClose}
              >
                Cancel
              </Button>
              <Button
                size="lg"
                w="fit-content"
                isLoading={isSubmitting}
                type="submit"
              >
                Save Action Item
              </Button>
            </HStack>
          </Flex>
        )}
      </ModalBody>
    </ModalContent>
  );
};

export const EditActionItemModal: FC<
  Omit<EditActionItemModalProps, 'actionItemId'> & {
    actionItemId: string | null;
  }
> = (props) => (
  <Modal {...props}>
    <ModalOverlay />
    {props.actionItemId && (
      <EditActionItemModalContent
        {...props}
        actionItemId={props.actionItemId}
      />
    )}
  </Modal>
);
