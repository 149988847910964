import { FC } from 'react';
import { TbReportAnalytics } from 'react-icons/tb';
import { ButtonProps, useDisclosure, Button, Icon } from '@spoke/common';
import { GenerateReportModal } from './GenerateReportModal';
import { useCurrentOrgLockdown } from '@spoke/user';

interface GenerateNewReportButtonProps extends ButtonProps {
  onReportGenerated?: () => void;
}

export const GenerateNewReportButton: FC<GenerateNewReportButtonProps> = ({ onReportGenerated, ...rest }) => {
  const generateReportModal = useDisclosure();
  const [isOrgLocked, isTrialExpired, isOrgFree] = useCurrentOrgLockdown();

  const handleReportGenerated = () => {
    generateReportModal.close();
    if (onReportGenerated) {
      onReportGenerated();
    }
  };

  return (
    <>
      <Button
        fontSize="15px"
        iconSpacing={1}
        leftIcon={<Icon as={TbReportAnalytics} color="white" w={4} h={4} />}
        onClick={generateReportModal.open}
        disabled={isOrgLocked || isTrialExpired || isOrgFree}
        {...rest}
      >
        Generate Report
      </Button>
      <GenerateReportModal
        isOpen={generateReportModal.isOpen}
        onClose={generateReportModal.close}
        onReportGenerated={handleReportGenerated}
      />
    </>
  );
};
