import { FC } from 'react';
import {
  useTeamMetricsFeatureState,
  useInstalledOrgIntegrations,
} from '../../queries';
import { IntegrationLinkSelectorEmptyState } from './IntegrationLinkSelectorEmptyState';
import { TeamBitbucketRepoLinkSelector } from './TeamBitbucketRepoLinkSelector';
import { TeamGithubRepoLinkSelectorEmptyState } from './TeamGithubRepoLinkEmptyState';
import { TeamGithubRepoLinkSelector } from './TeamGithubRepoLinkSelector';
import { TeamJiraBoardLinkEmptyState } from './TeamJiraBoardLinkEmptyState';
import { TeamJiraBoardLinkSelector } from './TeamJiraBoardLinkSelector';
import { TeamSettingsIntegrationsGridItem } from './TeamSettingsIntegrationsGridItem';
import {
  JiraIcon,
  GithubIcon,
  BitbucketIcon,
  Grid,
  SlackIcon,
  Text,
  Flex,
} from '@spoke/common';
import { TeamSlackLinkEmptyState } from './TeamSlackLinkEmptyState';

export const TeamSettingsIntegrationGrid: FC = () => {
  const states = useTeamMetricsFeatureState();
  const { github, jira, bitbucket, slack } = useInstalledOrgIntegrations();

  if (
    states.jiraMetrics.hidden &&
    states.githubMetrics.hidden &&
    states.bitbucketMetrics.hidden
  ) {
    return null;
  }

  return (
    <Flex flexDirection="column">
      <Text fontSize="lg" fontWeight="bold" my={2} color="gray.700">
        Metrics & Events
      </Text>

      <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={4} mb={4}>
        {!states.jiraMetrics.hidden && (
          <TeamSettingsIntegrationsGridItem
            empty={jira}
            title="Jira"
            icon={JiraIcon}
            description="Select which Jira boards are linked to this team."
            linkSelectorComponent={() => <TeamJiraBoardLinkSelector />}
            emptyStateComponent={() => <TeamJiraBoardLinkEmptyState />}
          />
        )}

        {!states.githubMetrics.hidden && (
          <TeamSettingsIntegrationsGridItem
            empty={github}
            title="GitHub"
            icon={GithubIcon}
            description="Select which GitHub repositories are linked to this team."
            linkSelectorComponent={() => <TeamGithubRepoLinkSelector />}
            emptyStateComponent={() => <TeamGithubRepoLinkSelectorEmptyState />}
          />
        )}

        {!states.bitbucketMetrics.hidden && (
          <TeamSettingsIntegrationsGridItem
            empty={bitbucket}
            title="BitBucket"
            icon={BitbucketIcon}
            description="Select which BitBucket repositories are linked to this team."
            linkSelectorComponent={() => <TeamBitbucketRepoLinkSelector />}
            emptyStateComponent={() => (
              <IntegrationLinkSelectorEmptyState
                title="BitBucket"
                icon={BitbucketIcon}
                description="Select which BitBucket repositories are linked to this team."
              />
            )}
          />
        )}
      </Grid>
    </Flex>
  );
};
