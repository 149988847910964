import { UserAvatarMenu } from './UserAvatarMenu';
import { LatestRetroShortcut, useCurrentTeamBoards } from '@spoke/board';
import {
  useCurrentUser,
  Flex,
  NAV_BAR_HEIGHT,
  SpkLink,
  Divider,
  Spacer,
  Link,
  Image,
  useAssets,
  useRoutes,
  SpkRouteKey,
  Text,
  SpkTime,
  DefaultPlanSlug,
  BoardType,
} from '@spoke/common';
import {
  useCurrentOrgLockdown,
  TeamBreadcrumbs,
  useCurrentOrg,
  useBillingDetails,
} from '@spoke/user';
import { PaymentStatus } from '@spoke/graphql';

export const DashboardNav = () => {
  const [currentUser] = useCurrentUser();
  const [org, { loading: orgLoading }] = useCurrentOrg();
  const [billing, { loading: billingLoading }] = useBillingDetails();
  const [{ teamBoards }] = useCurrentTeamBoards({ variables: { limit: 1 } });
  const [isOrgLocked] = useCurrentOrgLockdown();
  const { logoFull } = useAssets();
  const routes = useRoutes();

  const trialExpired = org?.status === PaymentStatus.TrialExpired ?? false;

  const latestBoard = teamBoards?.[0];

  return (
    <Flex
      alignItems="center"
      w="full"
      h={NAV_BAR_HEIGHT}
      px={6}
      bg="white"
      borderBottomWidth={1}
      borderColor="gray.100"
    >
      <SpkLink href={routes[SpkRouteKey.Root]} passHref>
        <Link>
          <Image src={logoFull} alt="ScatterSpoke Logo" minW={192} maxW={192} />
        </Link>
      </SpkLink>
      <Divider orientation="vertical" h="55%" mx={6} />
      <TeamBreadcrumbs />
      {Boolean(latestBoard && !isOrgLocked) && (
        <>
          <Divider orientation="vertical" h="55%" mx={6} />
          <LatestRetroShortcut
            boardId={latestBoard!.id}
            boardName={latestBoard!.name}
            boardUpdatedAt={
              latestBoard!.updatedAt
                ? new Date(latestBoard!.updatedAt).toISOString()
                : new Date().toISOString()
            }
            isAsyncFeedback={latestBoard?.boardAsyncScheduleId !== null}
          />
        </>
      )}
      {Boolean(
        (org?.plan?.slug === DefaultPlanSlug.UnlimitedTrial &&
          !billing?.canceled) ||
          trialExpired
      ) && (
        <Text fontSize="sm" color="gray.500" ml={4}>
          {trialExpired
            ? 'Trial Expired'
            : 'Trials ends in ' +
              SpkTime.formatDuration(
                Number(org?.trialEndsAt ?? 0) - Date.now(),
                'days'
              )}
        </Text>
      )}
      <Spacer />
      <UserAvatarMenu
        email={currentUser?.email || ''}
        name={currentUser?.name || ''}
      />
    </Flex>
  );
};
