import {
  Button,
  Divider,
  GridItemProps,
  VisuallyHidden,
} from '@chakra-ui/react';
import { ComponentProps, FC } from 'react';
import { MdKeyboardArrowRight, MdOutlineStickyNote2 } from 'react-icons/md';
import { LinkIcon, PlusCircle, History } from 'lucide-react';
import { ITEM_ICON } from '../impactReportUtil';
import { ImpactReportGoalsHookData } from '../queries';
import { SeverityBadge } from './SeverityBadge';
import {
  Badge,
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  InfoTooltip,
  Link,
  NO_OP,
  Text,
  Tooltip,
  VStack,
} from '@spoke/common';
import { TeamType } from '@spoke/graphql';

type Props = GridItemProps & {
  card: ImpactReportGoalsHookData['cards'][0];
  teamType: TeamType;
  showMonetaryValues: boolean;
  onIssueClick?: (issueId: string) => void;
  isLocked?: boolean;
  limit?: number;
  hideSummary?: boolean;
  hideGoalLink?: boolean;
};

export const ImpactReportSummaryCard: FC<Props> = ({
  card,
  teamType,
  showMonetaryValues,
  onIssueClick = NO_OP,
  isLocked,
  limit = 10,
  hideSummary = false,
  hideGoalLink = false,
  ...props
}) => {
  const items = card.items.slice(0, limit);

  const severityOptions: string[] = [
    'neutral',
    'bad_high',
    'bad_critical',
    'good_high',
    'good_critical',
  ];
  const itemsWithLinkedGoals = items.map((item) => ({
    ...item,
    linkedGoal:
      Math.random() > 0.5
        ? {
            id: `goal-${Math.random().toString(36).substr(2, 9)}`,
            title: `Goal: ${item.title.split(' ').slice(0, 3).join(' ')}...`,
          }
        : null,
    severity:
      severityOptions[Math.floor(Math.random() * severityOptions.length)],
  }));

  return (
    <GridItem padding={1} {...props}>
      <Flex flexDir="column">
        {isLocked ? (
          <Flex
            justifyContent="center"
            alignItems="center"
            height="100%"
            flexDir="column"
          >
            <Flex alignItems="center" mb={2}>
              <Icon
                as={MdOutlineStickyNote2}
                w={5}
                h={5}
                mr={2}
                color="gray.500"
              />
              <Text fontSize={14} color="gray.500">
                Feedback results are only available with an active subscription.
              </Text>
            </Flex>
            <Link href="/billing" color="primary.500">
              Manage your subscription
            </Link>
          </Flex>
        ) : (
          <>
            <VStack spacing={4} align="stretch">
              {itemsWithLinkedGoals.map((item) => (
                <Box
                  key={item.id}
                  border="1px solid"
                  borderColor="gray.200"
                  // p={4}
                  // mt={4}
                  borderRadius="8px"
                  overflow="hidden"
                >
                  <Flex p={4} alignItems="flex-start" gap={4}>
                    <VStack align="stretch" flex={1} spacing={1}>
                      <Flex alignItems="center" gap={2}>
                        <Text
                          fontSize={14}
                          fontWeight={500}
                          onClick={() => onIssueClick(item.id)}
                          cursor="pointer"
                          color="black"
                          _hover={{ textDecoration: 'underline' }}
                        >
                          {item.title} <Icon as={MdKeyboardArrowRight} />
                        </Text>
                      </Flex>
                      {!hideSummary && (
                        <Text fontSize="sm" color="gray.600">
                          {item.summary}
                        </Text>
                      )}
                    </VStack>
                    <Tooltip
                      label={`This issue has come up ${
                        teamType === TeamType.Team
                          ? item.occurrenceCount + ' times'
                          : 'on ' + item.teamCount + ' teams'
                      }`}
                      hasArrow
                      shouldWrapChildren
                      placement="top"
                      variant="white"
                    >
                      <HStack spacing={1} align="center">
                        <Icon as={History} w={4} h={4} />
                        <Text fontSize={12} color="gray.600">
                          {teamType === TeamType.Team
                            ? item.occurrenceCount
                            : item.teamCount}
                        </Text>
                      </HStack>
                    </Tooltip>
                    {/* {!hideGoalLink && (
                      item.linkedGoal ? (
                        <Tooltip
                          label="This issue is linked to a goal, click to view progress"
                          hasArrow
                          shouldWrapChildren
                          placement="top"
                          variant="white"
                        >
                          <Icon as={LinkIcon} w={4} h={4} />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          label="Create a goal for this issue"
                          hasArrow
                          shouldWrapChildren
                          placement="top"
                          variant="white"
                        >
                          <Icon as={PlusCircle} w={4} h={4} />
                        </Tooltip>
                      )
                    )} */}
                  </Flex>
                </Box>
              ))}
            </VStack>
          </>
        )}
      </Flex>
    </GridItem>
  );
};
