import { useState, useEffect, useMemo, useCallback } from 'react';
import { useRouter } from 'next/router';
import { FiAlertTriangle } from 'react-icons/fi';
import {
  useChangeBoardStageHandler,
  useGenerateBoardTakeaways,
  useGrantRevokeFacilitatorHandler,
} from '../../handlers';
import { ReopenRetroConfirmationModal } from '../ReopenRetroConfirmationModal';
import { useParticipations } from '../../queries';
import {
  useCurrentOrg,
  useCurrentOrgLockdown,
  useCurrentTeam,
  UpgradeCard,
} from '@spoke/user';
import {
  BoardStage,
  TakeawayStatus,
  TeamType,
  useImpactReportBoardSummaryQuery,
} from '@spoke/graphql';
import {
  useDisclosure,
  VStack,
  Flex,
  HStack,
  Icon,
  Heading,
  Image,
  useAssets,
  Link,
  Text,
  Box,
  useCurrentBoard,
  useRoutes,
  useToast,
  Button,
  Badge,
  Spinner,
  useCurrentUser,
} from '@spoke/common';
import {
  useDeleteActionItemHandler,
  useActionItems,
  EditActionItemModal,
  CreateActionItemModal,
} from '@spoke/action-item';
import {
  FeedbackTakeaways,
  FeedbackOverview,
  FeedbackIssues,
} from '@spoke/reports';
import { ImpactReportGoalsHookData } from '@spoke/impact-report';

const TRIAL_PLAN_SLUG = 'unlimited-trial';

export const RetroSummary = () => {
  const router = useRouter();
  const [currentTeam] = useCurrentTeam();
  const [currentUser] = useCurrentUser();
  const [board] = useCurrentBoard();
  const { illustrationTakeaways } = useAssets();
  const [handleGrantRevokeFacilitator] = useGrantRevokeFacilitatorHandler();
  const [handleChangeBoardStage] = useChangeBoardStageHandler();
  const [handleGenerateBoardTakeaways] = useGenerateBoardTakeaways();
  const [takeawayRequestSent, setTakeawayRequestSent] = useState(false);
  const reopenRetroConfirmModal = useDisclosure();
  const createActionItemModalDisclosure = useDisclosure();
  const [actionItems, actionItemsQuery] = useActionItems(
    {},
    { pollIfNeeded: false }
  );

  const stripViewSummaryAndChangeBoardStage = (stage: BoardStage) => {
    if ('viewSummary' in router.query) {
      // Remove the 'viewSummary' query parameter
      const { viewSummary, ...restQuery } = router.query;

      // Update the URL without the 'viewSummary' parameter
      router.replace(
        {
          pathname: router.pathname,
          query: restQuery,
        },
        undefined,
        { shallow: true }
      );
    }

    // Call handleChangeBoardStage with the appropriate stage
    handleChangeBoardStage(stage);
    handleGrantFacilitator(currentUser?.id ?? '');
  };

  const handleGrantFacilitator = useCallback(
    (userId: string) => {
      handleGrantRevokeFacilitator(userId, true);
    },
    [handleGrantRevokeFacilitator]
  );

  const mappedActionItems = useMemo(
    () =>
      actionItems.unresolved
        .filter((item) => item.boardId === board?.id)
        .map((item) => ({
          title: item.title ?? '',
          description: item.description ?? '',
          dueDate: item.dueDate ? new Date(item.dueDate) : new Date(),
          assignees:
            item.assignees?.map((assignee) => ({
              id: assignee?.id ?? '',
              name: assignee?.name ?? '',
            })) ?? [],
        })),
    [actionItems, board?.id]
  );

  const [isOrgLocked, isTrialExpired, isOrgFree] = useCurrentOrgLockdown();

  const [editingActionItemId, setEditingActionItemId] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (!board?.state?.takeAwayStatus && !takeawayRequestSent) {
      handleGenerateBoardTakeaways();
      setTakeawayRequestSent(true);
    }
  }, [
    board?.state?.takeAwayStatus,
    handleGenerateBoardTakeaways,
    takeawayRequestSent,
  ]);

  const getBoardTypeDescription = (): string =>
    board?.boardAsyncScheduleId ? 'feedback' : 'retrospective';

  const [shouldFetchSummary, setShouldFetchSummary] = useState(false);

  useEffect(() => {
    if (board?.id) {
      setShouldFetchSummary(true);
    }
  }, [board?.id]);

  const { data, loading, error, refetch } = useImpactReportBoardSummaryQuery({
    skip: !shouldFetchSummary,
    variables: {
      boardId: board?.id ?? '',
    },
    fetchPolicy: 'network-only',
  });

  const impactReportBoardSummary = data?.impactReportBoardSummary;
  const impactReportBoardSummaryLoading = loading;

  const cards: ImpactReportGoalsHookData['cards'][0][] = [
    {
      title: '',
      description: '', // Add appropriate description
      summary: '', // Add appropriate summary
      monetaryImpact: 0, // Add appropriate monetary impact
      badges: [], // Add appropriate badges
      items: impactReportBoardSummary?.issuesInFeedback ?? [],
    },
  ];

  const loadingMessages = [
    'Gathering your feedback...',
    'Analyzing your feedback...',
    'Preparing your summary...',
  ];
  const [currentMessageIndex, setCurrentMessageIndex] = useState<number>(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentMessageIndex(
        (prevIndex) => (prevIndex + 1) % loadingMessages.length
      );
    }, 3000);

    return () => clearInterval(intervalId);
  }, [loadingMessages.length]);

  const engagementScorePercent = Math.round(
    (impactReportBoardSummary?.engagementScore ?? 0) * 100
  );

  const showUpgradeCard = isOrgLocked || isTrialExpired || isOrgFree;

  return (
    <Flex
      direction="column"
      mx="auto"
      maxW={1000}
      p={4}
      spacing={12}
      justifyContent="center"
    >
      <VStack flex="1" align="center" w="full">
        {!board?.state?.takeAwayStatus ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            w="full"
            my={10}
          >
            <HStack>
              <Text fontSize={20} fontWeight={500}>
                {board?.type === 'ASYNC'
                  ? 'Feedback Summary'
                  : 'Retrospective Summary'}
              </Text>{' '}
              <Badge
                pt="1px"
                fontSize={11}
                ml="1px"
                mb="1px"
                bg="primary.400"
                color="white"
                borderRadius="sm"
              >
                NEW
              </Badge>
            </HStack>

            <Text fontSize={16} fontWeight={500} color="gray.500" mb={5}>
              Transform your retrospective into shareable, actionable takeaways
              for your team and management.
            </Text>

            <Image
              src={illustrationTakeaways}
              alt="No action items yet"
              mt={8}
              mb={2}
            />

            <Button size="lg" onClick={handleGenerateBoardTakeaways}>
              Create Summary
            </Button>
          </Flex>
        ) : board?.state?.takeAwayStatus === TakeawayStatus.Pending ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            w="full"
            my={10}
          >
            <Spinner speed="2s" size="md" />

            <Text fontSize={16} fontWeight={500} mt={5}>
              {loadingMessages[currentMessageIndex]}
            </Text>
            <Text fontSize={12} fontWeight={500} color="gray.500">
              This usually takes a few seconds.
            </Text>
          </Flex>
        ) : board?.state?.takeAwayStatus === TakeawayStatus.Error ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            w="full"
            my={10}
          >
            <Icon w={7} h={7} as={FiAlertTriangle} color="primary.500" />

            <Text fontSize={16} fontWeight={500} mt={5}>
              Our system is overloaded at this moment.
            </Text>
            <Text fontSize={12} fontWeight={500} color="gray.500">
              We will try again in a few moments and let you know once finished.
            </Text>
          </Flex>
        ) : (
          board?.state?.takeAwayStatus === TakeawayStatus.Done && (
            <>
              {showUpgradeCard ? (
                <Box>
                  <Link
                    onClick={reopenRetroConfirmModal.open}
                    fontSize={14}
                    fontWeight={500}
                    textDecoration="underline"
                    color="gray.500"
                  >
                    View {getBoardTypeDescription()}
                  </Link>
                  <UpgradeCard
                    title={`${board?.name} Summary`}
                    description="Upgrade to get a detailed summary of your feedback!"
                    features={[
                      'Team engagement: Measure and improve participation across your team',
                      'Discussion summary: Get a concise overview of key points and decisions made',
                      'Issue identification: Quickly spot and address potential problems or challenges',
                      'Sharable takeaways: Easily distribute actionable insights to team members and leadership',
                    ]}
                  />
                </Box>
              ) : (
                <Box
                  bg="white"
                  w="full"
                  p={8}
                  my={8}
                  style={{ marginTop: 0 }}
                  shadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                >
                  <HStack justifyContent="space-between">
                    <Heading fontWeight={600} fontSize={24} mb={4}>
                      {board?.name}
                    </Heading>
                    <Link
                      onClick={reopenRetroConfirmModal.open}
                      fontSize={14}
                      fontWeight={500}
                      textDecoration="underline"
                      color="gray.500"
                    >
                      View {getBoardTypeDescription()}
                    </Link>
                  </HStack>
                  <FeedbackOverview
                    engagementScorePercent={engagementScorePercent}
                    summary={impactReportBoardSummary?.summary ?? ''}
                    positiveCount={impactReportBoardSummary?.positiveCount ?? 0}
                    neutralCount={impactReportBoardSummary?.neutralCount ?? 0}
                    negativeCount={impactReportBoardSummary?.negativeCount ?? 0}
                    actionItems={mappedActionItems}
                  />
                  <FeedbackIssues
                    cards={cards}
                    currentTeam={{
                      id: currentTeam?.id ?? '',
                      type: currentTeam?.type ?? TeamType.Team,
                    }}
                  />
                  <FeedbackTakeaways takeaways={board?.takeaways ?? []} />
                </Box>
              )}
            </>
          )
        )}
      </VStack>
      <EditActionItemModal
        actionItemId={editingActionItemId}
        isOpen={Boolean(editingActionItemId)}
        onClose={() => setEditingActionItemId(null)}
      />
      <CreateActionItemModal
        isOpen={createActionItemModalDisclosure.isOpen}
        onClose={createActionItemModalDisclosure.close}
      />
      <ReopenRetroConfirmationModal
        isOpen={reopenRetroConfirmModal.isOpen}
        onClose={reopenRetroConfirmModal.close}
        onConfirm={() =>
          stripViewSummaryAndChangeBoardStage(
            board?.boardAsyncScheduleId
              ? BoardStage.Reflect
              : BoardStage.Discuss
          )
        }
        // board={board}
      />
    </Flex>
  );
};
