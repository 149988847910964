import { IconProps, Icon } from '../Icon';

export const TeamIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <rect width="24" height="24" fill="#F3F1FB" rx="8" />
    <path
      fill="currentColor"
      d="M12 12.5c1.087 0 2.047.26 2.827.6A1.99 1.99 0 0116 14.92v.413c0 .367-.3.667-.667.667H8.667A.669.669 0 018 15.333v-.406c0-.787.453-1.507 1.173-1.82A6.96 6.96 0 0112 12.5zm-5.333.167c.733 0 1.333-.6 1.333-1.334C8 10.6 7.4 10 6.667 10c-.734 0-1.334.6-1.334 1.333 0 .734.6 1.334 1.334 1.334zm.753.733a4.655 4.655 0 00-.753-.067c-.66 0-1.287.14-1.854.387A1.34 1.34 0 004 14.953v.38c0 .367.3.667.667.667H7v-1.073c0-.554.153-1.074.42-1.527zm9.913-.733c.734 0 1.334-.6 1.334-1.334 0-.733-.6-1.333-1.334-1.333C16.6 10 16 10.6 16 11.333c0 .734.6 1.334 1.333 1.334zM20 14.953c0-.54-.32-1.02-.813-1.233a4.634 4.634 0 00-1.854-.387c-.26 0-.506.027-.753.067.267.453.42.973.42 1.527V16h2.333c.367 0 .667-.3.667-.667v-.38zM12 8c1.107 0 2 .893 2 2s-.893 2-2 2-2-.893-2-2 .893-2 2-2z"
    />
  </Icon>
);
