import { useCallback } from 'react';
import {
  StackProps,
  VStack,
  Text,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Square,
} from '@chakra-ui/react';
import { BsThreeDots } from 'react-icons/bs';
import { MdDateRange, MdOutlineModeEditOutline } from 'react-icons/md';
import { BiTrashAlt } from 'react-icons/bi';
import { MdAutoMode } from 'react-icons/md';
import { HStack, SpkTime, Tooltip } from '@spoke/common';
import { FaSyncAlt } from 'react-icons/fa';
import { VscFeedback } from 'react-icons/vsc';
import { BoardAsyncSchedule, BoardAsyncScheduleType } from '@spoke/graphql';
import { datetime, RRule, RRuleSet, rrulestr } from 'rrule';
import { FiAlertOctagon } from 'react-icons/fi';

const NOON_IN_MS = 43200000;

type AsyncFeedbackConfigListProps = StackProps & {
  asyncFeedbackConfigs: BoardAsyncSchedule[];
  onConfigClick: (config: BoardAsyncSchedule) => void;
  onDeleteConfig?: (configId: string) => void;
  onTriggerClick?: (configId: string) => void;
  disableActions?: boolean;
  maxHeight?: string; // New prop for max height
};

export const getRRuleNextRunTime = (rruleString: string): string | null => {
  try {
    const rrule = RRule.fromString(rruleString);
    const nextRunTime = rrule.after(new Date());
    nextRunTime?.setSeconds(0);
    nextRunTime?.setMilliseconds(0);

    return nextRunTime
      ? nextRunTime.toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })
      : null;
  } catch (error) {
    return null;
  }
};

export const AsyncFeedbackConfigList = ({
  asyncFeedbackConfigs,
  onConfigClick,
  onDeleteConfig,
  onTriggerClick,
  disableActions = false,
  maxHeight = '200px', // Default value for max height
  ...rest
}: AsyncFeedbackConfigListProps) => {
  const handleConfigClick = useCallback(
    (config: BoardAsyncSchedule) => onConfigClick(config),
    [onConfigClick]
  );

  const getNextRunTime = (config: BoardAsyncSchedule): string | null => {
    try {
      const rrule = RRule.fromString(config.rrule || '');
      const nextRunTime = rrule.after(new Date());
      nextRunTime?.setSeconds(0);
      nextRunTime?.setMilliseconds(0);

      return nextRunTime
        ? nextRunTime.toLocaleDateString() +
            ' at ' +
            getTimeString(config.collectionTime)
        : null;
    } catch (error) {
      return null;
    }
  };

  const getRRuleText = (rruleString: string): string => {
    const rrule = RRule.fromString(rruleString);
    const nextRunTime = rrule.after(new Date());
    nextRunTime?.setSeconds(0);
    nextRunTime?.setMilliseconds(0);

    const rruleText = rrule.toText();
    const hour = nextRunTime?.getHours();
    const minute = nextRunTime?.getMinutes().toString().padStart(2, '0');
    return (
      rruleText.charAt(0).toUpperCase() +
      rruleText.slice(1) +
      ` at ${hour}:${minute} ${hour && hour >= 12 ? 'PM' : 'AM'}`
    );
  };

  const getTimeString = (timeMs: number) => {
    const localTimeMs = SpkTime.utcTimeMsToLocalTimeMs(timeMs);
    const amPm = localTimeMs > NOON_IN_MS ? 'PM' : 'AM';
    return SpkTime.getStrFromMsValue(timeMs) + ' ' + amPm;
  };

  const isOneTimeScheduleInPast = (config: BoardAsyncSchedule): boolean => {
    if (config.scheduleType === BoardAsyncScheduleType.Recurring) {
      return false;
    }
    if (config.oneTimeSchedule) {
      const localCollectionTimeMs = SpkTime.utcTimeMsToLocalTimeMs(
        config.collectionTime
      );
      const oneTimeScheduleDate = new Date(
        config.oneTimeSchedule -
          SpkTime.getUtcOffsetMs() +
          localCollectionTimeMs
      );
      return oneTimeScheduleDate < new Date();
    }
    return false;
  };

  return (
    <VStack
      w="full"
      alignItems="stretch"
      mt={4}
      spacing={3}
      overflowY="auto"
      maxHeight={maxHeight}
      pb={asyncFeedbackConfigs.length ? 4 : 0}
      {...rest}
    >
      {asyncFeedbackConfigs?.map(
        (config) =>
          config && (
            <Box
              key={config.id}
              p={4}
              borderWidth="1px"
              borderRadius="md"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <VStack alignItems="stretch">
                <Text
                  color="gray.700"
                  fontWeight={600}
                  onClick={() => !disableActions && handleConfigClick(config)}
                  cursor={disableActions ? 'default' : 'pointer'}
                  _hover={{
                    textDecoration: disableActions ? 'none' : 'underline',
                  }}
                >
                  {config.name}
                </Text>

                <HStack spacing={4}>
                  {isOneTimeScheduleInPast(config) && (
                    <HStack spacing={1} color={'red.500'}>
                      <Tooltip
                        label={
                          'Edit or or archive this feedback schedule because it already happened'
                        }
                        shouldWrapChildren
                        placement="top"
                        variant="white"
                      >
                        <Icon as={FiAlertOctagon} />
                      </Tooltip>
                    </HStack>
                  )}
                  <HStack spacing={1} color={'gray.500'}>
                    <Icon as={VscFeedback} />
                    <Tooltip
                      label={'Feedback Channel'}
                      shouldWrapChildren
                      placement="top"
                      variant="white"
                    >
                      <Text
                        cursor="default"
                        fontWeight={500}
                        pt="3px"
                        fontSize={14}
                      >
                        {config.feedbackCollectionType.charAt(0).toUpperCase() +
                          config.feedbackCollectionType.slice(1).toLowerCase()}
                      </Text>
                    </Tooltip>
                  </HStack>

                  <HStack spacing={1}>
                    <Icon as={MdDateRange} color="gray.500" />
                    <Tooltip
                      label={'When the next feedback collection will happen'}
                      shouldWrapChildren
                      placement="top"
                      variant="white"
                    >
                      <Text
                        cursor="default"
                        fontWeight={500}
                        pt="3px"
                        fontSize={14}
                        color="gray.500"
                      >
                        {config.scheduleType ===
                        BoardAsyncScheduleType.Recurring
                          ? getNextRunTime(config)
                          : config.oneTimeSchedule
                          ? new Date(
                              config.oneTimeSchedule - SpkTime.getUtcOffsetMs()
                            ).toLocaleDateString() +
                            ' at ' +
                            getTimeString(config.collectionTime)
                          : ''}
                      </Text>
                    </Tooltip>
                  </HStack>
                  {config.scheduleType === BoardAsyncScheduleType.Recurring && (
                    <HStack spacing={1} color={'gray.500'}>
                      <Tooltip
                        label={getRRuleText(config.rrule || '')}
                        shouldWrapChildren
                        placement="top"
                        variant="white"
                      >
                        <Icon as={FaSyncAlt} w={3} h={3} />
                      </Tooltip>
                      <Tooltip
                        label={getRRuleText(config.rrule || '')}
                        shouldWrapChildren
                        placement="top"
                        variant="white"
                      >
                        <Text
                          cursor="default"
                          fontWeight={500}
                          pt="3px"
                          fontSize={14}
                        >
                          Recurring
                        </Text>
                      </Tooltip>
                    </HStack>
                  )}
                </HStack>
              </VStack>
              {!disableActions && (
                <Menu>
                  <MenuButton>
                    <Square
                      borderRadius="lg"
                      boxShadow="extralight"
                      layerStyle="outlineGray"
                      p={2}
                    >
                      <Icon color="gray.600" as={BsThreeDots} w={5} h={5} />
                    </Square>
                  </MenuButton>
                  <MenuList minWidth="fit-content">
                    <MenuItem
                      alignItems="center"
                      gap={2}
                      onClick={() => handleConfigClick(config)}
                    >
                      <Icon color="gray.600" as={MdOutlineModeEditOutline} />
                      <Text>Edit</Text>
                    </MenuItem>
                    {onTriggerClick && (
                      <MenuItem
                        alignItems="center"
                        gap={2}
                        onClick={() => onTriggerClick(config?.id)}
                      >
                        <Icon color="gray.600" as={MdAutoMode} />
                        <Text>Trigger</Text>
                      </MenuItem>
                    )}

                    {onDeleteConfig && (
                      <MenuItem
                        alignItems="center"
                        gap={2}
                        onClick={() => onDeleteConfig(config.id)}
                      >
                        <Icon color="gray.600" as={BiTrashAlt} />
                        <Text>Delete</Text>
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              )}
            </Box>
          )
      )}
    </VStack>
  );
};
