import { FC, useState } from 'react';
import { BiTrashAlt } from 'react-icons/bi';
import {
  DropzoneFileFormats,
  parseCsv,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  HStack,
  Heading,
  FileDropzone,
  Flex,
  Button,
  Icon,
  Link,
  Text,
} from '@spoke/common';
import { useTeamType } from '@spoke/user';

const SAMPLE_CSV_DOWNLOAD_LINK =
  'https://d2ewr1kiai6ffs.cloudfront.net/scatterspoke-example-retrospective-import-format.csv';

const ACCEPTED_FILE_FORMATS: DropzoneFileFormats[] = ['csv'];

type ImportBoardWizardUploadProps = {
  onClose: () => void;
  onSubmit: (data: Record<string, string>[]) => void;
};
export const ImportBoardWizardUpload: FC<ImportBoardWizardUploadProps> = ({
  onClose,
  onSubmit,
}) => {
  const { capitalizedTeamType } = useTeamType();

  const [file, setFile] = useState<File | null>(null);

  const onFileSubmit = async () => {
    if (!file) return;
    const csvString = await file.text();
    const csvParsed = await parseCsv(csvString);
    onSubmit(csvParsed);
  };

  return (
    <ModalContent p={8} pb={4} maxW={600}>
      <ModalCloseButton />
      <ModalBody p={0}>
        <ModalHeader p={0}>
          <HStack>
            <Heading mb={1} fontSize={24} color="gray.900">
              Import {capitalizedTeamType} Feedback
            </Heading>
          </HStack>
          <Text fontSize={16} fontWeight={400} color="gray.700" mt={1}>
            Import a past team feedback by uploading a CSV file. Here is a{' '}
            <Link
              textDecor="underline"
              target="_blank"
              href={SAMPLE_CSV_DOWNLOAD_LINK}
            >
              sample.
            </Link>
          </Text>
        </ModalHeader>
        <HStack spacing={2} my={4}>
          {!file && (
            <FileDropzone
              acceptFiles={ACCEPTED_FILE_FORMATS}
              onUpload={setFile}
              label="Drag and drop your CSV file here or"
              manualUploadLabel="select a CSV to upload"
            />
          )}
          {file && (
            <Flex
              w="full"
              px={4}
              layerStyle="outlineGray"
              gap={2}
              alignItems="center"
            >
              <Text flex="1" fontSize={16}>
                {file.name}
              </Text>
              <Button
                onClick={() => setFile(null)}
                variant="link"
                leftIcon={<Icon mr="-4px" as={BiTrashAlt} />}
              >
                Remove
              </Button>
            </Flex>
          )}
        </HStack>
        <HStack mb={1} justifyContent="flex-end">
          <Button
            size="lg"
            variant="outlineGray"
            type="button"
            w="fit-content"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size="lg"
            w="fit-content"
            type="submit"
            onClick={onFileSubmit}
            isDisabled={!file}
          >
            Next
          </Button>
        </HStack>
      </ModalBody>
    </ModalContent>
  );
};
