import { FC, useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useCurrentUser } from '../../hooks';

export const VisitorIdentification: FC = ({ children }) => {
  const [currentUser] = useCurrentUser();
  useEffect(() => {
    const id = currentUser?.id;
    const username = currentUser?.name || '';
    const email = currentUser?.email || '';
    const verified = currentUser?.verified;
    const user = verified
      ? { id, username, email, verified }
      : null;

    Sentry.setUser(user);
  }, [
    currentUser?.email,
    currentUser?.id,
    currentUser?.name,
    currentUser?.verified,
  ]);

  return <>{children}</>;
};
