import { FC, useState, useMemo } from 'react';
import { Button, Icon, Spacer } from '@chakra-ui/react';
import { FiCalendar } from 'react-icons/fi';
import { BsChevronDown } from 'react-icons/bs';
import { format } from 'date-fns';
import { DateRange } from 'react-day-picker';
import {
  TeamsIcon,
  MultiSelect,
  truncateWithCount,
  useDisclosure,
  useCurrentUser,
  DateRangePickerPopover,
} from '@spoke/common';
import { useCurrentTeam, useTeamTree, useTeamType } from '@spoke/user';
import { MinimalTeamFragmentFragment, TeamType } from '@spoke/graphql';

interface AnalyticsRollupFilterBarProps {
  initialDateRange?: DateRange;
  onlyDateRange?: boolean;
  onProgramsChange: (selectedPrograms: string[]) => void;
  onTeamsChange: (selectedTeams: string[]) => void;
  onDateRangeChange: (range: DateRange | undefined) => void;
  isDisabled?: boolean;
}

export const AnalyticsRollupFilterBar: FC<AnalyticsRollupFilterBarProps> = ({
  initialDateRange,
  onlyDateRange,
  onProgramsChange,
  onTeamsChange,
  onDateRangeChange,
  isDisabled,
}) => {
  const [{ teamTree }] = useTeamTree();
  const { isProgram, isOrg } = useTeamType();
  const [currentTeam] = useCurrentTeam();
  const [currentUser] = useCurrentUser();
  const teamsDropdown = useDisclosure();
  const programsDropdown = useDisclosure();
  const [selectedChildrenTeams, setSelectedChildrenTeams] = useState<
    MinimalTeamFragmentFragment[]
  >([]);
  const [selectedChildrenPrograms, setSelectedChildrenPrograms] = useState<
    MinimalTeamFragmentFragment[]
  >([]);
  const [range, setRange] = useState<DateRange | undefined>(initialDateRange);

  const { programOptions, teamOptions } = useMemo(() => {
    const filterTeamNodes = (nodeType: TeamType) =>
      teamTree
        ?.filter((treeNode) => treeNode.team.type === nodeType)
        .map((treeNode) => treeNode.team) ?? [];

    const filteredProgramOptions = filterTeamNodes(TeamType.Program);

    const filteredTeamOptions = isProgram
      ? currentTeam?.children ?? []
      : filterTeamNodes(TeamType.Team);

    return {
      programOptions: filteredProgramOptions,
      teamOptions: filteredTeamOptions,
    };
  }, [teamTree, isProgram, currentTeam]);

  const isAdminUserForProgram =
    isProgram && currentTeam?.admin?.id === currentUser?.id;

  const teamsLabel =
    truncateWithCount(selectedChildrenTeams, 'name', 55) || 'All teams';
  const programsLabel =
    truncateWithCount(selectedChildrenPrograms, 'name', 55) || 'All programs';
  const periodLabel =
    range?.from && range?.to
      ? `${format(range.from, 'MMM dd, yyyy')} - ${format(
          range.to,
          'MMM dd, yyyy'
        )}`
      : 'Period';

  const getFilteredOptions = (
    options: MinimalTeamFragmentFragment[],
    term: string
  ): MinimalTeamFragmentFragment[] =>
    options.filter((option) =>
      option.name?.toLowerCase().includes(term.toLowerCase())
    );

  const handleSelect = (
    items: MinimalTeamFragmentFragment[],
    setter: React.Dispatch<React.SetStateAction<MinimalTeamFragmentFragment[]>>,
    onChange: (ids: string[]) => void
  ) => {
    setter(items);
    onChange(items.map((item) => item.id));
  };

  const handleDateRangeChange = (newRange: DateRange | undefined) => {
    setRange(newRange);
    onDateRangeChange(newRange);
  };

  return (
    <>
      <DateRangePickerPopover value={range} onChange={handleDateRangeChange}>
        <Button
          variant="outlineGray"
          leftIcon={<Icon as={FiCalendar} />}
          isDisabled={isDisabled}
        >
          {periodLabel}
        </Button>
      </DateRangePickerPopover>

      {!onlyDateRange && (isOrg || isProgram) && (
        <>
          {isOrg && (
            <MultiSelect
              searchable
              values={selectedChildrenPrograms}
              onChange={(programs) =>
                handleSelect(
                  programs,
                  setSelectedChildrenPrograms,
                  onProgramsChange
                )
              }
              onClose={programsDropdown.close}
              isOpen={programsDropdown.isOpen}
              getOptions={(term) => getFilteredOptions(programOptions, term)}
              idKey="id"
              labelKeyOrFn="name"
            >
              <Button
                justifyContent="start"
                onClick={programsDropdown.toggle}
                variant="outlineGray"
                leftIcon={<Icon as={TeamsIcon} />}
                w="full"
                isDisabled={isDisabled || selectedChildrenTeams.length > 0}
              >
                {programsLabel}
                <Spacer ml={1} />
                <Icon as={BsChevronDown} />
              </Button>
            </MultiSelect>
          )}
          <MultiSelect
            searchable
            values={selectedChildrenTeams}
            onChange={(teams) =>
              handleSelect(teams, setSelectedChildrenTeams, onTeamsChange)
            }
            onClose={teamsDropdown.close}
            isOpen={teamsDropdown.isOpen}
            getOptions={(term) => getFilteredOptions(teamOptions, term)}
            idKey="id"
            labelKeyOrFn="name"
          >
            <Button
              justifyContent="start"
              onClick={teamsDropdown.toggle}
              variant="outlineGray"
              leftIcon={<Icon as={TeamsIcon} />}
              w="full"
              isDisabled={isDisabled || selectedChildrenPrograms.length > 0}
            >
              {teamsLabel}
              <Spacer ml={1} />
              <Icon as={BsChevronDown} />
            </Button>
          </MultiSelect>
        </>
      )}
    </>
  );
};
