import { deepMerge, DeepPartial, QueryConfig } from '@spoke/common';
import { useImpactReportIssueDrilldownQuery } from '@spoke/graphql';

type Config = QueryConfig<typeof useImpactReportIssueDrilldownQuery>;
type QueryRef = ReturnType<typeof useImpactReportIssueDrilldownQuery>;
export type ImpactReportIssueDrilldownGoalsHookData = NonNullable<
  NonNullable<QueryRef['data']>['impactReportIssueDrilldown']
>;

export const useImpactReportIssueDrilldown = (
  config?: DeepPartial<Config>
): [ImpactReportIssueDrilldownGoalsHookData | null, QueryRef] => {
  const issueId = config?.variables?.issueId;
  const teamId = config?.variables?.teamId;

  const baseConfig: DeepPartial<Config> = {
    variables: {
      issueId: config?.variables?.issueId ?? '',
      teamId: config?.variables?.teamId ?? '',
    },
    skip: !issueId || !teamId,
  };

  const finalConfig = (
    config ? deepMerge(baseConfig, config) : baseConfig
  ) as Config;

  const impactReportIssueDrilldownQuery =
    useImpactReportIssueDrilldownQuery(finalConfig);

  const data: ImpactReportIssueDrilldownGoalsHookData | null =
    impactReportIssueDrilldownQuery.data?.impactReportIssueDrilldown ??
    impactReportIssueDrilldownQuery.previousData?.impactReportIssueDrilldown ??
    null;

  return [data, impactReportIssueDrilldownQuery];
};
