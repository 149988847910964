import { FC, useState } from 'react';
import {
  ModalProps,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  HStack,
  Heading,
  ModalCloseButton,
  ModalBody,
  Text,
  Button,
  ModalFooter,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SpokeFormRules,
  Select,
  MultiSelect,
  useDisclosure,
  TeamsIcon,
  Spacer,
  truncateWithCount,
  useToast,
  DateRangePickerPopover,
} from '@spoke/common';
import { useForm, Controller } from 'react-hook-form';
import { AnalyticsRollupFilterBar } from '@spoke/improvement-goals';
import { TbReportAnalytics } from 'react-icons/tb';
import { useCurrentTeam, useTeamTree, useTeamType } from '@spoke/user';
import {
  MinimalTeamFragmentFragment,
  TeamType,
  useGenerateReportMutation,
  ReportType,
} from '@spoke/graphql';
import { BsChevronDown } from 'react-icons/bs';
import { FiCalendar } from 'react-icons/fi';
import { format } from 'date-fns';

interface GenerateReportModalProps extends Omit<ModalProps, 'children'> {
  children?: React.ReactNode;
  onReportGenerated?: () => void;
}

type GenerateReportFormSchema = {
  name: string;
  feedbackCollectionType: string;
  dateRange: {
    from: Date;
    to: Date;
  };
};

const formRules: SpokeFormRules<GenerateReportFormSchema> = {
  name: {
    required: 'Name is required',
    minLength: { value: 3, message: 'Name must be at least 3 characters long' },
  },
  feedbackCollectionType: {
    required: 'Feedback type is required',
  },
  dateRange: {
    required: 'Date range is required',
  },
};

export const GenerateReportModal: FC<GenerateReportModalProps> = (props) => {
  const { isOpen, onClose, onReportGenerated } = props;
  const [generateReport, { loading }] = useGenerateReportMutation();
  const [toast] = useToast();
  const [currentTeam] = useCurrentTeam();
  const teamType = useTeamType();
  const [{ teamTree }] = useTeamTree();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<GenerateReportFormSchema>({
    defaultValues: {
      name: '',
      feedbackCollectionType: 'ALL',
      dateRange: {
        from: new Date(new Date().setMonth(new Date().getMonth() - 3)),
        to: new Date(),
      },
    },
  });

  const teamsDropdown = useDisclosure();

  const [selectedChildrenTeams, setSelectedChildrenTeams] = useState<
    { id: string; name: string }[]
  >([]);

  const teams =
    currentTeam?.type === TeamType.Organization
      ? teamTree
          ?.filter((treeNode) => treeNode.team.type === TeamType.Team)
          .map((treeNode) => ({
            id: treeNode.team.id,
            name: treeNode.team.name,
          }))
      : currentTeam?.type === TeamType.Program
      ? currentTeam?.children.map((child) => ({
          id: child.id,
          name: child.name,
        })) ?? []
      : [];

  const getTeamOptions = (term: string): { id: string; name: string }[] =>
    teams
      ?.filter(Boolean)
      .filter((team) => team.name.toLowerCase().includes(term.toLowerCase())) ??
    [];

  const teamsLabel =
    truncateWithCount(selectedChildrenTeams, 'name', 35) || 'Select teams';

  const handleGenerateReport = async (data: GenerateReportFormSchema) => {
    try {
      const result = await generateReport({
        variables: {
          input: {
            type: ReportType.TeamDigest,
            teamId: currentTeam?.id ?? '',
            inputData: {
              name: data.name,
              startDate: data.dateRange.from,
              endDate: data.dateRange.to,
              teamIds: selectedChildrenTeams.length
                ? selectedChildrenTeams.map((team) => team.id)
                : [currentTeam?.id ?? ''],
              ...(data.feedbackCollectionType !== 'ALL' && {
                feedbackType: data.feedbackCollectionType,
              }),
            },
          },
        },
      });

      if (result.data?.generateReport) {
        toast({
          description: `We'll notify you via email when "${data.name}" is ready.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        onClose();
        if (onReportGenerated) {
          onReportGenerated();
        }
      }
    } catch (error) {
      toast({
        description:
          'Error generating report. If the error persists, please contact support.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent p={6} maxW={600}>
        <ModalHeader p={0}>
          <HStack>
            <Heading mb={1} fontSize={24} color="gray.900">
              Generate Team Report
            </Heading>
          </HStack>
        </ModalHeader>
        <ModalCloseButton mt={2} />
        <form onSubmit={handleSubmit(handleGenerateReport)}>
          <ModalBody p={0} mt={3}>
            <VStack
              spacing={4}
              justifyContent="space-between"
              alignItems="stretch"
            >
              <Text fontSize="15" color="gray.500">
                Choose from different types of feedback and different time
                ranges to generate a report.
              </Text>
              <FormControl isInvalid={Boolean(errors.name)}>
                <FormLabel fontSize={15} htmlFor="name">
                  Name
                </FormLabel>
                <Input
                  id="name"
                  placeholder="Team digest name.."
                  minH={10}
                  alignItems="center"
                  autoFocus
                  {...register('name', formRules.name)}
                />
                <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
              </FormControl>
              <HStack spacing={4}>
                <FormControl isInvalid={Boolean(errors.dateRange)}>
                  <FormLabel htmlFor="feedbackSource">Date Range</FormLabel>
                  <Controller
                    name="dateRange"
                    control={control}
                    rules={formRules.dateRange}
                    render={({ field }) => (
                      <DateRangePickerPopover
                        value={field.value}
                        onChange={(newDate) => {
                          field.onChange(newDate);
                        }}
                      >
                        <Button variant="outlineGray" leftIcon={<FiCalendar />}>
                          {field.value.from && field.value.to
                            ? `${format(
                                field.value.from,
                                'MMM dd, yyyy'
                              )} - ${format(field.value.to, 'MMM dd, yyyy')}`
                            : 'Select date range'}
                        </Button>
                      </DateRangePickerPopover>
                    )}
                  />
                  <FormErrorMessage>
                    {errors?.dateRange?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.feedbackCollectionType)}>
                  <FormLabel htmlFor="feedbackSource">
                    Type of Feedback
                  </FormLabel>
                  <Select
                    id="feedbackSource"
                    defaultValue="ALL"
                    {...register(
                      'feedbackCollectionType',
                      formRules.feedbackCollectionType
                    )}
                  >
                    <option value="ALL">All</option>
                    <option value="CHECK_IN">Check-in</option>
                    <option value="STANDUP">Standup</option>
                    <option value="RELEASE">Release</option>
                    <option value="RETROSPECTIVE">Retrospective</option>
                    <option value="POST_MORTEM">Post Mortem</option>
                    <option value="SURVEY">Survey</option>
                  </Select>
                  <FormErrorMessage>
                    {errors?.feedbackCollectionType?.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>
              {!teamType.isTeam && (
                <FormControl>
                  <FormLabel htmlFor="teams">Teams to include</FormLabel>
                  <MultiSelect
                    searchable
                    values={selectedChildrenTeams}
                    onChange={setSelectedChildrenTeams}
                    onClose={teamsDropdown.close}
                    isOpen={teamsDropdown.isOpen}
                    getOptions={getTeamOptions}
                    idKey="id"
                    labelKeyOrFn="name"
                  >
                    <Button
                      justifyContent="start"
                      onClick={teamsDropdown.toggle}
                      variant="outlineGray"
                      leftIcon={<TeamsIcon />}
                      w="full"
                    >
                      {teamsLabel}
                      <Spacer />
                      <BsChevronDown />
                    </Button>
                  </MultiSelect>
                </FormControl>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack spacing={4}>
              <Button
                type="submit"
                leftIcon={<TbReportAnalytics />}
                isLoading={loading}
              >
                Generate
              </Button>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
