import { FC, ReactElement, useEffect, useState } from 'react';
import {
  FaRegFaceFrownOpen,
  FaRegFaceGrin,
  FaRegFaceMeh,
} from 'react-icons/fa6';
import {
  Box,
  Circle,
  Divider,
  Flex,
  Icon,
  Spacer,
  SpkTime,
  Text,
} from '@spoke/common';
import { ImpactReportQueryResult } from '@spoke/graphql';

type Props = {
  data:
    | NonNullable<
        ImpactReportQueryResult['data']
      >['impactReport']['sentimentOverTime']['data'][0]
    | null;
};

const colors = {
  positive: { bgDimmed: '#12B8861A', bg: '#12B886', fg: '#009467' },
  neutral: { bgDimmed: '#FFCC3E1A', bg: '#FFCC3E', fg: '#723E01' },
  negative: { bgDimmed: '#FA52521A', bg: '#FA5252', fg: '#D81616' },
} as const;

const ICON: Record<'positive' | 'negative' | 'neutral', ReactElement> = {
  positive: (
    <Box p={2} borderRadius={5} bg={colors.positive.bgDimmed}>
      <Icon w={4} h={4} color={colors.positive.fg} as={FaRegFaceGrin} />
    </Box>
  ),
  negative: (
    <Box p={2} borderRadius={5} bg={colors.negative.bgDimmed}>
      <Icon w={4} h={4} color={colors.negative.fg} as={FaRegFaceFrownOpen} />
    </Box>
  ),
  neutral: (
    <Box p={2} borderRadius={5} bg={colors.neutral.bgDimmed}>
      <Icon w={4} h={4} color={colors.negative.fg} as={FaRegFaceMeh} />
    </Box>
  ),
};

export const SentimentOverTimeChartTooltip: FC<Props> = ({ data }) => {
  const [barsInitialized, setBarsInitialized] = useState(false);

  useEffect(() => {
    if (!data) return;
    const timeout = setTimeout(() => {
      setBarsInitialized(true);
    }, 10);
    return () => {
      clearTimeout(timeout);
    };
  }, [data]);

  if (!data) {
    if (barsInitialized) setBarsInitialized(false);
    return null;
  }

  const mostly: 'positive' | 'negative' | 'neutral' =
    data.positiveCount > data.negativeCount &&
    data.positiveCount > data.neutralCount
      ? 'positive'
      : data.negativeCount > data.neutralCount
      ? 'negative'
      : 'neutral';

  const icon = ICON[mostly];

  const totalCount =
    data.positiveCount + data.negativeCount + data.neutralCount;

  return (
    <Flex flexDir="column" justifyContent="center" h="full">
      <Text fontWeight={500} color="gray.500" fontSize={14}>
        {SpkTime.format(data.date, 'MMM dd, yyyy')}
      </Text>
      <Divider mt={2} mb={3} />
      <Flex alignItems="center">
        {icon}
        <Text ml={2} color="gray.700" fontWeight={500} fontSize={16}>
          Mostly {mostly}
        </Text>
      </Flex>
      <Flex my={3}>
        <Text fontSize={14} color="gray.700" fontWeight={500}>
          Cards
        </Text>
        <Spacer />
        <Text color="gray.500" fontSize={14}>
          {totalCount}
        </Text>
      </Flex>
      <Flex mb={4}>
        <Flex
          bg={colors.positive.bg}
          minH={2}
          minW={10}
          flex={!barsInitialized ? 1 : data.positiveCount}
          transition="flex 0.3s ease-out"
          borderLeftRadius={2}
        />
        <Flex
          bg={colors.neutral.bg}
          minH={2}
          minW={10}
          flex={!barsInitialized ? 1 : data.neutralCount}
          transition="flex 0.3s ease-out"
        />
        <Flex
          bg={colors.negative.bg}
          minH={2}
          minW={10}
          flex={!barsInitialized ? 1 : data.negativeCount}
          transition="flex 0.3s ease-out"
          borderRightRadius={2}
        />
      </Flex>
      <Flex mb="12px">
        <Circle size={3} bg={colors.positive.bg} />
        <Text color="gray.700" ml={1} fontSize={14} fontWeight={500}>
          Positive
        </Text>
        <Spacer />
        <Text color="gray.500" fontSize={14}>
          {data.positiveCount}
        </Text>
      </Flex>
      <Flex mb="12px">
        <Circle size={3} bg={colors.neutral.bg} />
        <Text color="gray.700" ml={1} fontSize={14} fontWeight={500}>
          Neutral
        </Text>
        <Spacer />
        <Text color="gray.500" fontSize={14}>
          {data.neutralCount}
        </Text>
      </Flex>
      <Flex>
        <Circle size={3} bg={colors.negative.bg} />
        <Text color="gray.700" ml={1} fontSize={14} fontWeight={500}>
          Negative feedback
        </Text>
        <Spacer />
        <Text color="gray.500" fontSize={14}>
          {data.negativeCount}
        </Text>
      </Flex>
    </Flex>
  );
};
