import { Button } from '@chakra-ui/react';
import { SpkTime, isNullish, NUMBER_ONLY } from '@spoke/common';
import { FC, useEffect, useRef, useState } from 'react';
import { BsGlobe } from 'react-icons/bs';
import { Flex } from '../Flex';
import { Icon } from '../Icon';
import { HStack } from '../Stack/HStack';
import { Text } from '../Text';
import { Input, InputProps } from './Input';
import { InputGroup } from './InputGroup';
import { InputRightElement } from './InputRightElement';

const NOON_IN_MS = 43200000;
const HOUR_IN_MS = 60 * 60 * 1000;
const MINUTE_IN_MS = 60 * 1000;

type TimeInputProps = Omit<InputProps, 'value' | 'onChange'> & {
  value: number | null;
  onChange: (value: number | null) => void;
};

const getMsValueFromStr = (str: string, amPm: 'am' | 'pm') => {
  const hours = parseInt(str?.split(':')[0]?.padStart(2, '0') || '0');
  const minutes = parseInt(str?.split(':')[1]?.padEnd(2, '0') || '0');
  // 12 hours actually means 0 hours in this bullshit standard
  // https://en.wikipedia.org/wiki/12-hour_clock#Confusion_at_noon_and_midnight
  const amountOfHours = (hours === 12 ? 0 : hours) + (amPm === 'pm' ? 12 : 0);
  const ms =
    amountOfHours * HOUR_IN_MS +
    minutes * MINUTE_IN_MS -
    SpkTime.getUtcOffsetMs();
  return ms;
};

/**
 * Output is miliseconds from midnight
 */
export const TimeInput: FC<TimeInputProps> = ({ value, onChange, ...rest }) => {
  const [amPm, setAmPm] = useState<'am' | 'pm'>('am');

  const [stringValue, setStringValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newStringValue = e.target.value;

    const newCharacter = newStringValue.replace(/:/g, '').split('').pop();
    if (newCharacter && !newCharacter.match(NUMBER_ONLY)) return;

    if (newStringValue[1] === ':') return; // Deleting text backwards

    if (newStringValue.length > 5) return;

    // Adding colon
    if (newStringValue.length >= 3 && newStringValue[2] !== ':') {
      newStringValue =
        newStringValue.slice(0, 2) + ':' + newStringValue.slice(2);
    }

    // Removing colon
    if (newStringValue[newStringValue.length - 1] === ':') {
      newStringValue = newStringValue.slice(0, newStringValue.length - 1);
    }

    const validStringValue = SpkTime.coerceToValidDisplayString(newStringValue);

    setStringValue(validStringValue);
  };

  // Pushing ms value upwards (keeping parent state up to date)
  useEffect(() => {
    if (!stringValue) {
      onChange(null);
      return;
    }

    const msValue = getMsValueFromStr(stringValue, amPm);

    onChange(msValue);
  }, [stringValue, amPm, onChange]);

  // Pulling parent state downwards (keeping component state up to date)
  useEffect(() => {
    if (isNullish(value)) {
      setStringValue('');
      setAmPm('am');
      return;
    }

    const inputValue = inputRef.current?.value || '';

    const internalMsValue = getMsValueFromStr(inputValue, amPm);

    if (Number.isNaN(internalMsValue)) return;
    if (internalMsValue === value) return;

    const newStringValue = SpkTime.getStrFromMsValue(value!);
    const localMs = SpkTime.utcTimeMsToLocalTimeMs(value!);

    setStringValue(newStringValue);
    setAmPm(localMs > NOON_IN_MS ? 'pm' : 'am');

    // We don't mind for the updated amPm value (since amPm changes are upwards always)
    // and we don't want to trigger this effect
    // otherwise both push and pull would be triggered at once and we get an infinite loop
    // Feel free to refactor if you find a better solution than pushing/pulling a separate state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const fillMissingZeroes = () => {
    if (!stringValue) return;
    const hours = stringValue.split(':')[0]?.padEnd(2, '0') || '01';
    const minutes = stringValue.split(':')[1]?.padEnd(2, '0') || '00';
    const newSringValue = `${hours}:${minutes}`;
    setStringValue(newSringValue);
  };

  return (
    <Flex flexDir="column">
      <InputGroup>
        <Input
          placeholder={rest.placeholder ?? '09:00'}
          textAlign="left"
          value={stringValue}
          onChange={handleInputChange}
          ref={inputRef}
          onBlur={fillMissingZeroes}
          {...rest}
        />
        <InputRightElement mr={0} pr={8} pt={1} gap={1} pointerEvents="all">
          <Button
            fontSize={10}
            px={1}
            bg={amPm === 'am' ? 'primary.400' : 'gray.100'}
            color={amPm === 'am' ? 'white' : 'gray.500'}
            borderRadius={2}
            size="xs"
            variant="unstyled"
            onClick={() => setAmPm('am')}
          >
            AM
          </Button>
          <Button
            fontSize={10}
            px={1}
            bg={amPm === 'pm' ? 'primary.400' : 'gray.100'}
            borderRadius={2}
            color={amPm === 'pm' ? 'white' : 'gray.500'}
            size="xs"
            variant="unstyled"
            onClick={() => setAmPm('pm')}
          >
            PM
          </Button>
        </InputRightElement>
      </InputGroup>
      <HStack mt={1} spacing={1}>
        <Icon as={BsGlobe} color="gray.500" w="15px" h="15px" />
        <Text
          textAlign="left"
          fontSize={12}
          color="gray.500"
          fontWeight={500}
          pt="3px"
          ml={1}
        >
          {SpkTime.getUserTimezoneString()}
        </Text>
      </HStack>
    </Flex>
  );
};
