import { IconProps, Icon } from '../Icon';

export const SeverityCriticalIcon = (props: IconProps) => (
  <Icon
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.06 5.53003L4 2.4767L0.94 5.53003L0 4.59003L4 0.59003L8 4.59003L7.06 5.53003Z"
      fill="#D81616"
    />
    <path
      d="M7.06 9.46997L4 6.41664L0.94 9.46997L0 8.52997L4 4.52997L8 8.52997L7.06 9.46997Z"
      fill="#D81616"
    />
    <path
      d="M7.06 13.4102L4 10.3568L0.94 13.4102L0 12.4702L4 8.47016L8 12.4702L7.06 13.4102Z"
      fill="#D81616"
    />
  </Icon>
);
