import { makeVar, useApolloClient } from '@apollo/client';

import {
  useBoardByIdLazyQuery,
  BoardStage,
  useIsUserAuthorizedForBoardLazyQuery,
  BoardUpdatesSubscription,
  BoardUpdatesDocument,
  BoardDiscussStageUpdatesSubscription,
  BoardDiscussStageUpdatesDocument,
  CardVoteUpdatesByBoardIdSubscription,
  CardVoteUpdatesByBoardIdDocument,
  BoardMessageBroadcastSubscription,
  BoardMessageBroadcastDocument,
  NewCardsByBoardIdSubscription,
  NewCardsByBoardIdDocument,
} from '@spoke/graphql';
import { useState, useRef, useEffect } from 'react';
import { FIVE_SECONDS_MS } from '../../../constants';
import { useNetworkContext } from '../../../context';
import { log } from '../../../SpkLog';
import { QueryConfig, DeepPartial } from '../../../types';
import { useToast } from '../../../ui';
import {
  deepMerge,
  NO_OP,
  getNewCardsSubscriptionProcessor,
} from '../../../util';
import { useInstanceCount } from '../../etc';
import { useCurrentUser } from '../user';
import { useCurrentBoardId } from './useCurrentBoardId';

type Config = QueryConfig<typeof useBoardByIdLazyQuery>;
type QueryRef = ReturnType<typeof useBoardByIdLazyQuery>[1];
type QueryData = NonNullable<QueryRef['data']>['board'];

type UseCurrentBoardOptions = {
  /**
   * Whether to initialize the board subscription if not already initialized
   */
  initializeSubscription?: boolean;
};

const DEFAULT_OPTIONS: UseCurrentBoardOptions = {
  initializeSubscription: true,
};

const BOARD_POLLING_INTERVAL_MS = FIVE_SECONDS_MS;

// This state persists app-wide, not component-specific. So we don't create unecessary susbcriptions
const subscribedToChangesVar = makeVar<boolean>(false);
const unsubscriptionsVar = makeVar<(() => void)[]>([]);
const isPollingVar = makeVar<boolean>(false);

export const useCurrentBoard = (
  { initializeSubscription }: UseCurrentBoardOptions = DEFAULT_OPTIONS,
  queryConfig: DeepPartial<Config> = {}
): [QueryData, QueryRef, BoardStage | null, boolean] => {
  const [toast] = useToast();
  const apolloClient = useApolloClient();
  const { shouldPoll } = useNetworkContext();
  const [boardId, viewSummary] = useCurrentBoardId();
  const [board, setBoard] = useState<QueryData>(null);
  const [boardIsChangingStageTo, setBoardIsChangingStageTo] =
    useState<BoardStage | null>(null);

  const [getInstanceCount] = useInstanceCount('useCurrentBoard');

  const [loadIsUserAuthorizedForBoardQuery, isUserAuthorizedForBoardQuery] =
    useIsUserAuthorizedForBoardLazyQuery();

  const isAuthorized =
    isUserAuthorizedForBoardQuery.data?.isUserAuthorizedForBoard;

  const timeoutRefs = useRef([] as NodeJS.Timeout[]);

  const [currentUser] = useCurrentUser();
  const userId = currentUser?.id ?? '';

  const baseConfig: DeepPartial<Config> = {
    variables: { id: boardId },
  };

  const finalConfig = (
    queryConfig ? deepMerge(baseConfig, queryConfig) : baseConfig
  ) as Config;

  const [loadBoardQuery, boardQuery] = useBoardByIdLazyQuery(finalConfig);

  useEffect(() => {
    if (!boardId || boardQuery.called) return;
    loadBoardQuery();

    // No need for network only here since we just want to subscribe to cache changes
    // BoardGuard and BoardInvitationScreen will handle getting this up to date with backend
    loadIsUserAuthorizedForBoardQuery({ variables: { boardId } });
  }, [
    boardId,
    boardQuery.called,
    loadBoardQuery,
    loadIsUserAuthorizedForBoardQuery,
  ]);

  useEffect(() => {
    (async () => {
      const currentUserId = currentUser?.id;

      if (
        subscribedToChangesVar() ||
        shouldPoll ||
        !boardId ||
        !currentUserId ||
        !initializeSubscription ||
        !isAuthorized
      ) {
        return;
      }

      log.info('Starting board subscriptions');
      subscribedToChangesVar(true);

      const boardUpdates = apolloClient
        .subscribe<BoardUpdatesSubscription>({
          query: BoardUpdatesDocument,
          variables: { id: boardId },
        })
        .subscribe(NO_OP);

      const discussStageUpdates = apolloClient
        .subscribe<BoardDiscussStageUpdatesSubscription>({
          query: BoardDiscussStageUpdatesDocument,
          variables: { boardId },
        })
        .subscribe(NO_OP);

      const cardVoteUpdates = apolloClient
        .subscribe<CardVoteUpdatesByBoardIdSubscription>({
          query: CardVoteUpdatesByBoardIdDocument,
          variables: { boardId },
        })
        .subscribe(NO_OP);

      const messageBroadcasts = apolloClient
        .subscribe<BoardMessageBroadcastSubscription>({
          query: BoardMessageBroadcastDocument,
          variables: { boardId },
        })
        .subscribe((broadcast) => {
          const data = broadcast.data?.boardMessageBroadcastSubscription;
          if (!data) return;
          const { message, newFacilitatorUserId, timeOut, title } = data;
          if (newFacilitatorUserId && newFacilitatorUserId !== currentUserId) {
            return;
          }

          toast({
            status: 'info',
            title,
            description: message,
            duration: timeOut || 5000,
          });
        });

      const newCards = apolloClient
        .subscribe<NewCardsByBoardIdSubscription>({
          query: NewCardsByBoardIdDocument,
          variables: { boardId },
        })
        .subscribe(getNewCardsSubscriptionProcessor(apolloClient.cache));

      unsubscriptionsVar([
        boardUpdates.unsubscribe.bind(boardUpdates),
        discussStageUpdates.unsubscribe.bind(discussStageUpdates),
        cardVoteUpdates.unsubscribe.bind(cardVoteUpdates),
        messageBroadcasts.unsubscribe.bind(messageBroadcasts),
        newCards.unsubscribe.bind(newCards),
      ]);
    })();
  }, [
    boardId,
    boardQuery,
    apolloClient,
    shouldPoll,
    toast,
    currentUser?.id,
    initializeSubscription,
    isAuthorized,
  ]);

  useEffect(() => {
    const newBoard =
      boardQuery.data?.board ?? boardQuery.previousData?.board ?? null;

    const currentStage = board?.state?.stage;
    const newStage = newBoard?.state?.stage;

    const stageIsChanging: boolean = Boolean(currentStage !== newStage);

    if (currentStage && newStage && stageIsChanging) {
      setBoardIsChangingStageTo(newStage);
      const timeout1 = setTimeout(() => {
        setBoard(newBoard);
      }, 1500);
      const timeout2 = setTimeout(() => {
        setBoardIsChangingStageTo(null);
      }, 3500);
      timeoutRefs.current.push(timeout1);
      timeoutRefs.current.push(timeout2);
    } else setBoard(newBoard);
  }, [
    board?.state?.stage,
    boardQuery.data?.board,
    boardQuery.previousData?.board,
  ]);

  useEffect(() => {
    const shouldStopPolling =
      (!userId || (!shouldPoll && isPollingVar())) && initializeSubscription;

    if (shouldStopPolling) {
      log.info('Stopping board polling');
      boardQuery.stopPolling();
      isPollingVar(false);
      return;
    }

    const shouldStartPolling =
      shouldPoll && !isPollingVar() && initializeSubscription;

    if (shouldStartPolling) {
      log.info('Starting board polling');
      boardQuery.startPolling(BOARD_POLLING_INTERVAL_MS);
      isPollingVar(true);
      return;
    }
  }, [boardQuery, shouldPoll, userId, initializeSubscription]);

  useEffect(() => {
    const timeouts = timeoutRefs.current;
    return () => {
      for (const timeout of timeouts) clearTimeout(timeout);
    };
  }, []);

  useEffect(
    () => () => {
      // This allows us to only unsubscribe when all useCurrentBoard hooks are unmounted
      const isLastInstance = getInstanceCount() === 0;
      if (!isLastInstance) return;
      log.info('Stopping board subscriptions');
      for (const unsubscribe of unsubscriptionsVar()) unsubscribe();
      subscribedToChangesVar(false);
      if (!isPollingVar()) return;
      log.info('Stopping board polling');
      boardQuery.stopPolling();
      isPollingVar(false);
    },
    [boardQuery, getInstanceCount]
  );

  return [board, boardQuery, boardIsChangingStageTo, viewSummary];
};
