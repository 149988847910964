import { FC } from 'react';
import {
  ConfirmationModal,
  Text,
  useAssets,
  useCurrentBoard,
} from '@spoke/common';

type ReopenRetroConfirmationModalProps = {
  onClose: () => unknown;
  onConfirm: () => unknown;
  isOpen: boolean;
};
export const ReopenRetroConfirmationModal: FC<
  ReopenRetroConfirmationModalProps
> = ({ isOpen, onClose, onConfirm }) => {
  const { illustrationCanvas } = useAssets();
  const [board] = useCurrentBoard();
  console.log('board', board);

  const title = board?.boardAsyncScheduleId
    ? 'Are you sure you want to review this feedback?'
    : 'Are you sure you want to reopen this retrospective?';

  const textComponent = board?.boardAsyncScheduleId ? (
    <Text color="gray.600" fontSize={16} mb={2}>
      This will show all the feedback from the team without revealing who gave
      it, and allow you to retrospect on it as a team.
    </Text>
  ) : (
    <Text color="gray.600" fontSize={16} mb={2}>
      This will reopen this retrospective in <b>Discuss</b> stage and allow
      editing the retrospective contents again.
    </Text>
  );

  const confirmText = board?.boardAsyncScheduleId
    ? 'Yes'
    : 'Yes, reopen retrospective';

  return (
    <ConfirmationModal
      close={onClose}
      isOpen={isOpen}
      image={illustrationCanvas}
      imageSize={250}
      title={title}
      text={textComponent}
      confirmText={confirmText}
      cancelText="No, go back"
      onConfirm={onConfirm}
    />
  );
};
