import { Flex, Text } from '@chakra-ui/react';
import {
  MemoizedActionItemCard,
  useDeleteActionItemHandler,
  useToggleActionItemStatusHandler,
} from '@spoke/action-item';
import { ActionItem, Activity, ActivityType, Board } from '@spoke/graphql';
import { RetroHistoryCard, useArchiveBoardHandler } from '@spoke/board';
import {
  Button,
  ConfirmationModalCondensed,
  useRouter,
  useRoutes,
  log,
  HStack,
  Avatar,
  SpkLink,
} from '@spoke/common';
import { useCallback, useState } from 'react';
import { ImprovementGoalCard } from '@spoke/improvement-goals';
import {
  TeamInsight,
  TeamInsightFactCauseSolution,
  TeamInsightFactCauseSolutionFact,
  TeamInsightsModal,
} from '@spoke/insights';

export const ActivityFeedCardBody = ({ activity }: { activity: Activity }) => {
  const router = useRouter();
  const routes = useRoutes();

  // action item helpers
  const [handleDeleteActionItem] = useDeleteActionItemHandler();
  const [handleToggleActionItemStatus] = useToggleActionItemStatusHandler();

  // retro card helpers
  const onSelectBoard = (id: string) => router.push(routes.Board(id));
  const [handleArchiveBoard] = useArchiveBoardHandler();

  const [boardToArchive, setBoardToArchive] = useState<Pick<
    Board,
    'name' | 'id'
  > | null>();

  const onArchiveBoardClick = () => setBoardToArchive(activity.board);

  const onArchiveBoardConfirm = useCallback(async () => {
    if (!boardToArchive?.id) {
      log.error('Missing critical data to archive board');
      return;
    }

    await handleArchiveBoard(boardToArchive.id);
    setBoardToArchive(null);
  }, [boardToArchive?.id, handleArchiveBoard]);

  // insights modal helpers
  const [viewingInsightId, setViewingInsightId] = useState<string | null>(null);
  const viewingInsight =
    activity.insight?.id === viewingInsightId ? activity.insight : null;

  const openInsightModal = (insightId: string) => {
    setViewingInsightId(insightId);
  };

  const closeInsightModal = () => {
    setViewingInsightId(null);
  };

  type ConditionalLinkProps = {
    isExternal: boolean;
    href: string;
    children: React.ReactNode;
  };

  const ConditionalLink = ({
    isExternal,
    href,
    children,
  }: ConditionalLinkProps) => {
    if (isExternal) {
      return (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    }

    return (
      <SpkLink href={href} passHref>
        {children}
      </SpkLink>
    );
  };

  const renderActivityFeedItemLines = (text: string) => {
    const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;

    return text.split('\n').map((line, i) => {
      const parts = line.split(markdownLinkRegex);

      return (
        <span key={i}>
          {parts.map((part, j) => {
            if (j % 3 === 1) {
              // This part is the link text
              const isExternalLink = /^https?:\/\//.test(parts[j + 1]);
              const isInsightLink = /^\/insights\//.test(parts[j + 1]);
              if (isInsightLink) {
                return (
                  <span
                    key={j}
                    style={{
                      color: '#5133b3',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() => openInsightModal(activity.insight?.id || '')}
                  >
                    {part}
                  </span>
                );
              } else {
                return (
                  <ConditionalLink
                    key={j}
                    href={parts[j + 1]}
                    isExternal={isExternalLink}
                  >
                    <span
                      style={{
                        color: '#5133b3',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      {part}
                    </span>
                  </ConditionalLink>
                );
              }
            } else if (j % 3 === 2) {
              // This part is the URL, which we've already handled
              return null;
            } else {
              // This part is plain text
              return part;
            }
          })}
          <br />
        </span>
      );
    });
  };

  const renderFocusComponent = () => {
    if (!activity?.type) {
      return null;
    }
    switch (activity.type) {
      case ActivityType.ActionItem:
        if (!activity.actionItem) {
          return null;
        }
        return (
          <MemoizedActionItemCard
            actionItem={activity.actionItem as ActionItem}
            onDelete={handleDeleteActionItem}
            onResolveToggle={handleToggleActionItemStatus}
            width={['100%', '100%', '60%']} // Responsive width control
          />
        );
      case ActivityType.Retrospective:
        if (!activity.board) {
          return null;
        }
        return (
          <RetroHistoryCard
            board={activity.board as Board}
            onSelectBoard={onSelectBoard}
            onCloneBoard={() => {}}
            onToggleBoardPrivacy={() => {}}
            onMoveBoard={() => {}}
            onArchiveBoard={onArchiveBoardClick}
          />
        );
      case ActivityType.Goal:
        if (!activity.goal) {
          return null;
        }
        return (
          <Flex>
            <ImprovementGoalCard
              goal={activity.goal}
              onReportClick={() => {}}
            />
          </Flex>
        );
      case ActivityType.Insight:
        if (!activity.insight) {
          return null;
        }
        return (
          <TeamInsightFactCauseSolutionFact
            skipIntroductionText={true}
            fontSize={14}
            skipAnimations={true}
            date={new Date(activity.insight.date)}
            fact={activity.insight.fact}
          />
        );
      case ActivityType.User:
        if (!activity.user) {
          return null;
        }
        return (
          <Flex
            alignItems="center"
            backgroundColor="white"
            borderRadius="md"
            p={4}
            boxShadow="sm"
            width={['100%', '100%', '38%']} // Responsive width control
          >
            <HStack flex={1} spacing={3}>
              <Avatar
                name={activity.user.name || ''}
                height="7px"
                width="7px"
                padding="11px"
                fontSize={12}
                ml={2}
              />
              <Text color="gray.700" fontSize={14} fontWeight={500}>
                {activity.user.name}
              </Text>
            </HStack>
            <HStack flex={1}>
              <Text color="gray.600" fontSize={14}>
                {activity.user.email}
              </Text>
            </HStack>
          </Flex>
        );
      default:
        return null;
    }
  };

  return (
    <Flex justifyContent="flex-start">
      <Flex direction="column" flex="1" gap="4" flexWrap="wrap" mt={2}>
        {renderFocusComponent()}
        {activity.bodyText && activity.bodyText !== ' ' && (
          <Text fontSize="14" color="gray.600">
            {renderActivityFeedItemLines(activity.bodyText)}
          </Text>
        )}
        {activity.ctaText && (
          // TODO: use ctaLink for onClick
          <Button size="sm" variant="link">
            {activity.ctaText}
          </Button>
        )}
      </Flex>
      <ConfirmationModalCondensed
        title="Archive retrospective"
        text={`You are about to archive retrospective <b>${boardToArchive?.name}</b>. To recover archived retrospectives you will need to contact support.\n\nAre you sure you want to archive this retrospective?`}
        confirmText="Archive retrospective"
        isOpen={Boolean(boardToArchive)}
        close={() => setBoardToArchive(null)}
        onConfirm={onArchiveBoardConfirm}
      />
      <TeamInsightsModal
        isOpen={Boolean(viewingInsightId)}
        onClose={closeInsightModal}
        insights={viewingInsight ? [viewingInsight] : []}
        skipAnimations
      />
    </Flex>
  );
};
