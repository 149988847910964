import {
  deepMerge,
  DeepPartial,
  ONE_WEEK_MS,
  QueryConfig,
  SpkTime,
  useNow,
} from '@spoke/common';
import { useImpactReportQuery } from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';

type Config = QueryConfig<typeof useImpactReportQuery>;
type QueryRef = ReturnType<typeof useImpactReportQuery>;
export type ImpactReportGoalsHookData = NonNullable<
  NonNullable<QueryRef['data']>['impactReport']
>;

export const useImpactReport = (
  config?: DeepPartial<Config>
): [ImpactReportGoalsHookData | null, QueryRef] => {
  const [team] = useCurrentTeam();
  const teamId = team?.id ?? '';

  const now = useNow({ roundTo: 'ten-minutes' });

  const fallbackStart = new Date(
    now.getFullYear(),
    now.getMonth() - 3,
    now.getDate()
  ).getTime();
  const fallbackEnd = now.getTime();

  const baseConfig: DeepPartial<Config> = {
    variables: {
      filter: {
        teamId,
        rollupTeamIds: config?.variables?.filter?.rollupTeamIds ?? [],
        utcOffsetMs: SpkTime.getUtcOffsetMs(),
        startDate: config?.variables?.filter?.startDate ?? fallbackStart,
        endDate: config?.variables?.filter?.endDate ?? fallbackEnd,
      },
    },
    skip: !teamId,
  };

  const finalConfig = (
    config ? deepMerge(baseConfig, config) : baseConfig
  ) as Config;

  const impactReportQuery = useImpactReportQuery(finalConfig);

  const data: ImpactReportGoalsHookData | null =
    impactReportQuery.data?.impactReport ??
    impactReportQuery.previousData?.impactReport ??
    null;

  return [data, impactReportQuery];
};
