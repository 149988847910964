import { FC, useState, useCallback } from 'react';
import { useForm, useWatch, useFieldArray } from 'react-hook-form';
import { MdClose, MdNote, MdOutlineBookmarkAdd } from 'react-icons/md';
import { FiInfo } from 'react-icons/fi';
import { AnimatePresence } from 'framer-motion';
import { AiTwotoneCalendar } from 'react-icons/ai';
import { BsChevronDown } from 'react-icons/bs';
import { datetime, RRule, RRuleSet, rrulestr } from 'rrule';
import {
  BoardAsyncCollectionType,
  BoardAsyncFeedbackType,
  BoardAsyncQuestionType,
  BoardAsyncSchedule,
  BoardAsyncScheduleType,
  ImprovementGoalUnit,
  RetrospectiveFormat,
  useAllRetroFormatsQuery,
  useCreateBoardAsyncScheduleMutation,
  useUpdateBoardAsyncScheduleMutation,
} from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';
import {
  GoalDrilldownConfiguration,
  ImprovementGoalValueInput,
} from '@spoke/improvement-goals';
import {
  SpokeFormRules,
  ModalProps,
  useDisclosure,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  HStack,
  Icon,
  Heading,
  ModalBody,
  Flex,
  Spinner,
  VStack,
  Modal,
  ModalOverlay,
  Text,
  FormControl,
  FormLabel,
  Select,
  Option,
  FormErrorMessage,
  TimeInput,
  Tooltip,
  Spacer,
  Switch,
  Center,
  Button,
  Input,
  Box,
  MotionFlex,
  DatePickerPopover,
  SpkTime,
  Link,
  useRouter,
  useRoutes,
  useCurrentUser,
  SlackIcon,
  MicrosoftTeamsIcon,
  InfoTooltip,
  InputGroup,
  InputRightElement,
} from '@spoke/common';
import { useInstalledOrgIntegrations } from '@spoke/metrics';

export type BoardAsyncFeedbackFormSchema = {
  id?: string;
  teamId: string;
  name: string;
  rrule?: string;
  oneTimeSchedule?: Date;
  collectionTimeMs: number | null;
  feedbackCollectionType: string;
  feedbackType: string;
  maxQuestions?: number;
  retrospectiveFormatId?: string;
  sendReminders: boolean;
  areQuestionsOptional: boolean;
  isActive: boolean;
  questions: { id?: string; question: string; isOptional: boolean }[];
};

const rules: SpokeFormRules<BoardAsyncFeedbackFormSchema> = {
  name: { required: 'Name is required' },
  teamId: { required: 'Team ID is required' },
  collectionTimeMs: {
    required: 'Collection time is required',
    validate: (value: number | null) =>
      (value !== null && value > 0) || 'Collection time must be greater than 0',
  },
  feedbackCollectionType: { required: 'Feedback channel is required' },
  feedbackType: { required: 'Feedback type is required' },
  sendReminders: {},
  areQuestionsOptional: {},
  isActive: {},
  id: {},
  rrule: { required: 'Frequency is required' },
  oneTimeSchedule: { required: 'One-time schedule is required' },
  maxQuestions: { required: 'Max questions is required' },
  retrospectiveFormatId: {},
  questions: {
    validate: (questions) =>
      questions.length > 0 && questions.length <= 10
        ? true
        : 'You must define between 1 and 10 questions',
  },
};

type AsyncFeedbackConfigModalProps = {
  config?: BoardAsyncSchedule;
  onGoBack: () => void;
  isOpen: boolean;
  onClose: () => void;
};

export const AsyncFeedbackConfigModalContent: FC<
  AsyncFeedbackConfigModalProps
> = ({ config, onGoBack, isOpen, onClose }) => {
  const [createBoardAsyncSchedule] = useCreateBoardAsyncScheduleMutation();
  const [updateBoardAsyncSchedule] = useUpdateBoardAsyncScheduleMutation();
  const [team] = useCurrentTeam();
  const [user] = useCurrentUser();
  const { loading, slack } = useInstalledOrgIntegrations();

  const retroFormatsQuery = useAllRetroFormatsQuery();
  const retroFormats =
    (retroFormatsQuery.data?.retrospectiveFormats as RetrospectiveFormat[]) ??
    null;

  const [recurringFeedbackCollection, setRecurringFeedbackCollection] =
    useState<boolean>(
      config ? config?.scheduleType === BoardAsyncScheduleType.Recurring : true
    );
  const onRecurringFeedbackCollection = async () => {
    setRecurringFeedbackCollection(!recurringFeedbackCollection);
  };

  const defineYourOwnQuestionsDisclosure = useDisclosure(
    config?.questionType === BoardAsyncQuestionType.Custom || !config
  );
  const useRetrospectiveTemplateDisclosure = useDisclosure(
    config?.questionType === BoardAsyncQuestionType.Template
  );

  const getDefaultOneTimeSchedule = (): Date => {
    const today = new Date();
    // we just want today's date, reguardless of where you are.
    return new Date(today.getFullYear(), today.getMonth(), today.getDate());
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setValue,
    setError,
    getValues,
    control,
  } = useForm<BoardAsyncFeedbackFormSchema>({
    defaultValues: {
      id: config?.id || '',
      teamId: config?.teamId || '',
      name: config?.name || '',
      rrule: config?.rrule || '',
      collectionTimeMs: config?.collectionTime || null,
      feedbackCollectionType:
        config?.feedbackCollectionType?.toString() || 'EMAIL',
      feedbackType: config?.feedbackType?.toString() || 'CHECK_IN',
      oneTimeSchedule: config?.oneTimeSchedule
        ? new Date(config.oneTimeSchedule - SpkTime.getUtcOffsetMs())
        : getDefaultOneTimeSchedule(),
      maxQuestions: config?.maxQuestions || undefined,
      retrospectiveFormatId: config?.retrospectiveFormatId || undefined,
      sendReminders: config?.sendReminders,
      areQuestionsOptional: config?.areQuestionsOptional,
      isActive: config?.isActive,
      questions: config?.customQuestions || [
        { question: '', isOptional: false },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questions',
  });

  const feedbackCollectionType = useWatch({
    control,
    name: 'feedbackCollectionType',
  });

  const oneTimeSchedule = useWatch({
    control,
    name: 'oneTimeSchedule',
  });

  const onSubmit = useCallback(
    async (form: BoardAsyncFeedbackFormSchema) => {
      const validateCollectionTime = (value: number | null) => {
        if (value === null || value <= 0) {
          setError('collectionTimeMs', {
            type: 'manual',
            message: 'Choose a time to collect feedback',
          });
          return false;
        } else {
          setError('collectionTimeMs', {
            type: 'manual',
            message: '',
          });
          return true;
        }
      };

      const validateQuestions = (questions: { question: string }[]) => {
        if (defineYourOwnQuestionsDisclosure.isOpen) {
          const hasValidQuestion = questions.some(
            (q) => q.question.trim().length > 0
          );
          if (!hasValidQuestion) {
            setError('questions', {
              type: 'manual',
              message: 'You must provide at least 1 question to ask your team',
            });
            return false;
          }
        }
        return true;
      };

      const filteredQuestions = form.questions.filter(
        (q) => q.question.trim().length > 0
      );

      const isCollectionTimeValid = validateCollectionTime(
        form.collectionTimeMs
      );
      const areQuestionsValid = validateQuestions(form.questions);

      if (
        !isCollectionTimeValid ||
        !areQuestionsValid ||
        Object.keys(errors).length > 0
      ) {
        return;
      }
      let questionType: BoardAsyncQuestionType =
        BoardAsyncQuestionType.AutoGenerate;

      if (defineYourOwnQuestionsDisclosure.isOpen) {
        questionType = BoardAsyncQuestionType.Custom;
      } else if (useRetrospectiveTemplateDisclosure.isOpen) {
        questionType = BoardAsyncQuestionType.Template;
      }

      const scheduleType: BoardAsyncScheduleType =
        recurringFeedbackCollection && form.rrule
          ? BoardAsyncScheduleType.Recurring
          : BoardAsyncScheduleType.OneTime;

      if (config) {
        await updateBoardAsyncSchedule({
          variables: {
            id: form.id || '',
            data: {
              teamId: form.teamId,
              ownerId: user?.id || '',
              name: form.name,
              scheduleType,
              rrule: form.rrule,
              feedbackCollectionType:
                form.feedbackCollectionType as BoardAsyncCollectionType,
              oneTimeSchedule: form.oneTimeSchedule
                ? form.oneTimeSchedule.getTime()
                : undefined,
              collectionTime: form.collectionTimeMs ?? 0,
              questionType,
              maxQuestions: form.maxQuestions,
              retrospectiveFormatId: form.retrospectiveFormatId,
              sendReminders: form.sendReminders,
              areQuestionsOptional: form.areQuestionsOptional,
              feedbackType: form.feedbackType as BoardAsyncFeedbackType,
            },
            customQuestions: filteredQuestions.map((q, index) => ({
              ...(q.id && { id: q.id }),
              question: q.question,
              isOptional: q.isOptional,
              index,
            })),
          },
        });
        onGoBack();
      } else {
        await createBoardAsyncSchedule({
          variables: {
            data: {
              teamId: team?.id || '',
              ownerId: user?.id || '',
              name: form.name,
              scheduleType,
              rrule: form.rrule,
              feedbackCollectionType:
                form.feedbackCollectionType as BoardAsyncCollectionType,
              oneTimeSchedule: form.oneTimeSchedule
                ? form.oneTimeSchedule.getTime()
                : undefined,
              collectionTime: form.collectionTimeMs ?? 0,
              questionType,
              maxQuestions: form.maxQuestions,
              retrospectiveFormatId: form.retrospectiveFormatId || undefined,
              sendReminders: false,
              areQuestionsOptional: false,
              feedbackType: form.feedbackType as BoardAsyncFeedbackType,
            },
            customQuestions: filteredQuestions.map((q, index) => ({
              question: q.question,
              isOptional: q.isOptional,
              index,
            })),
          },
        });
        onGoBack();
      }
    },
    [
      config,
      createBoardAsyncSchedule,
      updateBoardAsyncSchedule,
      recurringFeedbackCollection,
      defineYourOwnQuestionsDisclosure.isOpen,
      useRetrospectiveTemplateDisclosure.isOpen,
      team?.id,
      onGoBack,
      user?.id,
      errors,
      setError,
    ]
  );

  const onDefineYourOwnQuestions = async () => {
    defineYourOwnQuestionsDisclosure.toggle();
    useRetrospectiveTemplateDisclosure.toggle();
  };
  const onUseRetrospectiveTemplate = async () => {
    useRetrospectiveTemplateDisclosure.toggle();
    defineYourOwnQuestionsDisclosure.toggle();
  };

  const [selectedRetrospectiveFormat, setSelectedRetrospectiveFormat] =
    useState<string | undefined>(config?.retrospectiveFormatId || undefined);

  const handleRetrospectiveFormatChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newFormat = event.target.value;
    setValue('retrospectiveFormatId', newFormat);
    setSelectedRetrospectiveFormat(newFormat);
  };

  const selectedFormatColumns = retroFormats?.find(
    (format) => format.slug === selectedRetrospectiveFormat
  )?.columnNames;

  return (
    <ModalContent p={8} maxW={700}>
      <ModalCloseButton />
      <ModalHeader p={0} mb={4}>
        <HStack>
          <Heading mb={1} fontSize={24} color="gray.900">
            {config ? `Edit Feedback Schedule` : 'Set Up Feedback Schedule'}
          </Heading>
        </HStack>
      </ModalHeader>
      <ModalBody p={0}>
        <VStack spacing={4} justifyContent="space-between" alignItems="stretch">
          <Text fontSize="15" color="gray.500">
            Automate feedback collection from your team for things like
            standups, retrospectives, surveys, and more. You can{' '}
            <Link
              color="primary.500"
              href="/integrations"
              isExternal
              target="_blank"
              rel="noopener noreferrer"
            >
              configure channels
            </Link>{' '}
            or{' '}
            <Link
              color="primary.500"
              href="https://www.scatterspoke.com/kb-cat/asynchronous-feedback"
              isExternal
              target="_blank"
              rel="noopener noreferrer"
            >
              learn more
            </Link>{' '}
            about feedback collection options.
          </Text>
          <FormControl isInvalid={Boolean(errors.name)}>
            <FormLabel fontSize={15} htmlFor="name">
              Name
            </FormLabel>
            <Input
              id="name"
              placeholder="We will append the date to make finding the results easier"
              minH={10}
              alignItems="center"
              autoFocus
              {...register('name', rules.name)}
            />
            <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
          </FormControl>
          <HStack spacing={4}>
            <FormControl>
              <HStack>
                <FormLabel htmlFor="feedbackSource">Feedback Channel</FormLabel>
                <InfoTooltip
                  ml={-4}
                  mb={1}
                  maxW={300}
                  size={14}
                  text="Choose the channel you want to collect feedback from. You can configure channels in the Integrations tab."
                />
              </HStack>
              <Select
                id="feedbackSource"
                defaultValue="EMAIL"
                {...register('feedbackCollectionType')}
              >
                <option value="EMAIL">Email</option>
                <option value="SLACK" disabled={!slack}>
                  Slack
                </option>
                <option value="TEAMS" disabled>
                  Microsoft Teams
                </option>
              </Select>
            </FormControl>
            <FormControl>
              <HStack>
                <FormLabel htmlFor="feedbackSource">Type of Feedback</FormLabel>
                <InfoTooltip
                  ml={-4}
                  mb={1}
                  maxW={300}
                  size={14}
                  text="Tags the feedback with the type of feedback so you can filter and analyze it later."
                />
              </HStack>
              <Select
                id="feedbackType"
                defaultValue=""
                {...register('feedbackType')}
              >
                <option value="CHECK_IN">Check-in</option>
                <option value="STANDUP">Standup</option>
                <option value="RELEASE">Release</option>
                <option value="RETROSPECTIVE">Retrospective</option>
                <option value="POST_MORTEM">Post Mortem</option>
                <option value="SURVEY">Survey</option>
              </Select>
            </FormControl>
          </HStack>
          {(feedbackCollectionType === 'SLACK' ||
            feedbackCollectionType === 'TEAMS') && (
            <HStack spacing={2} mt={3}>
              <Text fontSize={12} color="gray.500">
                (We will fall back to email for team members not mapped to{' '}
                {feedbackCollectionType.toLowerCase().charAt(0).toUpperCase() +
                  feedbackCollectionType.toLowerCase().slice(1)}
                )
              </Text>
            </HStack>
          )}
          <Text fontSize="15" fontWeight="500">
            What questions to ask?
          </Text>
          <Box
            borderWidth={1}
            borderRadius="lg"
            borderColor="gray.200"
            mt={4}
            p={4}
            maxHeight={
              defineYourOwnQuestionsDisclosure.isOpen ? 'auto' : '50px'
            }
            transition="max-height 0.2s ease-out"
            overflow="hidden"
          >
            <Flex cursor="pointer" gap={1}>
              <Heading fontSize={14} fontWeight={500} color="gray.900">
                Define your questions
              </Heading>
              <Spacer />
              <Switch
                onChange={onDefineYourOwnQuestions}
                isChecked={defineYourOwnQuestionsDisclosure.isOpen}
              />
            </Flex>
            <AnimatePresence>
              {defineYourOwnQuestionsDisclosure.isOpen && (
                <MotionFlex
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  direction="column"
                >
                  <Text fontSize={14} fontWeight={400} color="gray.500" mt={2}>
                    Choose up to 10 questions to ask your team.
                  </Text>
                  <FormControl isInvalid={Boolean(errors.questions?.message)}>
                    <Box maxHeight="300px" overflowY="auto">
                      {fields.map((field, index) => (
                        <FormControl mt={2} key={field.id}>
                          <FormLabel htmlFor={`questions[${index}].question`}>
                            Question {index + 1}
                          </FormLabel>

                          <HStack justifyContent="space-between" mt={2}>
                            <InputGroup>
                              <Input
                                id={`questions[${index}].question`}
                                placeholder={`Enter question ${index + 1}`}
                                {...register(
                                  `questions.${index}.question` as const
                                )}
                              />
                              {fields.length > 1 && (
                                <InputRightElement>
                                  <Icon
                                    as={MdClose}
                                    cursor="pointer"
                                    onClick={() => remove(index)}
                                  />
                                </InputRightElement>
                              )}
                            </InputGroup>
                            {/* <Spacer />
                            <FormLabel htmlFor={`questions[${index}].optional`}>
                              Optional
                            </FormLabel>
                            <Switchp
                              id={`questions[${index}].optional`}
                              {...register(
                                `questions.${index}.isOptional` as const
                              )}
                            /> */}
                          </HStack>
                        </FormControl>
                      ))}
                    </Box>
                    <FormErrorMessage>
                      {errors?.questions?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <Button
                    mt={4}
                    variant="outlineGray"
                    onClick={() => append({ question: '', isOptional: false })}
                    isDisabled={fields.length >= 10}
                  >
                    Add Question
                  </Button>
                </MotionFlex>
              )}
            </AnimatePresence>
          </Box>
          <Box
            borderWidth={1}
            borderRadius="lg"
            borderColor="gray.200"
            mt={4}
            p={4}
            maxHeight={
              useRetrospectiveTemplateDisclosure.isOpen ? '256px' : '50px'
            }
            transition="max-height 0.2s ease-out"
            overflow="hidden"
          >
            <Flex cursor="pointer" gap={1}>
              <Heading fontSize={14} fontWeight={500} color="gray.900">
                Use Retrospective Template
              </Heading>
              <Spacer />
              <Switch
                onChange={onUseRetrospectiveTemplate}
                isChecked={useRetrospectiveTemplateDisclosure.isOpen}
              />
            </Flex>
            <AnimatePresence>
              {useRetrospectiveTemplateDisclosure.isOpen && (
                <MotionFlex
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <VStack spacing={4}>
                    <Text
                      fontSize={14}
                      fontWeight={400}
                      color="gray.500"
                      mt={2}
                      alignSelf="start"
                    >
                      Choose from our pre-built retrospective templates.
                    </Text>
                    <FormControl>
                      <FormLabel htmlFor="retrospectiveFormat">
                        Retrospective Format
                      </FormLabel>
                      <Select
                        id="retrospectiveFormat"
                        placeholder="Select a format"
                        value={selectedRetrospectiveFormat}
                        onChange={handleRetrospectiveFormatChange}
                        // {...register('retrospectiveFormatId')}
                      >
                        {retroFormats?.map((format: RetrospectiveFormat) => (
                          <Option key={format.slug} value={format.slug}>
                            {format.displayName}
                          </Option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        {errors.retrospectiveFormatId && 'Format is required'}
                      </FormErrorMessage>
                    </FormControl>
                    {selectedFormatColumns && (
                      <Text
                        fontSize={14}
                        fontWeight={400}
                        color="gray.600"
                        mt={2}
                        alignSelf="start"
                      >
                        Prompts:{' '}
                        {selectedFormatColumns
                          .filter(
                            (column): column is string =>
                              column !== null && column !== undefined
                          )
                          .map(
                            (
                              column: string,
                              index: number,
                              array: string[]
                            ) => (
                              <>
                                {column}
                                {index < array.length - 1 ? ', ' : ''}
                              </>
                            )
                          )}
                      </Text>
                    )}
                  </VStack>
                </MotionFlex>
              )}
            </AnimatePresence>
          </Box>

          <Text fontSize="15" fontWeight="500">
            When to collect feedback?
          </Text>
          <Box
            borderWidth={1}
            borderRadius="lg"
            borderColor="gray.200"
            mt={4}
            p={4}
          >
            <HStack mb={2}>
              <Text fontSize={14} fontWeight={400}>
                Recurring
              </Text>
              <InfoTooltip
                ml={-1}
                maxW={300}
                size={14}
                text="Tags the feedback with the type of feedback so you can filter and analyze it later."
              />
              <Spacer />
              <Switch
                onChange={onRecurringFeedbackCollection}
                isChecked={recurringFeedbackCollection}
              />
            </HStack>

            <HStack spacing={4}>
              {recurringFeedbackCollection ? (
                <FormControl isInvalid={Boolean(errors.rrule)} pb={6}>
                  <FormLabel htmlFor="frequencySelector">Frequency</FormLabel>
                  <Select
                    id="frequencySelector"
                    placeholder="Select frequency"
                    {...register('rrule', rules.rrule)}
                    // value={getValues('rrule')}
                  >
                    <Option value="FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR">
                      Every Weekday (Monday to Friday)
                    </Option>
                    <Option value="FREQ=WEEKLY;INTERVAL=2;BYDAY=TU,TH">
                      Bi-weekly on Tuesday and Thursday
                    </Option>
                    <Option value="FREQ=WEEKLY;BYDAY=TU">
                      Weekly on Tuesday
                    </Option>
                    <Option value="FREQ=WEEKLY;INTERVAL=2;BYDAY=TU">
                      Every other week on Tuesday
                    </Option>
                    <Option value="FREQ=MONTHLY;BYDAY=-1TU">
                      Monthly on last Tuesday
                    </Option>
                    <Option value="FREQ=MONTHLY;INTERVAL=3;BYDAY=-1TU">
                      Quarterly on Last Tuesday
                    </Option>
                    {/* <Option value="custom">Custom...</Option> */}
                  </Select>
                  <FormErrorMessage>{errors?.rrule?.message}</FormErrorMessage>
                </FormControl>
              ) : (
                <FormControl pb={6} isInvalid={Boolean(errors.oneTimeSchedule)}>
                  <FormLabel htmlFor="dueDate">
                    Feedback Collection Date
                  </FormLabel>
                  <DatePickerPopover
                    futureOnly
                    value={oneTimeSchedule}
                    onChange={(newDate) => {
                      setValue('oneTimeSchedule', newDate as Date);
                    }}
                  >
                    <Button
                      id="dueDate"
                      variant="outlineGray"
                      leftIcon={<Icon as={AiTwotoneCalendar} />}
                      justifyContent="start"
                      w="full"
                    >
                      {oneTimeSchedule?.toLocaleDateString()}
                      <Spacer />
                      <Icon as={BsChevronDown} />
                    </Button>
                  </DatePickerPopover>
                  <FormErrorMessage>
                    {errors.oneTimeSchedule && 'Date is required'}
                  </FormErrorMessage>
                </FormControl>
              )}
              <FormControl isInvalid={Boolean(errors.collectionTimeMs)}>
                <FormLabel htmlFor="timeSelector">Time of day</FormLabel>

                <TimeInput
                  value={getValues('collectionTimeMs')}
                  onChange={(value) => {
                    setValue('collectionTimeMs', value ?? null);
                  }}
                />
                <FormErrorMessage>
                  {errors?.collectionTimeMs?.message}
                </FormErrorMessage>
              </FormControl>
            </HStack>
          </Box>

          <Flex ml="auto" mt={6} justifyContent="end" gap={2}>
            <Button
              size="lg"
              variant="outlineGray"
              type="submit"
              w="fit-content"
              onClick={onGoBack}
              isDisabled={isSubmitting}
            >
              Back
            </Button>
            <Button
              size="lg"
              w="fit-content"
              type="submit"
              isLoading={isSubmitting}
              onClick={() => handleSubmit(onSubmit)()}
            >
              {config ? 'Update' : 'Create'}
            </Button>
          </Flex>
        </VStack>
      </ModalBody>
    </ModalContent>
  );
};

export const AsyncFeedbackConfigModal: FC<AsyncFeedbackConfigModalProps> = (
  props
) => (
  <Modal isOpen={props.isOpen} onClose={props.onClose}>
    <ModalOverlay />
    <AsyncFeedbackConfigModalContent {...props} />
  </Modal>
);
