import { useCallback } from 'react';
import { useToast, TOAST_ERROR_GENERIC, log } from '@spoke/common';
import { useCreateCommentForActivityFeedMutation } from '@spoke/graphql';

export const useAddCommentForActivityFeedHandler = () => {
  const [createComment] = useCreateCommentForActivityFeedMutation();
  const [toast] = useToast();

  const handleAddComment = useCallback(
    async (
      userId: string,
      activityId: string,
      text: string
    ): Promise<boolean> => {
      if (!activityId || !userId || !text) {
        log.error('Missing critical data to add comment for activity feed', {
          userId,
          activityId,
          text,
        });
        return false;
      }

      log.info('Adding comment for activity feed', { text });

      const { errors, data: result } = await createComment({
        variables: { activityId, text },
      });

      if (errors) {
        log.error('createCommentForActivityFeed call responded with errors', {
          errors,
          text,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return true;
    },
    [createComment, toast]
  );

  return [handleAddComment];
};
