import { ComponentProps, FC, useState } from 'react';
import { ITEM_ICON, renderImpactReportSummary } from '../../impactReportUtil';
import { useImpactReportIssueDrilldown } from '../../queries/useImpactReportIssueDrilldown';
import { IssuesOverTimeChart } from '../IssuesOverTimeChart';
import { SeverityBadge } from '../SeverityBadge';
import {
  ImpactReportQueryHookResult,
  TeamType,
  ImpactReportIssueDrilldown,
} from '@spoke/graphql';
import {
  Button,
  capitalizeFirstLetter,
  Circle,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Spacer,
  TeamIcon,
  Text,
  truncateText,
  useDisclosure,
  Skeleton,
  SkeletonText,
  SkeletonCircle,
} from '@spoke/common';

import { CreateGoalWizardModal } from '@spoke/improvement-goals';

type Props = Omit<ModalProps, 'children'> & {
  issueId: string;
  teamId: string;
  teamType: TeamType;
  showMonetaryValues: boolean;
};

export const ImpactReportIssueModalContent: FC<Props> = ({
  issueId,
  teamId,
  teamType,
  showMonetaryValues,
}) => {
  const [showImpactedTeams, setShowImpactedTeams] = useState(false);
  const [selectedCauseIdx, setSelectedCauseIdx] = useState(0);
  const createImprovementGoalModal = useDisclosure();

  const [data, { loading }] = useImpactReportIssueDrilldown({
    variables: { issueId, teamId },
  });

  return (
    <ModalContent p={8} maxW={700}>
      <ModalCloseButton />
      <ModalHeader p={0} mb={4}>
        <HStack mb={1}>
          <SkeletonCircle size="12px" isLoaded={!loading}>
            {data && <Circle bg={data.color} size="12px" />}
          </SkeletonCircle>
          <Skeleton isLoaded={!loading}>
            <Heading fontSize={24} color="gray.900">
              {data?.title || 'Loading...'}
            </Heading>
          </Skeleton>
        </HStack>
        <Skeleton isLoaded={!loading}>
          <Flex>
            {data && (
              <Icon
                as={ITEM_ICON[data.type]?.icon}
                w={ITEM_ICON[data.type]?.w}
                h={ITEM_ICON[data.type]?.h}
                color={ITEM_ICON[data.type]?.color}
                mr={1}
                pt="2px"
              />
            )}
            <Text fontWeight={400} fontSize={15} color="gray.500">
              {data ? capitalizeFirstLetter(data.type) : 'Loading...'}
            </Text>
          </Flex>
        </Skeleton>
      </ModalHeader>
      <ModalBody p={0}>
        <Flex flexDir="column" alignItems="start" justifyContent="start">
          <Text
            textTransform="uppercase"
            color="gray.700"
            fontWeight={500}
            fontSize={13}
            mt={2}
            mb={1}
          >
            What&apos;s happening
          </Text>
          <SkeletonText isLoaded={!loading} noOfLines={3}>
            <Text color="gray.600" fontSize={16}>
              {data ? renderImpactReportSummary(data.summary) : ''}
            </Text>
          </SkeletonText>
          <Skeleton isLoaded={!loading} mt={4}>
            <Text color="gray.500" fontSize={15}>
              TOPICS: {data?.keywords.join(', ') || ''}
            </Text>
          </Skeleton>
          <HStack mt={2} w="full">
            <Flex flex={1} flexDir="column">
              <Text
                textTransform="uppercase"
                color="gray.700"
                fontWeight={500}
                fontSize={13}
                mt={4}
                mb={1}
              >
                Occurrences
              </Text>
              <Skeleton isLoaded={!loading}>
                <Text fontWeight={500} color="gray.700">
                  {data?.recurrenceData.length || 0}
                </Text>
              </Skeleton>
            </Flex>
            <Flex flex={1} flexDir="column">
              <Text
                textTransform="uppercase"
                color="gray.700"
                fontWeight={500}
                fontSize={13}
                mt={4}
                mb={1}
              >
                Est. {data?.nature === 'issue' ? 'Issue' : 'Win'} Duration
              </Text>
              <Skeleton isLoaded={!loading}>
                <Text fontWeight={500} color="gray.700">
                  {data && data.recurrenceData.length > 0
                    ? `${Math.round(
                        (Math.max(
                          ...data.recurrenceData.map((occurrence) =>
                            new Date(occurrence.date).getTime()
                          )
                        ) -
                          Math.min(
                            ...data.recurrenceData.map((occurrence) =>
                              new Date(occurrence.date).getTime()
                            )
                          )) /
                          (1000 * 60 * 60 * 24)
                      )} days`
                    : 'N/A'}
                </Text>
              </Skeleton>
            </Flex>
            <Flex flex={1} flexDir="column">
              <Text
                textTransform="uppercase"
                color="gray.700"
                fontWeight={500}
                fontSize={13}
                mt={4}
                mb={1}
              >
                Last Identified
              </Text>
              <Skeleton isLoaded={!loading}>
                <Text fontWeight={500} color="gray.700">
                  {data && data.recurrenceData.length > 0
                    ? `${Math.round(
                        (Date.now() -
                          Math.max(
                            ...data.recurrenceData.map((occurrence) =>
                              new Date(occurrence.date).getTime()
                            )
                          )) /
                          (1000 * 60 * 60 * 24)
                      )} days ago`
                    : 'N/A'}
                </Text>
              </Skeleton>
            </Flex>
          </HStack>

          <Flex
            mt={4}
            layerStyle="outlineGray"
            w="full"
            justifyContent="stretch"
            flexDir="column"
          >
            <Skeleton isLoaded={!loading} h={200}>
              {data && (
                <IssuesOverTimeChart
                  issues={[
                    {
                      id: data.id,
                      name: data.title,
                      color: data.color,
                      occurrences: data.recurrenceData.map((occurrence) => ({
                        date: new Date(occurrence.date).getTime(),
                        cardCount: occurrence.cardCount,
                        teamCount: occurrence.teamCount,
                      })),
                    },
                  ]}
                  variant="compact"
                />
              )}
            </Skeleton>
          </Flex>

          <Text
            textTransform="uppercase"
            color="gray.700"
            fontWeight={500}
            fontSize={13}
            mt={4}
            mb={1}
          >
            Impact size
          </Text>
          <Flex flexDir="column" layerStyle="outlineGray" w="full">
            <Flex justifyContent="space-between">
              {teamType === TeamType.Team ? (
                <Text color="gray.500">Other Teams Impacted</Text>
              ) : (
                <Text color="gray.500">Teams impacted</Text>
              )}
              <Button
                variant="link"
                cursor="pointer"
                onClick={() => setShowImpactedTeams(!showImpactedTeams)}
              >
                {showImpactedTeams ? 'Hide teams' : 'Show teams'}
              </Button>
            </Flex>
            <SeverityBadge
              w="fit-content"
              fontSize={16}
              iconSize={4}
              value={data?.impactedTeams.length || 0}
              severity={
                data?.impactedTeamsSeverity as ComponentProps<
                  typeof SeverityBadge
                >['severity']
              }
            />
            {showImpactedTeams && (
              <Flex
                flexDir="column"
                p={2}
                gap={2}
                maxHeight="250px"
                overflowY="auto"
              >
                <Divider my={2} />
                {data?.impactedTeams.map((team) => (
                  <HStack key={team.id} alignItems="center">
                    <Icon as={TeamIcon} w={7} h={7} color="primary.500" />
                    <Text fontSize={15} color="gray.700">
                      {truncateText(team.name, 100)}
                    </Text>
                    <Spacer />
                    <Text fontSize={15} color="gray.500">
                      {team.occurrenceCount} occurrences
                    </Text>
                  </HStack>
                ))}
              </Flex>
            )}
          </Flex>
        </Flex>
      </ModalBody>
      <CreateGoalWizardModal
        isOpen={createImprovementGoalModal.isOpen}
        onClose={createImprovementGoalModal.close}
      />
    </ModalContent>
  );
};

export const ImpactReportIssueModal: FC<Props> = (props) => (
  <Modal {...props}>
    <ModalOverlay />
    <ImpactReportIssueModalContent {...props} />
  </Modal>
);