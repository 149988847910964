import { PopoverAnchor } from '@chakra-ui/react';
import { FC, useRef, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useDisclosure, useOutsideClick } from '../hooks';
import { Box, BoxProps } from './Box';
import { DateRangePicker } from './DateRangePicker';
import { Popover, PopoverProps } from './Popover/Popover';
import { PopoverContent } from './Popover/PopoverContent';
import { Button } from './Button';

type DateRangePickerPopoverProps = {
  popoverProps?: PopoverProps;
  containerProps?: BoxProps;
  childrenContainerProps?: BoxProps;
  closeOnChange?: boolean;
  value?: DateRange | undefined;
  onChange: (range: DateRange | undefined) => void;
};

export const DateRangePickerPopover: FC<DateRangePickerPopoverProps> = ({
  popoverProps,
  containerProps,
  childrenContainerProps,
  children,
  closeOnChange,
  onChange,
  value,
  ...rest
}) => {
  const disclosure = useDisclosure();
  const contentRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  useOutsideClick(disclosure.close, contentRef, triggerRef);

  const [tempRange, setTempRange] = useState<DateRange | undefined>(value);

  const handleChange = (newRange: DateRange | null) => {
    setTempRange(newRange ?? undefined);
  };

  const handleApply = () => {
    if (tempRange) {
      onChange(tempRange);
      disclosure.close();
    }
  };

  const handleCancel = () => {
    setTempRange(value);
    disclosure.close();
  };

  return (
    <Popover
      closeOnBlur={false}
      isOpen={disclosure.isOpen}
      onClose={handleCancel}
      {...(popoverProps ?? {})}
    >
      <PopoverAnchor>
        <Box
          ref={triggerRef}
          onClick={disclosure.toggle}
          {...(childrenContainerProps ?? {})}
        >
          {children}
        </Box>
      </PopoverAnchor>
      <PopoverContent w="full">
        <Box flex="1" ref={contentRef} {...containerProps}>
          <DateRangePicker value={tempRange} onChange={handleChange} />
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            mt={2}
            p={2}
          >
            <Button
              variant="outlineGray"
              size="sm"
              fontSize="sm"
              mr={2}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              size="sm"
              fontSize="sm"
              onClick={handleApply}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </PopoverContent>
    </Popover>
  );
};
