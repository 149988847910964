import { useState } from 'react';
import { DateRange } from 'react-day-picker';
import { AnalyticsFilterButton } from './AnalyticsFilterButton';
import { TeamActionsButton } from './TeamActionsButton';
import {
  getIconByTeamType,
  useCurrentOrgLockdown,
  useCurrentTeam,
  InviteTeamMemberModal,
} from '@spoke/user';
import {
  Center,
  Flex,
  Heading,
  HStack,
  Spacer,
  TeamIcon,
  VStack,
  useDisclosure,
  Text,
} from '@spoke/common';
import { TeamType } from '@spoke/graphql';
import { ActivityFeedModal } from '@spoke/activity-feed';
import { CreateRetroButton } from '@spoke/board';

interface ImpactReportHeaderProps {
  teamType: TeamType;
  showMonetaryValues: boolean;
  onShowMonetaryValuesChange: (value: boolean) => void;
  onApplyFilters: (
    startDate: number,
    endDate: number,
    teamIds: string[],
    programTeamIds: string[]
  ) => void;
}

export const ImpactReportHeader = ({
  teamType,
  showMonetaryValues,
  onShowMonetaryValuesChange,
  onApplyFilters,
}: ImpactReportHeaderProps) => {
  const [isOrgLocked, isTrialExpired, isOrgFree] = useCurrentOrgLockdown();
  const [team] = useCurrentTeam();
  const teamTypeIcon = getIconByTeamType(teamType || TeamType.Team, 1.5);

  const activityFeedDisclosure = useDisclosure();
  const inviteModalDisclosure = useDisclosure();

  const getInitialDateRange = () => {
    const today = new Date();
    const threeMonthsAgo = new Date(today);
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
    return { from: threeMonthsAgo, to: today };
  };

  const initialDateRange = getInitialDateRange();

  const formatDateRange = (range: DateRange) => {
    const fromDate = range.from?.toLocaleDateString();
    const toDate = range.to?.toLocaleDateString();
    return `${fromDate} - ${toDate}`;
  };

  const [teamsCount, setTeamsCount] = useState(0);
  const [programsCount, setProgramsCount] = useState(0);
  const [effectiveDateRangeState, setEffectiveDateRangeState] =
    useState(initialDateRange);

  const handleApplyFilters = (
    startDate: number,
    endDate: number,
    programTeamIds: string[],
    teamIds: string[]
  ) => {
    setTeamsCount(teamIds.length);
    setProgramsCount(programTeamIds.length);
    setEffectiveDateRangeState({
      from: new Date(startDate),
      to: new Date(endDate),
    });
    onApplyFilters(startDate, endDate, programTeamIds, teamIds);
  };

  const getFilterSummary = () => {
    const dateRange = formatDateRange(effectiveDateRangeState);
    let summary = 'Viewing ';

    if (teamsCount > 0) {
      summary += `${teamsCount} ${teamsCount === 1 ? 'team' : 'teams'}`;
    } else if (
      teamType === TeamType.Program ||
      teamType === TeamType.Organization
    ) {
      summary += 'all teams';
    }

    if (programsCount > 0) {
      summary += ` in ${programsCount} ${
        programsCount === 1 ? 'program' : 'programs'
      }`;
    }

    summary +=
      teamType === TeamType.Team ? ` ${dateRange}` : ` over ${dateRange}`;

    return summary;
  };
  return (
    <Flex direction="row" alignItems="center">
      <Center
        backgroundColor="primary.25"
        borderRadius={8}
        w={10}
        h={10}
        mr={3}
      >
        {teamTypeIcon}
      </Center>
      <VStack alignItems="flex-start" spacing={0}>
        <Heading
          fontWeight={500}
          size="md"
        >{`${team?.name}'s Dashboard`}</Heading>
        <Text color="gray.500" fontSize="sm">
          {getFilterSummary()}
        </Text>
      </VStack>
      <Spacer />
      <HStack spacing={4}>
        <TeamActionsButton
          isDisabled={isOrgLocked || isTrialExpired || isOrgFree}
          onActivityFeedClick={activityFeedDisclosure.open}
          onInviteClick={inviteModalDisclosure.open}
        />
        <AnalyticsFilterButton
          initialDateRange={initialDateRange}
          onApplyFilters={handleApplyFilters}
          isDisabled={isOrgLocked || isTrialExpired || isOrgFree}
        />
        <CreateRetroButton disabled={isOrgLocked} />
      </HStack>
      <ActivityFeedModal
        isOpen={activityFeedDisclosure.isOpen}
        onClose={activityFeedDisclosure.close}
      />
      <InviteTeamMemberModal
        isOpen={inviteModalDisclosure.isOpen}
        onClose={inviteModalDisclosure.close}
      />
    </Flex>
  );
};
