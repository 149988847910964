import React, { FC, useState, useMemo, useEffect, useCallback } from 'react';
import {
  Button,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  VStack,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import { FiFilter } from 'react-icons/fi';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { format } from 'date-fns';
import { DateRange } from 'react-day-picker';
import {
  TeamsIcon,
  MultiSelect,
  truncateWithCount,
  useCurrentUser,
  DateRangePicker,
  ProgramIcon,
} from '@spoke/common';
import { useCurrentTeam, useTeamTree, useTeamType } from '@spoke/user';
import { MinimalTeamFragmentFragment, TeamType } from '@spoke/graphql';

interface AnalyticsFilterButtonProps {
  initialDateRange?: DateRange;
  onApplyFilters: (
    startDate: number,
    endDate: number,
    programTeamIds: string[],
    teamIds: string[]
  ) => void;
  isDisabled?: boolean;
}

export const AnalyticsFilterButton: FC<AnalyticsFilterButtonProps> = ({
  initialDateRange,
  onApplyFilters,
  isDisabled,
}) => {
  const [{ teamTree }] = useTeamTree();
  const { isProgram, isOrg } = useTeamType();
  const [currentTeam] = useCurrentTeam();
  const [currentUser] = useCurrentUser();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const programsDropdown = useDisclosure();
  const teamsDropdown = useDisclosure();
  const [selectedChildrenTeams, setSelectedChildrenTeams] = useState<
    MinimalTeamFragmentFragment[]
  >([]);
  const [selectedChildrenPrograms, setSelectedChildrenPrograms] = useState<
    MinimalTeamFragmentFragment[]
  >([]);

  const [range, setRange] = useState<DateRange | undefined>(() => {
    if (initialDateRange) {
      return initialDateRange;
    } else {
      const today = new Date();
      const threeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        today.getDate()
      );
      return {
        from: threeMonthsAgo,
        to: today,
      };
    }
  });

  const [prevTeamId, setPrevTeamId] = useState<string | undefined>(
    currentTeam?.id
  );

  const { programOptions, teamOptions } = useMemo(() => {
    const filterTeamNodes = (nodeType: TeamType) =>
      teamTree
        ?.filter((treeNode) => treeNode.team.type === nodeType)
        .map((treeNode) => treeNode.team) ?? [];

    const filteredProgramOptions = filterTeamNodes(TeamType.Program);
    const filteredTeamOptions = isProgram
      ? currentTeam?.children ?? []
      : filterTeamNodes(TeamType.Team);

    return {
      programOptions: filteredProgramOptions,
      teamOptions: filteredTeamOptions,
    };
  }, [teamTree, isProgram, currentTeam]);

  const [teamsLabel, setTeamsLabel] = useState<string>('All teams');
  const [programsLabel, setProgramsLabel] = useState<string>('All programs');

  const getFilteredOptions = (
    options: MinimalTeamFragmentFragment[],
    term: string
  ): MinimalTeamFragmentFragment[] =>
    options.filter((option) =>
      option.name?.toLowerCase().includes(term.toLowerCase())
    );

  const handleSelect = (
    items: MinimalTeamFragmentFragment[],
    setter: React.Dispatch<React.SetStateAction<MinimalTeamFragmentFragment[]>>,
    onChange: (ids: string[]) => void,
    setLabel: React.Dispatch<React.SetStateAction<string>>,
    labelKey: string
  ) => {
    setter(items);
    onChange(items.map((item) => item.id));
    setLabel(truncateWithCount(items, 'name', 30) || `All ${labelKey}`);
  };

  const [tempSelectedChildrenTeams, setTempSelectedChildrenTeams] = useState<
    MinimalTeamFragmentFragment[]
  >(selectedChildrenTeams);
  const [tempSelectedChildrenPrograms, setTempSelectedChildrenPrograms] =
    useState<MinimalTeamFragmentFragment[]>(selectedChildrenPrograms);
  const [tempRange, setTempRange] = useState<DateRange | undefined>(range);

  const handleClearFilters = useCallback(() => {
    setTempSelectedChildrenTeams([]);
    setTempSelectedChildrenPrograms([]);
    setTempRange(initialDateRange);
    setTeamsLabel('All teams');
    setProgramsLabel('All programs');
  }, [initialDateRange]);

  const handleApplyFilters = () => {
    const programTeamIds = tempSelectedChildrenPrograms.map(
      (program) => program.id
    );
    const teamIds = tempSelectedChildrenTeams.map((team) => team.id);
    onApplyFilters(
      tempRange?.from?.getTime() ?? 0,
      tempRange?.to?.getTime() ?? 0,
      programTeamIds,
      teamIds
    );
    setTempRange(tempRange);
    setSelectedChildrenTeams(tempSelectedChildrenTeams);
    setSelectedChildrenPrograms(tempSelectedChildrenPrograms);
    onClose();
  };

  const handleTempDateRangeChange = (newRange: DateRange | null) => {
    setTempRange(newRange ?? undefined);
  };

  useEffect(() => {
    if (currentTeam?.id !== prevTeamId) {
      handleClearFilters();
      setPrevTeamId(currentTeam?.id);
    }
  }, [currentTeam?.id, prevTeamId, handleClearFilters]);

  return (
    <Popover isOpen={isOpen} onClose={onClose} placement="bottom-end">
      <PopoverTrigger>
        <Button
          onClick={onToggle}
          variant="outlineGray"
          leftIcon={<Icon as={FiFilter} />}
          isDisabled={isDisabled}
        />
      </PopoverTrigger>
      <PopoverContent width="auto">
        <PopoverBody>
          <VStack spacing={4} align="stretch">
            <Box width="100%">
              <DateRangePicker
                value={tempRange}
                onChange={handleTempDateRangeChange}
              />
            </Box>

            {isOrg && (
              <MultiSelect
                searchable
                values={tempSelectedChildrenPrograms}
                onChange={(programs) =>
                  handleSelect(
                    programs,
                    setTempSelectedChildrenPrograms,
                    () => {},
                    setProgramsLabel,
                    'programs'
                  )
                }
                onClose={programsDropdown.onClose}
                isOpen={programsDropdown.isOpen}
                getOptions={(term) => getFilteredOptions(programOptions, term)}
                idKey="id"
                labelKeyOrFn="name"
              >
                <Button
                  variant="outlineGray"
                  leftIcon={<Icon as={ProgramIcon} w={5} h={5} />}
                  rightIcon={<Icon as={BsChevronDown} />}
                  width="100%"
                  justifyContent="space-between"
                  isDisabled={tempSelectedChildrenTeams.length > 0}
                  onClick={programsDropdown.onToggle}
                >
                  {programsLabel}
                </Button>
              </MultiSelect>
            )}

            {(isOrg || isProgram) && (
              <MultiSelect
                searchable
                values={tempSelectedChildrenTeams}
                onChange={(teams) =>
                  handleSelect(
                    teams,
                    setTempSelectedChildrenTeams,
                    () => {},
                    setTeamsLabel,
                    'teams'
                  )
                }
                onClose={teamsDropdown.onClose}
                isOpen={teamsDropdown.isOpen}
                getOptions={(term) => getFilteredOptions(teamOptions, term)}
                idKey="id"
                labelKeyOrFn="name"
              >
                <Button
                  variant="outlineGray"
                  leftIcon={<Icon as={TeamsIcon} w={5} h={5} />}
                  rightIcon={<Icon as={BsChevronDown} />}
                  width="100%"
                  justifyContent="space-between"
                  isDisabled={tempSelectedChildrenPrograms.length > 0}
                  onClick={teamsDropdown.onToggle}
                >
                  {teamsLabel}
                </Button>
              </MultiSelect>
            )}

            <Box width="100%" display="flex" justifyContent="space-between">
              <Button
                variant="outlineGray"
                size="sm"
                fontSize="sm"
                onClick={handleClearFilters}
              >
                Clear Filters
              </Button>
              <Button
                variant="solid"
                size="sm"
                fontSize="sm"
                onClick={handleApplyFilters}
              >
                Apply Filters
              </Button>
            </Box>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
