import React from 'react';
import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
} from '@chakra-ui/react';
import { BsThreeDots, BsChevronUp, BsChevronDown } from 'react-icons/bs';
import { TbActivityHeartbeat } from 'react-icons/tb';
import { FiLink } from 'react-icons/fi';

interface TeamActionsButtonProps {
  isDisabled: boolean;
  onActivityFeedClick: () => void;
  onInviteClick: () => void;
}

export const TeamActionsButton: React.FC<TeamActionsButtonProps> = ({
  isDisabled,
  onActivityFeedClick,
  onInviteClick,
}) => {
  const { isOpen, onClose, onToggle } = useDisclosure();

  return (
    <Menu isOpen={isOpen} onClose={onClose}>
      {({ isOpen: isOpenMenu }) => (
        <>
          <MenuButton
            as={Button}
            onClick={onToggle}
            variant="outlineGray"
            isDisabled={isDisabled}
          >
            <Icon as={BsThreeDots} w={5} h={5} color="gray.600" mt={1} />
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => {
                onInviteClick();
                onClose();
              }}
              display="flex"
              alignItems="center"
              color="gray.600"
            >
              <Icon as={FiLink} w={5} h={5} color="gray.600" mr={2} />
              Invite
            </MenuItem>
            <MenuItem
              onClick={() => {
                onActivityFeedClick();
                onClose();
              }}
              display="flex"
              alignItems="center"
              color="gray.600"
            >
              <Icon
                as={TbActivityHeartbeat}
                w={5}
                h={5}
                color="gray.600"
                mr={2}
              />
              Activity Feed
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};
