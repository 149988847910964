import React, { useState } from 'react';
import {
  Box,
  Flex,
  HStack,
  Heading,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import { InfoTooltip, useAssets } from '@spoke/common';
import {
  ImpactReportSummaryCard,
  ImpactReportGoalsHookData,
  ImpactReportIssueModal,
} from '@spoke/impact-report';
import { TeamType } from '@spoke/graphql';

interface FeedbackIssuesProps {
  cards: ImpactReportGoalsHookData['cards'][0][];
  currentTeam: { type: TeamType; id: string };
  hideSummary?: boolean;
}

const FeedbackIssues: React.FC<FeedbackIssuesProps> = ({
  cards,
  currentTeam,
  hideSummary = false,
}) => {
  const [selectedIssueId, setSelectedIssueId] = useState<string | null>(null);
  const { illustrationYellowPage } = useAssets();

  return (
    <Box borderWidth={1} borderRadius="lg" borderColor="gray.200" my={4} p={4}>
      <VStack alignItems="stretch" spacing={1}>
        <HStack mb={4}>
          <Heading as="h3" size="md" mb={1}>
            Issues Found
          </Heading>
          <InfoTooltip
            maxW={300}
            size={14}
            text="These are current issues that we've detected in feedback at least once in the past 30 days."
          />
        </HStack>
        {cards?.[0]?.items?.length === 0 ? (
          <VStack w="full">
            <VStack textAlign="center" px={4} spacing={4}>
              <Flex direction="column" alignItems="center">
                <Image
                  src={illustrationYellowPage}
                  alt=" No issues found in this feedback"
                  w={200}
                  mb={5}
                />
                <Heading size="md" color="gray.700" fontWeight={500} mb={2}>
                  All clear!
                </Heading>
                <Text color="gray.500" fontSize={14}>
                  Issues are identified from team feedback, highlighting key
                  problems and opportunities for improvement.
                </Text>
              </Flex>
            </VStack>
          </VStack>
        ) : (
          cards.map((card, idx, arr) => {
            if (idx > 0) {
              return null;
            }
            return (
              currentTeam && (
                <ImpactReportSummaryCard
                  showMonetaryValues={false}
                  hideSummary={hideSummary}
                  onIssueClick={setSelectedIssueId}
                  teamType={currentTeam.type}
                  card={card}
                  key={card.title}
                  limit={5}
                  width="full"
                />
              )
            );
          })
        )}
      </VStack>
      <ImpactReportIssueModal
        isOpen={!!selectedIssueId}
        teamType={currentTeam?.type || TeamType.Team}
        onClose={() => setSelectedIssueId(null)}
        issueId={selectedIssueId ?? ''}
        teamId={currentTeam?.id ?? ''}
        showMonetaryValues={false}
      />
    </Box>
  );
};

export { FeedbackIssues };
