import { FC } from 'react';
import { IoLinkSharp } from 'react-icons/io5';
import { InviteTeamMemberModal } from './InviteTeamMemberModal';
import { ButtonProps, useDisclosure, Button, Icon } from '@spoke/common';

interface InviteToTeamButtonProps extends ButtonProps {
  iconOnly?: boolean;
}

export const InviteToTeamButton: FC<InviteToTeamButtonProps> = ({
  iconOnly = false,
  ...rest
}) => {
  const inviteToTeamModal = useDisclosure();

  return (
    <>
      <Button
        id="invite-to-team-btn"
        variant="outlineGray"
        leftIcon={<Icon as={IoLinkSharp} color="gray.600" />}
        minW={iconOnly ? undefined : 24}
        onClick={inviteToTeamModal.open}
        {...rest}
      >
        {iconOnly ? null : 'Invite'}
      </Button>
      <InviteTeamMemberModal
        isOpen={inviteToTeamModal.isOpen}
        onClose={inviteToTeamModal.close}
      />
    </>
  );
};
