import React, { createContext, FC, useContext } from 'react';
import { SpokeAuthView } from '../../types';

export enum SpkRouteKey {
  // Common
  Root = 'Root',
  Auth = 'Auth',
  ActionItems = 'ActionItems',
  TeamPulse = 'TeamPulse',
  TeamSettings = 'TeamSettings',
  CreateBoard = 'CreateBoard',
  Board = 'Board',

  // =========
  // Web only
  Logout = 'Logout',
  AuthView = 'AuthView',
  AuthBridge = 'AuthBridge',

  Users = 'Users',
  Billing = 'Billing',
  OrgTeams = 'OrgTeams',
  OrgSettings = 'OrgSettings',
  UserSettings = 'UserSettings',
  Dashboard = 'Dashboard',
  Retrospectives = 'Retrospectives',
  ParkingLot = 'ParkingLot',
  TeamMembers = 'TeamMembers',
  ProgramTeams = 'ProgramTeams',
  Whiteboard = 'Whiteboard',

  Integrations = 'Integrations',
  SetupGithub = 'SetupGithub',
  SetupBitbucket = 'SetupBitbucket',
  Metrics = 'Metrics',

  ImpactReport = 'ImpactReport',

  ImprovementGoalDrilldown = 'ImprovementGoalDrilldown',
  TeamDigestReport = 'TeamDigestReport',
  ImprovementGoals = 'ImprovementGoals',
  Reports = 'Reports',

  Onboarding = 'Onboarding',
  OnboardingCreateTeam = 'OnboardingCreateTeam',
  OnboardingSurvey = 'OnboardingSurvey',
  GetStarted = 'GetStarted',
  GetStartedQuestions = 'GetStartedQuestions',
  GetStartedBookOnboarding = 'GetStartedBookOnboarding',
  GetStartedWeclome = 'GetStartedWelcome',
  GetStartedRetro = 'GetStartedRetro',
  GetStartedIntegrations = 'GetStartedIntegrations',

  Invitation = 'Invitation',
  InvitationBoard = 'InvitationBoard',
  InvitationBoardView = 'InvitationBoardView',
  InvitationTeam = 'InvitationTeam',
  InvitationTeamView = 'InvitationTeamView',
  LegacyInvitationTeam = 'LegacyInvitationTeam',
}

export type SpkRoutes = {
  [SpkRouteKey.Root]: string;
  [SpkRouteKey.Auth]: string;
  [SpkRouteKey.ActionItems]: string;
  [SpkRouteKey.TeamPulse]: string;
  [SpkRouteKey.TeamSettings]: string;
  [SpkRouteKey.CreateBoard]: string;
  [SpkRouteKey.Logout]: string;
  [SpkRouteKey.Users]: string;
  [SpkRouteKey.Billing]: string;
  [SpkRouteKey.OrgTeams]: string;
  [SpkRouteKey.OrgSettings]: string;
  [SpkRouteKey.UserSettings]: string;
  [SpkRouteKey.Dashboard]: string;
  [SpkRouteKey.Retrospectives]: string;
  [SpkRouteKey.ParkingLot]: string;
  [SpkRouteKey.TeamMembers]: string;
  [SpkRouteKey.ProgramTeams]: string;
  [SpkRouteKey.Integrations]: string;
  [SpkRouteKey.SetupGithub]: string;
  [SpkRouteKey.SetupBitbucket]: string;
  [SpkRouteKey.Metrics]: string;
  [SpkRouteKey.ImprovementGoals]: string;
  [SpkRouteKey.Onboarding]: string;
  [SpkRouteKey.OnboardingCreateTeam]: string;
  [SpkRouteKey.OnboardingSurvey]: string;
  [SpkRouteKey.GetStarted]: string;
  [SpkRouteKey.GetStartedQuestions]: string;
  [SpkRouteKey.GetStartedBookOnboarding]: string;
  [SpkRouteKey.GetStartedWeclome]: string;
  [SpkRouteKey.GetStartedRetro]: string;
  [SpkRouteKey.GetStartedIntegrations]: string;
  [SpkRouteKey.Invitation]: string;
  [SpkRouteKey.ImpactReport]: string;
  [SpkRouteKey.Reports]: string;
  [SpkRouteKey.Board]: (boardId?: string) => string;
  [SpkRouteKey.AuthView]: (authView?: SpokeAuthView) => string;
  [SpkRouteKey.AuthBridge]: (to?: string, otp?: string) => string;
  [SpkRouteKey.Whiteboard]: (boardId?: string) => string;
  [SpkRouteKey.ImprovementGoalDrilldown]: (goalId?: string) => string;
  [SpkRouteKey.TeamDigestReport]: (reportId?: string) => string;
  [SpkRouteKey.InvitationBoard]: (id?: string) => string;
  [SpkRouteKey.InvitationTeam]: (token?: string) => string;
  [SpkRouteKey.LegacyInvitationTeam]: (token?: string) => string;

  [SpkRouteKey.InvitationBoardView]: (
    boardId?: string,
    view?: SpokeAuthView
  ) => string;
  [SpkRouteKey.InvitationTeamView]: (
    token?: string,
    authView?: SpokeAuthView
  ) => string;
};

export const UNUSED_ROUTE = '';

/**
 * Initial state for React Context. Will be replaced at runtime.
 */
export const EMPTY_ROUTES: SpkRoutes = {
  [SpkRouteKey.Root]: UNUSED_ROUTE,
  [SpkRouteKey.Auth]: UNUSED_ROUTE,
  [SpkRouteKey.ActionItems]: UNUSED_ROUTE,
  [SpkRouteKey.TeamPulse]: UNUSED_ROUTE,
  [SpkRouteKey.TeamSettings]: UNUSED_ROUTE,
  [SpkRouteKey.CreateBoard]: UNUSED_ROUTE,
  [SpkRouteKey.Logout]: UNUSED_ROUTE,
  [SpkRouteKey.Users]: UNUSED_ROUTE,
  [SpkRouteKey.Billing]: UNUSED_ROUTE,
  [SpkRouteKey.OrgTeams]: UNUSED_ROUTE,
  [SpkRouteKey.OrgSettings]: UNUSED_ROUTE,
  [SpkRouteKey.UserSettings]: UNUSED_ROUTE,
  [SpkRouteKey.Dashboard]: UNUSED_ROUTE,
  [SpkRouteKey.Retrospectives]: UNUSED_ROUTE,
  [SpkRouteKey.ParkingLot]: UNUSED_ROUTE,
  [SpkRouteKey.TeamMembers]: UNUSED_ROUTE,
  [SpkRouteKey.ProgramTeams]: UNUSED_ROUTE,
  [SpkRouteKey.Integrations]: UNUSED_ROUTE,
  [SpkRouteKey.SetupGithub]: UNUSED_ROUTE,
  [SpkRouteKey.SetupBitbucket]: UNUSED_ROUTE,
  [SpkRouteKey.Metrics]: UNUSED_ROUTE,
  [SpkRouteKey.ImprovementGoals]: UNUSED_ROUTE,
  [SpkRouteKey.Onboarding]: UNUSED_ROUTE,
  [SpkRouteKey.OnboardingCreateTeam]: UNUSED_ROUTE,
  [SpkRouteKey.OnboardingSurvey]: UNUSED_ROUTE,
  [SpkRouteKey.GetStarted]: UNUSED_ROUTE,
  [SpkRouteKey.GetStartedQuestions]: UNUSED_ROUTE,
  [SpkRouteKey.GetStartedBookOnboarding]: UNUSED_ROUTE,
  [SpkRouteKey.GetStartedWeclome]: UNUSED_ROUTE,
  [SpkRouteKey.GetStartedRetro]: UNUSED_ROUTE,
  [SpkRouteKey.GetStartedIntegrations]: UNUSED_ROUTE,
  [SpkRouteKey.Invitation]: UNUSED_ROUTE,
  [SpkRouteKey.ImpactReport]: UNUSED_ROUTE,
  [SpkRouteKey.Reports]: UNUSED_ROUTE,
  [SpkRouteKey.InvitationBoard]: () => UNUSED_ROUTE,
  [SpkRouteKey.InvitationBoardView]: () => UNUSED_ROUTE,
  [SpkRouteKey.InvitationTeam]: () => UNUSED_ROUTE,
  [SpkRouteKey.InvitationTeamView]: () => UNUSED_ROUTE,
  [SpkRouteKey.LegacyInvitationTeam]: () => UNUSED_ROUTE,
  [SpkRouteKey.Board]: () => UNUSED_ROUTE,
  [SpkRouteKey.AuthView]: () => UNUSED_ROUTE,
  [SpkRouteKey.AuthBridge]: () => UNUSED_ROUTE,
  [SpkRouteKey.Whiteboard]: () => UNUSED_ROUTE,
  [SpkRouteKey.ImprovementGoalDrilldown]: () => UNUSED_ROUTE,
  [SpkRouteKey.TeamDigestReport]: () => UNUSED_ROUTE,
};

const RouteContext = createContext<SpkRoutes>(EMPTY_ROUTES);

export const useRoutes = () => useContext(RouteContext);

export const RoutesProvider: FC<{ routes: SpkRoutes }> = ({
  children,
  routes,
}) => <RouteContext.Provider value={routes}>{children}</RouteContext.Provider>;

export const getRouteKeyByRoute = (
  route: string,
  routes: SpkRoutes
): SpkRouteKey => {
  const routeKey = Object.entries(routes).find(
    ([_, value]) => value === route
  )?.[0];
  if (!routeKey) return SpkRouteKey.Root;
  return routeKey as SpkRouteKey;
};
