import { FC } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import {
  SpkTime,
  SpkLink,
  SlideFade,
  Flex,
  BlinkingDot,
  truncateText,
  Icon,
  Text,
  useRoutes,
} from '@spoke/common';

const TWENTY_MINUTES = 20 * 60 * 1000;

type LatestRetroShortcutProps = {
  boardId: string;
  boardName: string;
  boardUpdatedAt: string;
  isAsyncFeedback?: boolean;
};
export const LatestRetroShortcut: FC<LatestRetroShortcutProps> = ({
  boardId,
  boardName,
  boardUpdatedAt,
  isAsyncFeedback = false,
}) => {
  const routes = useRoutes();
  const isCurrentRetro =
    SpkTime.getAbsoluteMsFromNow(boardUpdatedAt) < TWENTY_MINUTES;

  return (
    <SpkLink
      href={`${routes.Board(boardId)}${
        isAsyncFeedback ? '?viewSummary=true' : ''
      }`}
      passHref
    >
      <SlideFade
        offsetY="-20px"
        in
        transition={{ enter: { duration: 0.5 }, exit: { duration: 0.5 } }}
      >
        <Flex
          _hover={{ bg: 'gray.75' }}
          transition="background-color 0.1s ease-out"
          background="white"
          alignItems="center"
          layerStyle="outlineGray"
          borderRadius="md"
          cursor="pointer"
          borderColor="gray.300"
          as="a"
          p={2}
          py={1}
        >
          <Flex flexDir="column">
            <Flex alignItems="center">
              {isCurrentRetro && (
                <BlinkingDot color="green.500" mt="3px" mr="6px" />
              )}
              <Text
                fontSize={12}
                fontWeight={500}
                color="gray.500"
                pt="2px"
                mb="-4px"
              >
                {isCurrentRetro ? 'Current feedback' : 'Latest feedback'}
              </Text>
            </Flex>
            <Text fontSize={13} fontWeight={500} color="gray.70">
              {truncateText(boardName, 24)}
            </Text>
          </Flex>
          <Icon as={FiArrowRight} color="gray.500" ml={2} w={5} h={5} />
        </Flex>
      </SlideFade>
    </SpkLink>
  );
};
