import { FC } from 'react';
import {
  Box,
  Flex,
  Text,
  Badge,
  Icon,
  useColorModeValue,
  Link,
} from '@chakra-ui/react';
import {
  ArrowUpIcon,
  ArrowDownIcon,
  AlertCircleIcon,
  TargetIcon,
  ChevronRightIcon,
} from 'lucide-react';
import { useRouter } from 'next/router';
import { MdTrendingFlat } from 'react-icons/md';
import { BiTrendingDown, BiTrendingUp } from 'react-icons/bi';
import { ImprovementGoalsHookData } from '../queries';
import { InfoTooltip, Tooltip } from '@spoke/common';
import {
  ImprovementGoal,
  ImprovementGoalsByTeamIdsQuery,
  ImprovementGoalTypeTargetType,
} from '@spoke/graphql';

interface GoalCardProps {
  goal: ImprovementGoalsByTeamIdsQuery['improvementGoalsByTeamIds']['improvementGoals'][0];
  highIssueThreshold?: number;
}

export const ImprovementGoalSlimCard: FC<GoalCardProps> = ({
  goal,
  highIssueThreshold,
}) => {
  const router = useRouter();
  const formatValue = (value: number | undefined) =>
    value !== undefined ? value.toFixed(0) : 'N/A';
  const isAboveTarget =
    goal.data?.[goal.data.length - 1]?.value !== undefined &&
    goal.target !== undefined
      ? goal.data[goal.data.length - 1].value <= goal.target
      : false;
  const hasHighIssues =
    goal.actionsTakenCount !== undefined && highIssueThreshold !== undefined
      ? goal.actionsTakenCount >= highIssueThreshold
      : false;
  const changePercentage =
    goal.data?.length > 1
      ? ((goal.data[goal.data.length - 1].value -
          goal.data[goal.data.length - 2].value) /
          goal.data[goal.data.length - 2].value) *
        100
      : 0;
  const isPositiveChange = changePercentage >= 0;
  const hasNotChanged =
    !goal.data ||
    goal.data.length === 0 ||
    goal.data.length === 1 ||
    (goal.data.length > 1 &&
      goal.data[goal.data.length - 1].value ===
        goal.data[goal.data.length - 2].value);

  const getStatusColor = (): string => {
    const isGoodAbove =
      goal.type.targetType === ImprovementGoalTypeTargetType.Above;
    const currentValue = goal.data?.[goal.data.length - 1]?.value;
    if (currentValue !== undefined && goal.target !== undefined) {
      const difference = Math.abs(currentValue - goal.target);
      const percentageDifference = (difference / goal.target) * 100;
      if (percentageDifference <= 25) return 'yellow.500';

      const currentIsAboveTarget = currentValue > goal.target;
      if (
        (isGoodAbove && !currentIsAboveTarget) ||
        (!isGoodAbove && currentIsAboveTarget)
      ) {
        return 'red.500';
      }
    }
    return 'green.500';
  };

  const handleGoalClick = () => {
    router.push(`/team-goals/${goal.id}`);
  };

  const getChangeIcon = () => {
    if (hasNotChanged) return MdTrendingFlat;
    return isPositiveChange ? BiTrendingUp : BiTrendingDown;
  };

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      width="calc(20% - 16px)"
      minWidth="175px"
      height="auto"
      border="1px solid"
      borderColor="gray.200"
      p={4}
      bg={useColorModeValue('white', 'gray.800')}
      borderRadius="8px"
    >
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Flex alignItems="center" cursor="pointer" onClick={handleGoalClick}>
          <Text fontSize="sm" fontWeight={500} color="black" noOfLines={2}>
            {goal.type.name}
          </Text>
          <Icon as={ChevronRightIcon} h={4} w={4} ml={1} flexShrink={0} />
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" alignItems="flex-end" mt="auto">
        <Box>
          <Text fontSize="lg" fontWeight={600} color="black">
            {formatValue(goal.data?.[goal.data.length - 1]?.value)}
          </Text>
          <Tooltip
            variant="white"
            label={`Target value for ${goal.type.name} is ${formatValue(
              goal.target
            )}, and your current value is ${formatValue(
              goal.data?.[goal.data.length - 1]?.value
            )}`}
          >
            <Flex alignItems="center">
              <Icon
                as={TargetIcon}
                h={4}
                w={4}
                mr={1}
                color={getStatusColor()}
              />
              <Text fontSize="sm" fontWeight={500} color={getStatusColor()}>
                {formatValue(goal.target)}
              </Text>
            </Flex>
          </Tooltip>
        </Box>
        <Box>
          <Flex
            alignItems="center"
            justifyContent="flex-end"
            color={
              hasNotChanged
                ? 'yellow.500'
                : isAboveTarget
                ? 'green.500'
                : 'red.500'
            }
            fontSize="xs"
            fontWeight={500}
          >
            <Icon as={getChangeIcon()} h={4} w={4} mr={1} />
            <Text>{Math.abs(changePercentage).toFixed(1)}%</Text>
          </Flex>
          <Tooltip
            variant="white"
            label="Number of issues we've identified for this goal"
          >
            <Flex alignItems="center" mt={2}>
              {goal.goalIssues && goal.goalIssues.length > 1 && (
                <Icon
                  as={AlertCircleIcon}
                  h={4}
                  w={4}
                  mr={1}
                  color="orange.300"
                />
              )}
              <Text fontSize="xs" fontWeight={500} color="gray.600">
                Issues
              </Text>
              <Badge
                ml={2}
                variant={hasHighIssues ? 'solid' : 'subtle'}
                colorScheme={hasHighIssues ? 'red' : 'gray'}
                fontSize="2xs"
                fontWeight={600}
              >
                {goal.goalIssues?.length}
              </Badge>
            </Flex>
          </Tooltip>
        </Box>
      </Flex>
    </Flex>
  );
};
