import { useToken } from '@chakra-ui/react';
import { curveLinear } from '@visx/curve';
import { scaleLinear, scaleTime } from '@visx/scale';
import { LinePath } from '@visx/shape';
import {
  defaultStyles as defaultTooltipStyles,
  useTooltip,
  useTooltipInPortal,
} from '@visx/tooltip';
import { FC, useCallback, useMemo, useRef } from 'react';
import useMeasure from 'react-use-measure';
import { SentimentOverTimeChartTooltip } from './SentimentOverTimeChartTooltip';
import { ImpactReportQueryResult } from '@spoke/graphql';
import { binarySearch, Flex, pickSpaced, useLagState } from '@spoke/common';
import { Skeleton } from '@spoke/common'; // Import the Skeleton component

export type TooltipProps = {
  width: number;
  height: number;
};

type Props = {
  data: NonNullable<
    ImpactReportQueryResult['data']
  >['impactReport']['sentimentOverTime']['data'];
  loading?: boolean; // Add the loading prop
};

type TooltipData = Props['data'][0] | null;

const INNER_PADDING_HORIZONTAL = 60;
const INNER_PADDING_TOP = 10;
const INNER_PADDING_BOTTOM = 20;
const SVG_HEIGHT = 270;

const MONTH_LABEL_MAX_COUNT = 6;

export const SentimentOverTimeChart: FC<Props> = ({
  data,
  loading = false,
}) => {
  const [ref, bounds] = useMeasure();

  const width = bounds.width || 100;
  const height = bounds.height || 100;

  const [gray200, gray300, gray500, primary400] = useToken('colors', [
    'gray.200',
    'gray.300',
    'gray.500',
    'primary.400',
  ]);

  const textDefaultProps = {
    fontSize: 14,
    fontFamily: 'Roboto, sans-serif',
    fill: gray500,
  };

  const allDates = data.map((i) => new Date(i.date));
  const { minDate, maxDate } = useMemo(() => {
    const sortedDates = allDates.sort((a, b) => a.getTime() - b.getTime());
    const firstDate = new Date(sortedDates[0]);
    const lastDate = new Date(sortedDates[sortedDates.length - 1]);

    if (firstDate.toDateString() === lastDate.toDateString()) {
      firstDate.setDate(firstDate.getDate() - 1);
      lastDate.setDate(lastDate.getDate() + 1);
    } else {
      firstDate.setDate(firstDate.getDate() - 5);
      lastDate.setDate(lastDate.getDate() + 5);
    }

    firstDate.setHours(0, 0, 0, 0);
    lastDate.setHours(23, 59, 59, 999);

    return { minDate: firstDate, maxDate: lastDate };
  }, [allDates]);

  /**
   * This goes from -1 (full negative) to 1 (full positive)
   * Times 20% to get a visual margin
   */
  const minValue = -1 * 1.2;
  const maxValue = 1 * 1.2;

  /**
   * Array of dates containg the first day of each month in the dataset.
   */
  const monthStarts = Object.values(
    data.reduce((acc, issue) => {
      const date = new Date(issue.date);
      const month = date.getMonth();
      const year = date.getFullYear();
      const key = `${month}-${year}`;
      if (!acc[key]) acc[key] = new Date(`${year}-${month + 1}-01`);
      return acc;
    }, {} as Record<string, Date>)
  );

  /**
   * Coordinates for the inner rectangle where we draw dots.
   */
  const canvasXLeft = INNER_PADDING_HORIZONTAL;
  const canvasXRight = width - INNER_PADDING_HORIZONTAL;
  const canvasYTop = INNER_PADDING_TOP;
  const canvasYBottom = height - INNER_PADDING_BOTTOM;

  /**
   * This is a helper component to visualize the margins of the chart.
   * Only useful for development purposes. Flip the boolean constant to enable.
   */

  const xScale = useMemo(
    () =>
      scaleTime({
        range: [canvasXLeft, canvasXRight],
        domain: [minDate, maxDate],
      }),
    [canvasXLeft, canvasXRight, maxDate, minDate]
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [canvasYBottom, canvasYTop],
        domain: [minValue, maxValue],
      }),
    [canvasYBottom, canvasYTop, maxValue, minValue]
  );

  const AxisBottomComponent = (): JSX.Element => (
    <g>
      {pickSpaced(monthStarts, MONTH_LABEL_MAX_COUNT).map((tick, idx) => (
        <text
          key={`tick-${idx}`}
          x={xScale(tick)}
          y={height}
          fontSize={textDefaultProps.fontSize}
          fill={textDefaultProps.fill}
        >
          {tick.toLocaleString('en-US', { month: 'short' }).toUpperCase()}
        </text>
      ))}
    </g>
  );

  const LeftAxisTick: FC<{ y: number; label: string; color: string }> = ({
    y,
    color,
    label,
  }) => {
    const rectHeight = 5;
    return (
      <g>
        <line
          x1={canvasXLeft}
          x2={canvasXRight}
          y1={y}
          y2={y}
          stroke={gray200}
          strokeWidth={1}
        />
        <rect
          x={15}
          y={y - rectHeight / 2}
          width="30"
          height={rectHeight}
          rx="2"
          ry="2"
          fill={color}
        />
        <text
          x={15}
          y={y + rectHeight + 12}
          fontSize={textDefaultProps.fontSize}
          fill={gray500}
        >
          {label}
        </text>
      </g>
    );
  };

  /**
   * Tooltip code
   */
  const {
    containerRef: tooltipContainerRef,
    containerBounds,
    TooltipInPortal,
  } = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  });

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip<TooltipData>({
    // initial tooltip state
    tooltipOpen: false,
    tooltipLeft: width / 3,
    tooltipTop: height / 3,
    tooltipData: null,
  });

  // Wait for exit animation to finish before hiding tooltip
  const [tooltipLeftLag] = useLagState(tooltipLeft, 100);
  const [tooltipTopLag] = useLagState(tooltipTop, 100);

  const tooltipStyles = {
    ...defaultTooltipStyles,
    backgroundColor: 'white',
    color: 'white',
    width: 270,
    height: 240,
    padding: 18,
    borderRadius: 10,
    border: '1px solid rgba(0, 0, 0, 0.1)',
    opacity: 0,
    transition: 'opacity 0.2s ease-out',
  } as const;

  const tooltipArrowStyles = {
    position: 'absolute',
    left: '50%',
    bottom: '-10px',
    marginLeft: '-5px',
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '10px solid white',
  } as const;

  const cursorLineRef = useRef<SVGLineElement>(null);
  const cursorCircleRef = useRef<SVGCircleElement>(null);

  /**
   * This is a helper component to visualize the margins of the chart.
   * Only useful for development purposes. Flip the boolean constant to enable.
   */
  const DevMargins = () => {
    // eslint-disable-next-line no-constant-condition
    if (true) return <></>;
  };

  const handlePointerLeave = useCallback(() => {
    hideTooltip();
    if (cursorLineRef.current) {
      cursorLineRef.current.setAttribute('opacity', `${0}`);
    }
    if (cursorCircleRef.current) {
      cursorCircleRef.current.setAttribute('opacity', `${0}`);
    }
    if (tooltipTimeoutRef.current) clearTimeout(tooltipTimeoutRef.current);
  }, [hideTooltip]);

  const tooltipTimeoutRef = useRef<number | null>(null);
  const TOOLTIP_DELAY_MS = 300;

  /**
   * Tooltip handler for mouse move
   */
  const handlePointerMove = useCallback(
    (event: React.PointerEvent<HTMLDivElement>) => {
      const cursorX =
        ('clientX' in event ? event.clientX : 0) - containerBounds.left;
      const cursorY =
        ('clientY' in event ? event.clientY : 0) - containerBounds.top;

      const matchingPoint = binarySearch(
        data,
        xScale.invert(cursorX).getTime(),
        (point, target) => point.date - target
      );

      const outOfBounds = cursorX < canvasXLeft || cursorX > canvasXRight;

      if (!matchingPoint || outOfBounds) {
        handlePointerLeave();
        return;
      }

      const pointY = yScale(matchingPoint.value ?? height / 2);

      hideTooltip();

      if (tooltipTimeoutRef.current) clearTimeout(tooltipTimeoutRef.current);
      tooltipTimeoutRef.current = window.setTimeout(() => {
        showTooltip({
          tooltipLeft: cursorX - 10 - tooltipStyles.width / 2,
          tooltipTop: pointY - 40 - tooltipStyles.height,
          tooltipData: matchingPoint,
        });
      }, TOOLTIP_DELAY_MS);

      if (cursorLineRef.current) {
        cursorLineRef.current.setAttribute('x1', `${cursorX}`);
        cursorLineRef.current.setAttribute('x2', `${cursorX}`);
        cursorLineRef.current.setAttribute('opacity', `${1}`);
      }
      if (cursorCircleRef.current) {
        cursorCircleRef.current.setAttribute('cx', `${cursorX}`);
        cursorCircleRef.current.setAttribute('cy', `${cursorY}`);
        cursorCircleRef.current.setAttribute('opacity', `${1}`);
      }
    },
    [
      containerBounds.left,
      containerBounds.top,
      data,
      xScale,
      canvasXLeft,
      canvasXRight,
      yScale,
      height,
      hideTooltip,
      handlePointerLeave,
      showTooltip,
      tooltipStyles.width,
      tooltipStyles.height,
    ]
  );

  return (
    <Flex flexDir="column">
      {loading ? (
        <Skeleton width="100%" height={`${SVG_HEIGHT}px`} />
      ) : (
        <Flex
          ref={ref}
          w="full"
          h={SVG_HEIGHT}
          onPointerMove={handlePointerMove}
          onMouseLeave={handlePointerLeave}
          cursor="none"
        >
          <svg
            width="100%"
            height="100%"
            viewBox={`0 0 ${width} ${height}`}
            ref={tooltipContainerRef}
          >
            <DevMargins />
            <LeftAxisTick y={yScale(1)} label="Positive" color="#12B886" />
            <LeftAxisTick y={yScale(0)} label="Neutral" color="#FFCC3E" />
            <LeftAxisTick y={yScale(-1)} label="Negative" color="#FA5252" />

            <LinePath
              data={data}
              x={(d) => xScale(+d.date)}
              y={(d) => yScale(d.value ?? 0)}
              curve={curveLinear}
              stroke={primary400}
              strokeWidth={2}
              strokeDasharray="4,4"
            />

            {/* Line that follows cursor */}
            <line
              className="point-chart-cursor"
              ref={cursorLineRef}
              y1={yScale(-1)}
              y2={yScale(1)}
              opacity={0}
              stroke={gray300}
            />

            {/* Circle that follows cursor (outer) */}
            <circle
              r={4}
              ref={cursorCircleRef}
              opacity={0}
              fill="white"
              stroke={gray300}
              strokeWidth={2}
              pointerEvents="none"
            />

            <TooltipInPortal
              left={tooltipLeft || tooltipLeftLag}
              top={tooltipTop || tooltipTopLag}
              style={{ ...tooltipStyles, opacity: tooltipOpen ? 1 : 0 }}
              zIndex={1000}
            >
              <div
                style={{
                  ...tooltipArrowStyles,
                  borderTop: '10px solid rgba(0, 0, 0, 0.05)',
                  bottom: '-12px',
                }}
              />
              <div style={tooltipArrowStyles} />
              <SentimentOverTimeChartTooltip data={tooltipData ?? null} />
            </TooltipInPortal>

            <AxisBottomComponent />
          </svg>
        </Flex>
      )}
    </Flex>
  );
};
