import { StackProps } from '@chakra-ui/react';
import { FC } from 'react';
import { MdOutlineAutoAwesome } from 'react-icons/md';
import { FlowMetricsQueryData } from '../queries';
import {
  SourceControlInsight,
  DiscussionAnalyticsInsight,
  ImprovementGoalInsight,
  SourceControlInsightReviewPercent,
  FlowInsightForecast,
  DiscussionAnalyticsInsightKeywordHighlight,
  ImprovementGoalInsightFocus,
} from '@spoke/graphql';
import {
  VStack,
  HStack,
  Icon,
  Skeleton,
  Flex,
  SpkTime,
  ONE_DAY_MS,
  IssueLink,
  Text,
  Image,
} from '@spoke/common';

type Props = StackProps & {
  loading?: boolean;
  insight:
    | SourceControlInsight
    | DiscussionAnalyticsInsight
    | ImprovementGoalInsight
    | FlowMetricsQueryData['insight']
    | null;
};

export const MetricsInsight: FC<Props> = ({ loading, insight, ...rest }) => {
  const insightByType = {
    SourceControlInsightReviewPercent: (
      <SourceControlInsightReviewPercentInsight
        insight={insight as SourceControlInsightReviewPercent}
      />
    ),
    FlowInsightForecast: (
      <FlowInsightForecastInsight
        insight={insight as FlowInsightForecast & { cycleTimeMs?: number }}
      />
    ),
    DiscussionAnalyticsInsightKeywordHighlight: (
      <DiscussionAnalyticsInsightKeywordHighlightInsight
        insight={insight as DiscussionAnalyticsInsightKeywordHighlight}
      />
    ),
    ImprovementGoalInsightFocus: (
      <ImprovementGoalInsightFocusInsight
        insight={insight as ImprovementGoalInsightFocus}
      />
    ),
    '': <MetricsInsightEmptyState />,
  } as const;

  if (!insightByType[insight?.__typename ?? ''] && !loading) return null;

  return (
    <VStack alignItems="flex-start" spacing="1px" {...rest}>
      <HStack alignItems="flex-start" spacing={1}>
        <Icon
          as={MdOutlineAutoAwesome}
          h={4}
          w={4}
          color="primary.500"
          mt="1px"
        />
        <Text fontSize={14} color="primary.600">
          Insight
        </Text>
      </HStack>
      {!loading ? (
        insightByType[insight?.__typename ?? '']
      ) : (
        <Skeleton minH={5} w="full" />
      )}
    </VStack>
  );
};

const MetricsInsightEmptyState = () => (
  <Flex gap={1} pt="1px" alignItems="center">
    {/* <Text color="gray.500" fontSize={14}>
      We&apos;ll show you insights once we have enough data.
    </Text> */}
    <Text color="gray.500" fontSize={14}>
      A work item has exceeded its expected cycle time and also has the lowest
      percentage of commits relative to other tasks.
    </Text>
  </Flex>
);

const SourceControlInsightReviewPercentInsight = ({
  insight,
}: {
  insight: SourceControlInsightReviewPercent;
}) => (
  <Flex gap={2} alignItems="center">
    {insight.picUrl && (
      <Image
        src={insight.picUrl}
        alt={insight.userName}
        borderRadius={200}
        w="20px"
        h="20px"
      />
    )}
    <Text gap={1} mt="1px">
      <Text as="span" color="gray.800" fontSize={14}>
        {insight.userName}
      </Text>{' '}
      <Text as="span" color="gray.500" fontSize={14}>
        has been doing
      </Text>{' '}
      <Text as="span" color="gray.800" fontSize={14}>
        {insight.value}%
      </Text>{' '}
      <Text as="span" color="gray.500" fontSize={14}>
        of the reviews for the team.
      </Text>
    </Text>
  </Flex>
);

const FlowInsightForecastInsight = ({
  insight,
}: {
  insight: FlowInsightForecast & { cycleTimeMs?: number };
}) => {
  const { itemsNearCycleTime, itemsOverCycleTime, cycleTimeMs } = insight;

  if (!cycleTimeMs) return null;

  const showExceededTimeOf = (timeInMs: number): string =>
    SpkTime.formatDuration(
      timeInMs,
      timeInMs >= ONE_DAY_MS ? 'days' : 'hours',
      true
    );

  const orderedItemsOverCycleTime =
    itemsOverCycleTime
      ?.slice()
      ?.sort((a, b) => (b?.wipTimeMs ?? 0) - (a?.wipTimeMs ?? 0)) ?? [];

  const orderedItemsNearCycleTime =
    itemsNearCycleTime
      ?.slice()
      ?.sort((a, b) => (b?.wipTimeMs ?? 0) - (a?.wipTimeMs ?? 0)) ?? [];

  const NearingCycleTime = !orderedItemsNearCycleTime[0] ? null : (
    <>
      The issue
      <IssueLink
        text={orderedItemsNearCycleTime?.[0]?.slug ?? ''}
        url={orderedItemsNearCycleTime?.[0]?.externalUrl ?? ''}
        fontSize={14}
        pb="3px"
        iconProps={{ transform: 'translateY(-3px)', h: 4 }}
      />
      is only
      <Text color="gray.800" fontSize={14}>
        {showExceededTimeOf(
          (cycleTimeMs || 0) - (orderedItemsNearCycleTime?.[0]?.wipTimeMs || 0)
        )}
      </Text>
      away from exceeding your cycle time.
    </>
  );

  const OverCycleTime = !orderedItemsOverCycleTime[0] ? null : (
    <>
      Work item
      <IssueLink
        d="inline"
        text={orderedItemsOverCycleTime[0].slug ?? ''}
        url={orderedItemsOverCycleTime[0].externalUrl ?? ''}
        fontSize={14}
        pb="3px"
        iconProps={{ transform: 'translateY(-3px)', h: 4 }}
      />
      has exceeded your cycle time by
      <Text color="gray.800" fontSize={14}>
        {showExceededTimeOf(
          orderedItemsOverCycleTime?.[0]?.wipTimeMs - (cycleTimeMs || 0)
        )}
      </Text>
      {orderedItemsOverCycleTime[1] ? (
        <>
          and
          <IssueLink
            text={orderedItemsOverCycleTime[1].slug ?? ''}
            url={orderedItemsOverCycleTime[1].externalUrl ?? ''}
            fontSize={14}
            pb="3px"
            iconProps={{ transform: 'translateY(-3px)', h: 4 }}
          />
          by
          <Text color="gray.800" fontSize={14}>
            {showExceededTimeOf(
              orderedItemsOverCycleTime?.[1]?.wipTimeMs - (cycleTimeMs || 0)
            )}
            .
          </Text>
        </>
      ) : (
        <> </>
      )}
    </>
  );

  const OverCycleTimeNumberOnly = !orderedItemsOverCycleTime ? null : (
    <Text color="gray.500" fontSize={14}>
      {orderedItemsOverCycleTime.length} work items have exceeded your cycle
      time.
    </Text>
  );

  const CycleTimeKudos =
    'No work items are aging near your cycle time. Keep this up!';

  return (
    <Flex
      lineHeight={1}
      gap={1}
      pt="1px"
      alignItems="center"
      color="gray.500"
      fontSize={14}
      flexWrap="wrap"
    >
      {!itemsOverCycleTime.length && orderedItemsNearCycleTime.length
        ? NearingCycleTime
        : !itemsOverCycleTime.length
        ? CycleTimeKudos
        : orderedItemsOverCycleTime.length <= 2
        ? OverCycleTime
        : OverCycleTimeNumberOnly}
    </Flex>
  );
};
const DiscussionAnalyticsInsightKeywordHighlightInsight = ({
  insight,
}: {
  insight: DiscussionAnalyticsInsightKeywordHighlight;
}) => (
  <Flex gap={1} pt="1px" alignItems="center">
    <Text color="gray.500" fontSize={14}>
      The team is talking a lot about
    </Text>
    <Text color="gray.800" fontSize={14}>
      {insight.keyword}
    </Text>
    <Text color="gray.500" fontSize={14}>
      in a
    </Text>
    <Text color="gray.800" fontSize={14}>
      {insight.sentiment} way.
    </Text>
  </Flex>
);

const ImprovementGoalInsightFocusInsight = ({
  insight,
}: {
  insight: ImprovementGoalInsightFocus;
}) => (
  <Flex gap={1} pt="1px" alignItems="center">
    <Text color="gray.500" fontSize={14}>
      You have been focusing
    </Text>
    <Text color="gray.800" fontSize={14}>
      {insight.percentage}%
    </Text>
    <Text color="gray.500" fontSize={14}>
      of your goal actions on
    </Text>
    <Text color="gray.800" fontSize={14}>
      {insight.goalName}
    </Text>
  </Flex>
);
