import { useCallback } from 'react';
import { useToast, TOAST_ERROR_GENERIC, log } from '@spoke/common';
import { ActivityReactionKind, useToggleReactionForActivityFeedMutation } from '@spoke/graphql';

export const useToggleReactionForActivityFeedHandler = () => {
  const [toggleReaction] = useToggleReactionForActivityFeedMutation();
  const [toast] = useToast();

  const handleToggleReaction = useCallback(
    async (data: {
      userId: string;
      activityId: string;
      kind: ActivityReactionKind;
    }): Promise<boolean> => {
      const { userId, activityId, kind } = data;

      if (!activityId || !userId || !kind) {
        log.error(
          'Missing critical data to toggle reaction for activity feed',
          {
            userId,
            activityId,
            kind,
          }
        );
        return false;
      }

      log.info('Toggling reaction for activity feed', { data });

      const { errors, data: result } = await toggleReaction({
        variables: { activityId, kind },
      });

      if (errors) {
        log.error('toggleReactionForActivityFeed call responded with errors', {
          errors,
          data,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return true;
    },
    [toggleReaction, toast]
  );

  return [handleToggleReaction];
};
