import { IconProps, Icon } from '../Icon';

export const OrganizationIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect width="24" height="24" fill="#F3F1FB" rx="8" />
    <path
      fill="currentColor"
      d="M8 14c.733 0 1.333.6 1.333 1.333 0 .734-.6 1.334-1.333 1.334s-1.333-.6-1.333-1.334C6.667 14.6 7.267 14 8 14zm0-1.333a2.674 2.674 0 00-2.667 2.666C5.333 16.8 6.533 18 8 18c1.467 0 2.667-1.2 2.667-2.667 0-1.466-1.2-2.666-2.667-2.666zm4-5.334c.733 0 1.333.6 1.333 1.334C13.333 9.4 12.733 10 12 10s-1.333-.6-1.333-1.333c0-.734.6-1.334 1.333-1.334zM12 6a2.674 2.674 0 00-2.667 2.667c0 1.466 1.2 2.666 2.667 2.666 1.467 0 2.667-1.2 2.667-2.666C14.667 7.2 13.467 6 12 6zm4 8c.733 0 1.333.6 1.333 1.333 0 .734-.6 1.334-1.333 1.334s-1.333-.6-1.333-1.334c0-.733.6-1.333 1.333-1.333zm0-1.333a2.674 2.674 0 00-2.667 2.666C13.333 16.8 14.533 18 16 18c1.467 0 2.667-1.2 2.667-2.667 0-1.466-1.2-2.666-2.667-2.666z"
    />
  </Icon>
);
