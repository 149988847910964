import { FC } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import { BiAddToQueue } from 'react-icons/bi';
import { VscFeedback } from 'react-icons/vsc';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { ImportBoardWizardModal } from './ImportBoardWizard';
import { useTeamType } from '@spoke/user';
import {
  ButtonProps,
  useDisclosure,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Icon,
  useRouter,
  useRoutes,
} from '@spoke/common';
import { AsyncFeedbackModal } from './AsyncFeedback/AsyncFeedbackModal';

export const CreateRetroButton: FC<ButtonProps> = ({ ...props }) => {
  const router = useRouter();
  const routes = useRoutes();
  const importBoardWizard = useDisclosure();
  const asyncFeedbackModal = useDisclosure();
  const { lowercaseTeamType } = useTeamType();

  return (
    <Menu placement="bottom-end">
      {({ isOpen }) => (
        <>
          <MenuButton _disabled={{ cursor: 'not-allowed' }} {...props}>
            <Button
              id="create-retro-dashboard-btn"
              as="span"
              rightIcon={isOpen ? <BsChevronUp /> : <BsChevronDown />}
              {...props}
            >
              Collect Feedback
            </Button>
          </MenuButton>
          <MenuList>
            <MenuItem
              display="flex"
              alignItems="center"
              onClick={asyncFeedbackModal.open}
              color="gray.600"
            >
              <Icon as={VscFeedback} w={5} h={5} color="gray.600" mr={2} />
              Async Feedback
            </MenuItem>
            <MenuItem
              display="flex"
              alignItems="center"
              onClick={() => router.push(routes.CreateBoard)}
              color="gray.600"
            >
              <Icon as={BiAddToQueue} w={5} h={5} color="gray.600" mr={2} />
              Create Retrospective
            </MenuItem>
            <MenuItem
              onClick={importBoardWizard.open}
              display="flex"
              alignItems="center"
              color="gray.600"
            >
              <Icon
                as={AiOutlineDownload}
                w={5}
                h={5}
                color="gray.600"
                mb="2px"
                mr={2}
              />
              Import Feedback
            </MenuItem>
          </MenuList>
          <ImportBoardWizardModal
            isOpen={importBoardWizard.isOpen}
            onClose={importBoardWizard.close}
          />
          <AsyncFeedbackModal
            isOpen={asyncFeedbackModal.isOpen}
            onClose={asyncFeedbackModal.close}
          />
        </>
      )}
    </Menu>
  );
};
