
import { useCallback } from 'react';
import { StackProps, VStack } from '@chakra-ui/react';
import {
  useDeleteActionItemHandler,
  useToggleActionItemStatusHandler,
} from '../handlers';
import { useActionItems } from '../queries';
import { MemoizedActionItemCard } from './ActionItemCard';
import { ActionItem } from '@spoke/graphql';

type ActionItemListProps = StackProps & {
  actionItems: ReturnType<typeof useActionItems>[0]['unresolved' | 'resolved'];
  setEditingActionItemId: (id: string) => void;
};

export const ActionItemList = ({
  actionItems,
  setEditingActionItemId,
  ...rest
}: ActionItemListProps) => {
  const [handleDeleteActionItem] = useDeleteActionItemHandler();
  const [handleToggleActionItemStatus] = useToggleActionItemStatusHandler();
  const currentActionItemsCount = actionItems.length;

  const onActionItemEditClick = useCallback(
    (actionItemId: string) => setEditingActionItemId(actionItemId),
    [setEditingActionItemId]
  );

  return (
    <VStack
      w="full"
      alignItems="stretch"
      mt={4}
      spacing={3}
      overflowY="auto"
      pb={currentActionItemsCount ? 4 : 0}
      {...rest}
    >
      {actionItems?.map(
        (item) =>
          item && (
            <MemoizedActionItemCard
              key={item.id as string}
              actionItem={item as ActionItem}
              onDelete={handleDeleteActionItem}
              onResolveToggle={handleToggleActionItemStatus}
              onEditClick={onActionItemEditClick}
            />
          )
      )}
    </VStack>
  );
};
