import {
  Flex,
  Heading,
  Box,
  Text,
  MenuDivider,
  MenuGroup,
} from '@chakra-ui/react';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { format, isToday, isYesterday } from 'date-fns';
import { BiInfoCircle } from 'react-icons/bi';
import { BsFilter } from 'react-icons/bs';
import {
  MdOutlineAutoAwesome,
  MdOutlineBookmarkBorder,
  MdOutlinedFlag,
  MdOutlineStickyNote2,
} from 'react-icons/md';
import { debounce } from 'lodash';
import { useActivityFeedByTeamId } from '../queries';
import { ActivityFeedCard } from './ActivityFeedCard';
import { ActivityFeedEmptyState } from './ActivityFeedEmptyState';
import { Activity, ActivityType } from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';
import {
  Badge,
  Button,
  Checkbox,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MetricsIcon,
  Skeleton,
  Spacer,
  Tooltip,
} from '@spoke/common';

export const ActivityFeed: FC = () => {
  const [currentTeam] = useCurrentTeam();
  const previousTeamId = useRef<string | null>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isFiltersMenuOpen, setIsFiltersMenuOpen] = useState(false);
  const [activityTypeFilters, setActivityTypeFilters] = useState<
    Array<ActivityType>
  >([]);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const feedPageSize = 10;
  const [data, queryResult, loadMore, refetch] =
    useActivityFeedByTeamId(feedPageSize);

  // when the current team changes or the component mounts unmounts to make sure get latest updates
  useEffect(() => {
    if (currentTeam?.id !== previousTeamId.current) {
      refetch(currentTeam?.id);
      previousTeamId.current = currentTeam?.id ?? null;
    }
  }, [refetch, currentTeam, currentTeam?.id]);

  const handleFilterChange = (filter: ActivityType) => {
    setActivityTypeFilters((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter((f) => f !== filter);
      } else {
        return [...prevFilters, filter];
      }
    });
  };

  const refetchWithFilters = useCallback(() => {
    const debounced = debounce(() => {
      refetch(currentTeam?.id, activityTypeFilters);
    }, 300);
    debounced();
  }, [activityTypeFilters, refetch, currentTeam?.id]);

  useEffect(() => {
    if (shouldRefetch) {
      refetchWithFilters();
      setShouldRefetch(false); // Reset the trigger
    }
  }, [shouldRefetch, refetchWithFilters]);

  const isActivityTypeInFilters = (activityType: ActivityType): boolean => {
    return activityTypeFilters.includes(activityType);
  };

  // Group activities by day, we could make this exposed option to change
  const groupedActivities = data?.activities.reduce<Record<string, Activity[]>>(
    (groups, activity) => {
      if (!activity.createdAt) {
        console.error(
          'Invalid createdAt value from server:',
          activity.createdAt
        );
        return groups; // Skip this activity if createdAt is invalid
      }
      const createdAt = new Date(activity.createdAt);
      if (!activity.createdAt) {
        console.error('Parsed invalid createdAt value:', activity.createdAt);
        return groups; // Skip this activity if createdAt is invalid
      }
      const date = createdAt.toISOString();
      if (!date) {
        console.error('Error localstring parsing:', activity.createdAt);
        return groups; // Skip this activity if createdAt is invalid
      }
      if (!groups[date]) {
        groups[date] = [];
      }
      // this works fine, just weird graphql type stuff from generator
      // @ts-ignore
      groups[date].push(activity);
      return groups;
    },
    {}
  );

  return (
    <Box
      borderWidth={1}
      borderRadius="lg"
      borderColor="gray.200"
      mt={4}
      mb={4}
      p={4}
    >
      <Flex gap={4} justifyContent="flex-start">
        <Heading fontSize={16} fontWeight={500} color="gray.900">
          Recent Activity
        </Heading>
        <Tooltip
          label="Stay informed and take action on the most valuable events and insights from your Organization."
          openDelay={350}
          placement="top"
          fontSize={12}
          maxW={250}
          variant="white"
          hasArrow
        >
          <Box tabIndex={-1}>
            <Icon as={BiInfoCircle} h={5} w={5} color="gray.500" />
          </Box>
        </Tooltip>
        <Spacer />
        <Menu
          isOpen={isFiltersMenuOpen}
          onClose={() => {}}
          // onBlur={() => setIsFiltersMenuOpen(false)}
        >
          {({ isOpen }) => (
            <>
              <MenuButton
                as={Button}
                onClick={() => setIsFiltersMenuOpen(!isFiltersMenuOpen)}
                variant="outlineGray"
                leftIcon={<Icon as={BsFilter} color="gray.600" />}
              >
                Filter
                <Badge ml={2} colorScheme="gray">
                  {activityTypeFilters.length}
                </Badge>
              </MenuButton>
              <MenuList>
                <MenuGroup title="Feed Filters">
                  <MenuDivider />
                </MenuGroup>
                <MenuItem display="flex" alignItems="center" color="gray.600">
                  <Checkbox
                    isChecked={isActivityTypeInFilters(ActivityType.Insight)}
                    onChange={() => handleFilterChange(ActivityType.Insight)}
                  >
                    <Flex direction="row" align="center">
                      <Icon as={MdOutlineAutoAwesome} w={5} h={5} />
                      <Text fontSize="md" fontWeight="bold" ml={2}>
                        Insights
                      </Text>
                    </Flex>
                    <Text fontSize="xs">
                      Key takeaways from retrospectives, metrics, goals, and
                      more.
                    </Text>
                  </Checkbox>
                </MenuItem>
                <MenuItem display="flex" alignItems="center" color="gray.600">
                  <Checkbox
                    isChecked={isActivityTypeInFilters(ActivityType.Metrics)}
                    onChange={() => handleFilterChange(ActivityType.Metrics)}
                  >
                    <Flex direction="row" align="center">
                      <Icon as={MetricsIcon} w={5} h={5} />
                      <Text fontSize="md" fontWeight="bold" ml={2}>
                        Metrics
                      </Text>
                    </Flex>
                    <Text fontSize="xs">
                      Trends and stats about team performance.
                    </Text>
                  </Checkbox>
                </MenuItem>
                <MenuItem display="flex" alignItems="center" color="gray.600">
                  <Checkbox
                    isChecked={isActivityTypeInFilters(
                      ActivityType.Retrospective
                    )}
                    onChange={() =>
                      handleFilterChange(ActivityType.Retrospective)
                    }
                  >
                    <Flex direction="row" align="center">
                      <Icon as={MdOutlineStickyNote2} w={5} h={5} />
                      <Text fontSize="md" fontWeight="bold" ml={2}>
                        Retrospectives
                      </Text>
                    </Flex>
                    <Text fontSize="xs">
                      Review summaries and discussions from team retros.
                    </Text>
                  </Checkbox>
                </MenuItem>
                <MenuItem display="flex" alignItems="center" color="gray.600">
                  <Checkbox
                    isChecked={isActivityTypeInFilters(ActivityType.ActionItem)}
                    onChange={() => handleFilterChange(ActivityType.ActionItem)}
                  >
                    <Flex direction="row" align="center">
                      <Icon as={MdOutlineBookmarkBorder} w={5} h={5} />
                      <Text fontSize="md" fontWeight="bold" ml={2}>
                        Action Items
                      </Text>
                    </Flex>
                    <Text fontSize="xs">
                      Highlight tasks, assignments, and follow-up steps from
                      discussions.
                    </Text>
                  </Checkbox>
                </MenuItem>
                <MenuItem display="flex" alignItems="center" color="gray.600">
                  <Checkbox
                    isChecked={isActivityTypeInFilters(ActivityType.Goal)}
                    onChange={() => handleFilterChange(ActivityType.Goal)}
                  >
                    <Flex direction="row" align="center">
                      <Icon as={MdOutlinedFlag} w={5} h={5} />
                      <Text fontSize="md" fontWeight="bold" ml={2}>
                        Goals
                      </Text>
                    </Flex>
                    <Text fontSize="xs">
                      See progress and updates for your objectives.
                    </Text>
                  </Checkbox>
                </MenuItem>
                <MenuDivider />
                <Flex display="flex" justifyContent="flex-end" color="gray.600">
                  <Text
                    fontSize="sm"
                    color="gray.500"
                    onClick={() => {
                      setActivityTypeFilters([]);
                      setIsFiltersMenuOpen(false);
                      setShouldRefetch(true);
                    }}
                    cursor="pointer"
                    mt={2}
                  >
                    Reset to default
                  </Text>
                  <Button
                    ml={4}
                    size="sm"
                    onClick={() => {
                      setShouldRefetch(true);
                      setIsFiltersMenuOpen(false);
                    }}
                  >
                    Save
                  </Button>
                </Flex>
              </MenuList>
            </>
          )}
        </Menu>
      </Flex>
      <Flex gap={4} justifyContent="space-between">
        <Heading fontSize={12} fontWeight={500} mt={2} color="gray.500">
          Most recent events for your {currentTeam?.type.toLowerCase()}
        </Heading>
      </Flex>
      <Flex mt={4} flexDir="column" alignItems="stretch">
        {queryResult.loading ? (
          <Skeleton minW="full" minH={400} />
        ) : (
          groupedActivities &&
          Object.entries(groupedActivities).length > 0 &&
          Object.entries(groupedActivities).map(([date, activities]) => (
            <Box key={date}>
              <Text fontSize="14" color="gray.500" my={3}>
                {!isNaN(Date.parse(date))
                  ? isToday(new Date(date))
                    ? 'Today'
                    : isYesterday(new Date(date))
                    ? 'Yesterday'
                    : format(new Date(date), 'MMMM dd, yyyy')
                  : console.error(`Invalid date on markdown: ${date}`)}
              </Text>
              {activities.map((activity, index) => (
                <ActivityFeedCard key={activity.id} activity={activity} />
              ))}
            </Box>
          ))
        )}
        {data?.nextCursor && (
          <Flex>
            <Button
              w="full"
              bg="white"
              _hover={{ bg: 'gray.50' }}
              variant="outlineGray"
              onClick={async () => {
                setIsLoadingMore(true);
                await loadMore();
                setIsLoadingMore(false);
              }}
              isDisabled={isLoadingMore}
            >
              Load More
            </Button>
          </Flex>
        )}
        {!queryResult.loading &&
          (!groupedActivities ||
            Object.entries(groupedActivities).length === 0) && (
            <ActivityFeedEmptyState mt={12} maxW={600} mx="auto" />
          )}
      </Flex>
    </Box>
  );
};
