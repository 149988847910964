import {
  useColorModeValue,
  Flex,
  Stack,
  Icon,
  Divider,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  AddCommentBox,
  MotionFlex,
  useCurrentUser,
  useToast,
  Comment,
  Spacer,
  Tooltip,
  Box,
} from '@spoke/common';
import { Activity, ActivityReactionKind } from '@spoke/graphql';
import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import { BiChat, BiCopy, BiShare } from 'react-icons/bi';
import { PiHandsClappingLight } from 'react-icons/pi';
import { RiMailSendLine } from 'react-icons/ri';
import { useAddCommentForActivityFeedHandler } from '../handlers/useAddCommentForActivityFeedHandler';
import { useArchiveCommentForActivityFeedHandler } from '../handlers/useArchiveCommentForActivityFeedHandler';
import { useToggleReactionForActivityFeedHandler } from '../handlers/useToggleActivityFeedItemHandler';

export const ActivityFeedCardFooter = ({
  activity,
}: {
  activity: Activity;
}) => {
  const [currentUser] = useCurrentUser();
  const hoverColor = useColorModeValue('purple.800', 'purple.200');

  const [handleToggleReaction] = useToggleReactionForActivityFeedHandler();
  const [handleArchiveComment] = useArchiveCommentForActivityFeedHandler();
  const [handleAddComment] = useAddCommentForActivityFeedHandler();

  const [toast] = useToast();
  const [commentsOpen, setCommentsOpen] = useState<boolean>(false);
  const btnRef = React.useRef<HTMLElement>(null);

  return (
    <Flex
      sx={{
        '& > button': {
          minW: '136px',
        },
      }}
      flexDir="column"
    >
      <Stack direction="row" align="center" mt={2}>
        <Flex position="relative">
          {activity.reactions.length > 0 && (
            <Tooltip
              label={`${activity.reactions
                .map((reaction) => reaction?.user?.name || '')
                .join(', ')} gave kudos`}
              openDelay={350}
              placement="top"
              fontSize={12}
              maxW={250}
              variant="white"
              hasArrow
            >
              <Flex direction="row" align="center">
                <Icon
                  as={PiHandsClappingLight}
                  color={'gray.500'}
                  w={4}
                  h={4}
                  mr={1}
                />
                <Text color={'gray.500'} mr={2} textAlign="left" fontSize="12">
                  {activity.reactions.length === 1 && `You gave kudos`}
                  {activity.reactions.length === 2 &&
                    `You and ${
                      activity.reactions[1]?.user?.firstName || ''
                    } gave kudos`}
                  {activity.reactions.length === 3 &&
                    `You, ${
                      activity.reactions[1]?.user?.firstName || ''
                    }, and ${
                      activity.reactions[2]?.user?.firstName || ''
                    } gave kudos`}
                  {activity.reactions.length > 3 &&
                    `You, ${
                      activity.reactions[1]?.user?.firstName || ''
                    }, and ${activity.reactions.length - 2} others gave kudos`}
                </Text>
              </Flex>
            </Tooltip>
          )}
        </Flex>
        {activity.comments.length > 0 && (
          <>
            <Spacer />
            <Text color="yourColorHere" mr={2} textAlign="right" fontSize="12">
              {`${activity.comments?.length || 0} comment${
                activity.comments?.length !== 1 ? 's' : ''
              }`}
            </Text>
          </>
        )}
      </Stack>
      <Divider my={4} />
      <Stack direction="row" align="center">
        <Icon
          as={PiHandsClappingLight}
          boxSize={5}
          _hover={{
            color: hoverColor,
          }}
          w={5}
          h={5}
          cursor="pointer"
          onClick={() => {
            handleToggleReaction({
              userId: currentUser?.id || '',
              activityId: activity.id,
              kind: ActivityReactionKind.Kudos,
            });
          }}
        />
        <Text
          mr={2}
          textAlign="left"
          fontSize="14"
          cursor="pointer"
          onClick={() => {
            handleToggleReaction({
              userId: currentUser?.id || '',
              activityId: activity.id,
              kind: ActivityReactionKind.Kudos,
            });
          }}
        >
          Kudos
        </Text>
        <Icon
          as={BiChat}
          boxSize={5}
          _hover={{
            color: hoverColor,
          }}
          w={5}
          h={5}
          cursor="pointer"
          onClick={() => setCommentsOpen(!commentsOpen)}
        />
        <Text
          mr={2}
          textAlign="left"
          fontSize="14"
          cursor="pointer"
          onClick={() => setCommentsOpen(!commentsOpen)}
        >
          What happened?
        </Text>
        {/* <Icon
          as={BiShare}
          boxSize={5}
          _hover={{
            color: hoverColor,
          }}
          w={5}
          h={5}
          cursor="pointer"
          onClick={() => {
            toast({
              title: 'Success',
              description: 'Text has been copied to clipboard',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });

            navigator.clipboard.writeText(activity.bodyText);
          }}
        />
        <Text
          mr={2}
          textAlign="left"
          fontSize="14"
          cursor="pointer"
          onClick={() => {
            toast({
              title: 'Success',
              description: 'Text has been copied to clipboard',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });

            navigator.clipboard.writeText(activity.bodyText);
          }}
        >
          Share
        </Text> */}
        <Icon
          as={BiCopy}
          boxSize={5}
          _hover={{
            color: hoverColor,
          }}
          w={5}
          h={5}
          cursor="pointer"
          onClick={() => {
            toast({
              title: 'Success',
              description: 'Text has been copied to clipboard',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });

            navigator.clipboard.writeText(activity.bodyText);
          }}
        />
        <Text
          mr={2}
          textAlign="left"
          fontSize="14"
          cursor="pointer"
          onClick={() => {
            toast({
              title: 'Success',
              description: 'Text has been copied to clipboard',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });

            navigator.clipboard.writeText(activity.bodyText);
          }}
        >
          Copy
        </Text>
      </Stack>
      <AnimatePresence>
        {commentsOpen && (
          <MotionFlex
            initial={{ height: '0px', opacity: 0 }}
            animate={{ height: 'fit-content', opacity: 1 }}
            exit={{ height: '0px', opacity: 0 }}
            transition={{ duration: 0.07, ease: 'easeOut' }}
          >
            <Flex
              flexDir="column"
              p={3}
              borderTopWidth="1px"
              borderTopColor="gray.100"
              borderTopStyle="solid"
              cursor="default"
              w="full"
            >
              <AddCommentBox
                onAdd={(text) =>
                  handleAddComment(currentUser?.id || '', activity.id, text)
                }
              />
              {activity.comments?.map((comment) =>
                comment ? (
                  <Comment
                    key={comment.id}
                    id={comment.id}
                    authorName={comment.user?.name}
                    canDelete={comment.user?.id === currentUser?.id}
                    createdAt={comment.createdAt}
                    text={comment.text}
                    onDelete={() =>
                      handleArchiveComment(activity.id, comment.id)
                    }
                    mt={3}
                  />
                ) : null
              )}
            </Flex>
          </MotionFlex>
        )}
      </AnimatePresence>
    </Flex>
  );
};
