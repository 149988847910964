import { useEffect } from 'react';
import { QueryConfig, TEN_SECONDS_MS } from '@spoke/common';
import { useCurrentTeam } from '@spoke/user';
import {
  useGetActivityFeedQuery,
  ActivityFeedNewActivityByTeamIdDocument,
  ActivityFeedUpdateByTeamIdDocument,
  ActivityType,
} from '@spoke/graphql';

type Config = QueryConfig<typeof useGetActivityFeedQuery>;
type QueryRef = ReturnType<typeof useGetActivityFeedQuery>;
export type ActivityFeedByTeamIdHookData = NonNullable<
  NonNullable<QueryRef['data']>['feed']
>;

type UseActivityFeedByTeamIdHookConfig = {
  pollIfNeeded?: boolean;
};

const DEFAULT_HOOK_CONFIG: UseActivityFeedByTeamIdHookConfig = {
  pollIfNeeded: false,
};

const ACTIVITY_FEED_POLLING_INTERVAL_MS = TEN_SECONDS_MS;

export const useActivityFeedByTeamId = (
  pageSize: number,
  cursor?: string,
  hookConfig: UseActivityFeedByTeamIdHookConfig = DEFAULT_HOOK_CONFIG
): [
  ActivityFeedByTeamIdHookData | null,
  QueryRef,
  () => void,
  (
    newTeamId: string | undefined,
    activityFeedTypeFilters?: Array<ActivityType>
  ) => void
] => {
  const { pollIfNeeded } = hookConfig;
  const [currentTeam] = useCurrentTeam();
  const teamId = currentTeam?.id;

  const config: Config = {
    variables: {
      teamId: teamId || '',
      pageSize,
      cursor,
    },
  };

  const activityFeedByTeamIdQuery = useGetActivityFeedQuery(config);

  // Handle subscriptions
  useEffect(() => {
    if (!teamId) return;
    const unsubscribeNewActivity = activityFeedByTeamIdQuery.subscribeToMore({
      document: ActivityFeedNewActivityByTeamIdDocument,
      variables: {
        teamId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        // this is valid and works, types are just wrong
        const newActivity = (subscriptionData.data as any)
          .activityFeedNewActivityByTeamId;
        const mergedActivities = [
          newActivity,
          ...(prev?.feed?.activities || []),
        ];
        return {
          __typename: 'Query',
          feed: {
            activities: mergedActivities,
            nextCursor: mergedActivities[mergedActivities.length - 1].id,
          },
        };
      },
    });

    const unsubscribeUpdateActivity = activityFeedByTeamIdQuery.subscribeToMore(
      {
        document: ActivityFeedUpdateByTeamIdDocument,
        variables: {
          teamId,
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          // this is valid and works, types are just wrong
          const updatedActivity = (subscriptionData.data as any)
            .activityFeedUpdateByTeamId;

          // Find the index of the activity to be updated
          const activityIndex = prev?.feed?.activities.findIndex(
            (activity) => activity.id === updatedActivity.id
          );

          // If the activity is found, replace it
          let updatedActivities = prev?.feed?.activities || [];
          if (activityIndex !== undefined && activityIndex !== -1) {
            updatedActivities = [
              ...updatedActivities.slice(0, activityIndex),
              updatedActivity,
              ...updatedActivities.slice(activityIndex + 1),
            ];
          }

          return {
            __typename: 'Query',
            feed: {
              activities: updatedActivities,
              nextCursor: prev?.feed?.nextCursor,
            },
          };
        },
      }
    );

    return () => {
      unsubscribeNewActivity();
      unsubscribeUpdateActivity();
    };
  }, [teamId, activityFeedByTeamIdQuery]);

  // Handle polling
  useEffect(() => {
    if (pollIfNeeded) {
      activityFeedByTeamIdQuery.startPolling(ACTIVITY_FEED_POLLING_INTERVAL_MS);
    } else {
      activityFeedByTeamIdQuery.stopPolling();
    }
  }, [pollIfNeeded, activityFeedByTeamIdQuery]);

  const data: ActivityFeedByTeamIdHookData | null =
    activityFeedByTeamIdQuery.data?.feed ??
    activityFeedByTeamIdQuery.previousData?.feed ??
    null;

  const loadMore = async () => {
    if (activityFeedByTeamIdQuery.data?.feed?.nextCursor) {
      await activityFeedByTeamIdQuery.fetchMore({
        variables: {
          cursor: activityFeedByTeamIdQuery.data?.feed?.nextCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            ...fetchMoreResult,
            feed: {
              ...fetchMoreResult.feed,
              activities: [
                ...(prev.feed?.activities || []),
                ...(fetchMoreResult.feed?.activities || []),
              ],
            },
          };
        },
      });
    }
  };

  const refetch = (
    newTeamId: string | undefined,
    activityFeedTypeFilters?: Array<ActivityType>
  ) => {
    activityFeedByTeamIdQuery.refetch({
      teamId: newTeamId || currentTeam?.id,
      pageSize,
      cursor,
      typeFilters: activityFeedTypeFilters,
    });
  };

  return [data, activityFeedByTeamIdQuery, loadMore, refetch];
};
