import { FC } from 'react';
import { BadgeProps } from '@chakra-ui/react';
import {
  Badge,
  Icon,
  SeverityCriticalIcon,
  SeverityHighIcon,
} from '@spoke/common';

type Props = BadgeProps & {
  value: number;
  iconSize?: number;
  severity?:
    | 'neutral'
    | 'bad_high'
    | 'bad_critical'
    | 'good_high'
    | 'good_critical';
};

const SCHEME: Record<NonNullable<Props['severity']>, string> = {
  bad_critical: 'red',
  bad_high: 'orange',
  good_critical: 'blue',
  good_high: 'green',
  neutral: 'gray',
};

const ICON: Record<
  NonNullable<Props['severity']>,
  { icon: FC; w: number; h: number } | null
> = {
  bad_critical: { icon: SeverityCriticalIcon, w: 3, h: 4 },
  bad_high: { icon: SeverityHighIcon, w: 3, h: 3 },
  good_critical: { icon: SeverityCriticalIcon, w: 3, h: 4 },
  good_high: { icon: SeverityHighIcon, w: 3, h: 3 },
  neutral: null,
};

export const SeverityBadge: FC<Props> = ({
  value,
  severity,
  iconSize = 4,
  ...props
}) => {
  const colorScheme = SCHEME[severity ?? 'neutral'];

  const icon = ICON[severity ?? 'neutral'];

  return (
    <Badge
      colorScheme={colorScheme}
      fontSize={16}
      {...props}
      px="6px"
      py="2px"
      fontWeight={400}
    >
      {icon && (
        <Icon
          as={icon.icon}
          w={`${icon.w * iconSize}px`}
          h={`${icon.h * iconSize}px`}
        />
      )}{' '}
      {value}
    </Badge>
  );
};
