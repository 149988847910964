import { useCurrentOrg } from './useCurrentOrg';
import { PaymentStatus } from '@spoke/graphql';
import { DefaultPlanSlug } from '@spoke/common';

export const useCurrentOrgLockdown = (): [boolean, boolean, boolean] => {
  const [currentOrg] = useCurrentOrg();
  const isOrgLockedDown =
    currentOrg?.isExceedingSeats || currentOrg?.status !== PaymentStatus.Active;
  const isTrialExpired =
    currentOrg?.status === PaymentStatus.TrialExpired ?? false;
  const isOrgFree = currentOrg?.plan?.slug === DefaultPlanSlug.Free;
  return [isOrgLockedDown, isTrialExpired, isOrgFree];
};
